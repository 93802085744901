import { useIonAlert } from "@ionic/react";
import { useTenant } from "./multiTenancy";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeVar, useReactiveVar } from "@apollo/client";
import { useCart } from "./cart";

export const selectedSalesPointVar = makeVar<number | undefined>(undefined);

export const useSelectedSalesPoint = (): [number | undefined, (_newSalesPoint: number, forceChange?: boolean) => void] => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const [, , , , cleanCart] = useCart();
  const selectedSalesPoint = useReactiveVar(selectedSalesPointVar);

  useEffect(() => {
    if (!selectedSalesPoint && tenant.settings?.mobileCommerceSalesPoints && tenant.settings?.mobileCommerceSalesPoints.length === 1) {
      changeSalesPoint(tenant.settings?.mobileCommerceSalesPoints[0].salesPoint.id);
    }
  }, [selectedSalesPoint, selectedSalesPoint]);

  const [showAlert] = useIonAlert();
  const changeSalesPoint = useCallback(
    (id: number, forceChange = false): void => {
      if (forceChange) {
        if (id) {
          selectedSalesPointVar(id);
        }
      } else if (selectedSalesPoint) {
        showAlert({
          header: t("stores.change_alert.title"),
          message: t("stores.change_alert.message"),
          buttons: [
            t("stores.change_alert.cancel"),
            {
              text: t("stores.change_alert.confirm"),
              handler: () => {
                cleanCart();
                selectedSalesPointVar(id);
              },
            },
          ],
        });
      } else {
        selectedSalesPointVar(id);
      }
    },
    [selectedSalesPoint]
  );

  return [selectedSalesPoint, changeSalesPoint];
};
