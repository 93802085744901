import { IonButton, IonContent, IonIcon, IonPage } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useHistory, useParams } from "react-router";
import { useTenant } from "../../hooks/multiTenancy";
import "./mainSelection.css";
import { cashOutline, cutOutline, documentTextOutline } from "ionicons/icons";

const MainSelection: React.FC = () => {
  const { idElement, idSalesPoint } = useParams<{ idElement: string; idSalesPoint: string }>();
  const tenant = useTenant();
  const { t } = useTranslation();
  const history = useHistory();

  if (tenant.settings?.digitalPaymentOnly) {
    history.replace(`/select/table/${idSalesPoint}/${idElement}`);
    return <></>;
  }

  const openApp = useCallback(() => {
    localStorage.setItem("selected-table", JSON.stringify({ idTable: idElement, time: Date.now() }));
    history.push(`/`);
  }, []);

  return (
    <IonPage className="mainSelection">
      <IonContent fullscreen>
        <div className="background-image" style={{ backgroundImage: `url('${tenant.baseURL}/background.png')` }} />
        <div className="background-shadow" />
        <div className="content">
          <div className="brand">
            <img className="logo" src={`${tenant.baseURL}/logo.png`} />
            <div className="tagline">{tenant.settings?.fidelityAccount?.payoff || tenant.settings?.payoff}</div>
          </div>
          <div className="main-guide-container">{t("table_payment.introMain")}</div>
          <div className="sub-guide-container">
            <div className="sub-guide-element">
              <div className="iconElem">
                <IonIcon className="icon" icon={documentTextOutline} />
              </div>

              <div>{t("table_payment.intro1")}</div>
            </div>
            <div className="sub-guide-element">
              <div className="iconElem">
                <IonIcon className="icon" icon={cashOutline} />
              </div>

              <div>{t("table_payment.intro2")}</div>
            </div>
            <div className="sub-guide-element">
              <div className="iconElem">
                <IonIcon className="icon" icon={cutOutline} />
              </div>

              <div>{t("table_payment.intro3")}</div>
            </div>
          </div>
          <div className="actionBtn">
            <IonButton className="button" color="primary" onClick={() => openApp()}>
              {t("table_payment.selectOrderApp")}
            </IonButton>
            <IonButton className="button" onClick={() => history.push(`/select/table/${idSalesPoint}/${idElement}`)}>
              {t("table_payment.selectTablePay")}
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MainSelection;
