import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonNote, IonPage, IonRow, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import "./auth.css";
import { useTranslation } from "react-i18next";
import { eye, eyeOff } from "ionicons/icons";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { makeVar, useMutation } from "@apollo/client";
import { loginWithEmailMutation } from "../../apollo-client/queries";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useTenant } from "../../hooks/multiTenancy";
import { useAuth } from "../../hooks/auth";

export const idAppCustomerDeviceVar = makeVar<string>("");
export const idFidelityIdentifierVar = makeVar<string>("");

type LoginFormData = {
  email: string;
  password: string;
};

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const Login: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [toast] = useIonToast();
  const tenant = useTenant();
  const [, setToken] = useAuth();
  const [loginMutation, { loading: loginLoading }] = useMutation(loginWithEmailMutation);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginFormData>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
  });

  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async (data: LoginFormData) => {
    try {
      const res = await loginMutation({ variables: data });
      setToken(res.data.login.accessToken.token);
      idAppCustomerDeviceVar(res.data.login.appCustomerDevice.id);
      idFidelityIdentifierVar(res.data.login.appCustomerDevice.idFidelityIdentifier);
      history.push("/app");
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const error = e as any;
      if (error.networkError) {
        if (error.networkError?.statusCode !== 200 && error.networkError.result && error.networkError.result.errorMsg) {
          const message = error.networkError.result.errorMsg;
          toast(message, 5000);
        } else {
          toast(t("auth.wrong_password"), 5000);
        }
      } else {
        toast("error", 5000);
      }
    }
  };

  return (
    <IonPage className="auth">
      <IonLoading isOpen={loginLoading} message={t("loading")} />
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>{t("auth.login")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="background-image" style={{ backgroundImage: `url('${tenant.baseURL}/background.png')` }} />
        <div className="background-shadow" />
        <div className="content top-bar">
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <IonGrid>
              <IonRow>
                <IonCol size="12">
                  <IonItem className={errors.email ? "error" : ""}>
                    <IonLabel position="floating">{t("auth.email")}</IonLabel>
                    <Controller name="email" control={control} render={({ field }) => <IonInput onIonChange={field.onChange} onIonBlur={field.onBlur} />} />
                  </IonItem>
                  {errors.email && <IonNote color="danger">{errors.email?.message}</IonNote>}
                </IonCol>
                <IonCol size="12">
                  <div className={"passwordContainer"}>
                    <IonItem className={errors.password ? "error inputPassword" : "inputPassword"}>
                      <IonLabel position="floating">{t("auth.password")}</IonLabel>
                      <Controller name="password" control={control} render={({ field }) => <IonInput type={showPassword ? "text" : "password"} onIonChange={field.onChange} onIonBlur={field.onBlur}></IonInput>} />
                    </IonItem>
                    <IonButton className="show-password" fill="clear" onClick={() => setShowPassword(!showPassword)}>
                      <IonIcon icon={showPassword ? eyeOff : eye} />
                    </IonButton>
                  </div>
                  {errors.password && <IonNote color="danger">{errors.password?.message}</IonNote>}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12" className="link">
                  <Link to="/register">
                    <span>{t("auth.no_account")}</span>
                    <span> </span>
                    <span className="register">{t("auth.register")}</span>
                  </Link>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12" className="link">
                  <Link to="/password-reset">{t("auth.no_password")}</Link>
                </IonCol>
              </IonRow>
              <IonRow className="form-buttons">
                <IonCol size="12">
                  <IonButton type="submit" color="primary">
                    {t("auth.login")}
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
