import _ from "lodash";
import { format, fromUnixTime, getUnixTime, isAfter, isBefore } from "date-fns";
import {
  Bill,
  BillItem,
  BillItemCourseSkus,
  BillItemOptionValue,
  BindingType,
  CartCourses,
  CourseChoices,
  Currency,
  Customer,
  DeliveryMode,
  ExternalOrdersPaymentType,
  ExternalWorkflowStatus,
  FidelityAccount,
  FidelityCustomer,
  FidelitySalesPoint,
  Item,
  License,
  LicenseActivation,
  LicenseActivationModule,
  Option,
  OptionValue,
  OptionValueBinding,
  Order,
  Price,
  Sku,
  SourceType,
  Variation,
  VariationType,
  WorkingPeriodSlot,
} from "./apollo-client/types";
import { Address } from "./components/addressInput";
import { NewOrder } from "./pages/main/newOrder";
import { Tenant } from "./hooks/multiTenancy";
import { Cart } from "./hooks/cart";
import { MenuItem } from "./hooks/menu";

export const getMobileCommerceSalesPoints = (fidelityAccount?: FidelityAccount): FidelitySalesPoint[] => {
  const hasMCModule = (licenseActivationModules?: LicenseActivationModule[]): boolean => {
    return _.some(licenseActivationModules, (lam) => lam.module?.name === "mobile_commerce");
  };

  const licenseActivationsCheck = (licenseActivations?: LicenseActivation[]): boolean => {
    const now = new Date();
    return _.some(licenseActivations, (la) => {
      return isAfter(now, fromUnixTime(la.validityStartDate!)) && isBefore(now, fromUnixTime(la.validityEndDate!)) && hasMCModule(la.licenseActivationModules);
    });
  };

  const licensesCheck = (licenses?: License[]): boolean => {
    return _.some(licenses, (l) => l.active && licenseActivationsCheck(l.licenseActivations));
  };

  return (fidelityAccount?.fidelitySalesPoints || []).filter((fsp) => licensesCheck(fsp.salesPoint?.licenses));
};

export const getSalesPoint = (tenant: Tenant, idSalesPoint?: number): FidelitySalesPoint | undefined => {
  return idSalesPoint ? tenant.settings?.mobileCommerceSalesPoints?.find((sp) => sp.salesPoint.id === idSalesPoint) : undefined;
};

export const getMainImage = (product: Item): string | undefined => {
  return _.first(_.sortBy(product.itemImages, (i) => -i.position))?.imageUrl;
};

export const paymentsAmount = (payments: { description?: string; amount: number }[]): number => {
  const tot = _.sum(payments.map((p) => p.amount));
  return Number(tot.toFixed(2));
};

export const getAvailableQuantity = (sku: Sku, cart: Cart): number | undefined => {
  if (sku.stockConfigs) {
    const stockConfig = sku.stockConfigs.find((sc) => sc.live);
    if (stockConfig && stockConfig.stockStatus) {
      const serverQuantity = stockConfig.stockStatus.stockLevel! - (stockConfig.stockStatus.outgoingQuantity || 0);
      let cartQuantity = _.sum(cart.items.filter((i) => i.sku.id === sku.id).map((i) => i.quantity));
      const itemsMenu = cart.items.filter((i) => i.menu !== null);
      const menuCartQuantity = _.sum(
        itemsMenu.map((m) => {
          _.sum(
            m.menu?.courses.map((cu) => {
              _.sum(cu.courseChoices.filter((i) => i.sku.id === sku.id).map((i) => i.quantity));
            })
          );
        })
      );
      if (menuCartQuantity) {
        cartQuantity += menuCartQuantity;
      }
      return serverQuantity - cartQuantity;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export const getProductPrice = (item: Item, currency?: Currency): string => {
  const price = _.first(item.prices);
  return getPrice(price!, currency);
};

export const getSkuPrice = (item: Item, sku: Sku, currency?: Currency): string => {
  const skuPrice = _.first(sku.prices);
  const itemPrice = _.first(item.prices);
  return getPrice((skuPrice || itemPrice)!, currency);
};

export const getPrice = (price: Price | BillItemOptionValue | number, currency?: Currency, sign?: boolean, quantity?: number, printPrcentage = true): string => {
  if (price === undefined) {
    return "";
  }
  if (typeof price === "number") {
    if (quantity) {
      price = price * quantity;
    }
    const currencySymbol = !currency || currency.code === "EUR" ? "€" : currency.code;
    const signSymbol = sign && price > 0 ? "+" : "";
    const priceString = price.toLocaleString(undefined, { minimumFractionDigits: 2 });
    return `${signSymbol}${priceString} ${currencySymbol}`;
  } else if (price.price) {
    let p = price.price;
    if (quantity) {
      p = price.price * quantity;
    }
    const currencySymbol = !currency || currency.code === "EUR" ? "€" : currency.code;
    const signSymbol = sign && p > 0 ? "+" : "";
    const priceString = p.toLocaleString(undefined, { minimumFractionDigits: 2 });
    return `${signSymbol}${priceString} ${currencySymbol}`;
  } else if (price.percentagePrice && printPrcentage) {
    const signSymbol = sign && price.percentagePrice > 0 ? "+" : "";
    return `${signSymbol}${price.percentagePrice}%`;
  } else {
    return "";
  }
};

export const getCartTotal = (cart: Cart): number => {
  const r = _.sum(
    cart.items.map((i) => {
      if (i.menu) {
        return getPriceMenu(i.menu) * i.quantity;
      } else {
        return getAdditionRemovalPrice(i.price, i.additionRemovals) * i.quantity;
      }
    })
  );
  return r;
};

export const getDeliveryCost = (cart: Cart, address: Address, fidelitySalesPoint: FidelitySalesPoint): number | null => {
  if (fidelitySalesPoint.salesPoint?.externalOrdersSettings?.delivery?.freeShippingThreshold && getCartTotal(cart) >= fidelitySalesPoint.salesPoint?.externalOrdersSettings?.delivery?.freeShippingThreshold) {
    return 0;
  } else {
    if (address.country && fidelitySalesPoint.salesPoint?.country) {
      if (address.country === fidelitySalesPoint.salesPoint?.country) {
        return fidelitySalesPoint.salesPoint?.externalOrdersSettings?.delivery?.nationalShippingFee || null;
      } else {
        return fidelitySalesPoint.salesPoint?.externalOrdersSettings?.delivery?.foreignShippingFee || null;
      }
    } else {
      return null;
    }
  }
};

export const isWorkingPeriodSlotAvailable = (slot: WorkingPeriodSlot, cartPieces: number, deliveryMode?: DeliveryMode): boolean => {
  if (deliveryMode && deliveryMode === DeliveryMode.DELIVERY && slot.availableDeliverableOrders !== undefined && slot.availableDeliverableOrders != null && slot.availableDeliverableOrders < 1) {
    return false;
  }
  if (slot.availablePiecesOrderable !== undefined && slot.availablePiecesOrderable !== null && slot.availablePiecesOrderable < cartPieces) {
    return false;
  }
  if (slot.availableOrdersAmount !== undefined && slot.availableOrdersAmount !== null && slot.availableOrdersAmount < 1) {
    return false;
  }
  return true;
};

export const getPrepaidAccountBalance = (customer: FidelityCustomer, idFidelitySalesPoint: number): number | null => {
  if (customer.prepaidAccounts) {
    const prepaidAccount = customer.prepaidAccounts.find((pa) => pa.idFidelitySalesPoint === idFidelitySalesPoint);
    if (prepaidAccount) {
      return prepaidAccount.amount;
    }
  }
  return null;
};

export const getDatetime = (date: Date): string => {
  return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS");
};

export const buildOrder = (cart: Cart, orderInfo: NewOrder, fidelitySalesPoint: FidelitySalesPoint, variation?: Variation): Bill => {
  const now = new Date();
  let deliveryCost = 0;
  let amount = 0;
  let rownumber = cart.items.length + 1;
  let deliveryInfo: Partial<Order> = {};
  let deliveryCostBillItem: BillItem | null = null;
  let variationBillItem: BillItem | null = null;
  if (orderInfo.deliveryMode === DeliveryMode.DELIVERY) {
    deliveryCost = getDeliveryCost(cart, orderInfo.address!, fidelitySalesPoint) || 0;
    deliveryInfo = {
      destinationStreet: orderInfo.address?.street,
      destinationCity: orderInfo.address?.city,
      destinationZipcode: orderInfo.address?.zipcode,
      destinationDistrict: orderInfo.address?.district,
      destinationCountry: orderInfo.address?.country,
    };
    if (deliveryCost > 0) {
      rownumber++;
      deliveryCostBillItem = {
        rowNumber: rownumber,
        subtotal: false,
        menu: false,
        composition: false,
        price: deliveryCost,
        quantity: 1,
        shippingCost: true,
      };
    }
  } else if (orderInfo.deliveryMode === DeliveryMode.OTHER) {
    deliveryInfo = { otherDeliveryDestination: orderInfo.otherDeliveryDestination };
  }

  if (variation && variation.variation) {
    rownumber++;
    variationBillItem = {
      rowNumber: rownumber,
      subtotal: true,
      menu: false,
      composition: false,
      percentageVariation: variation.variation,
      shippingCost: false,
      variationType: variation.variationType,
    };
  }

  const billItemCourseSkus = (courses: CartCourses[], menu?: boolean): BillItemCourseSkus[] => {
    const b: BillItemCourseSkus[] = [];
    courses.map((course) => {
      course.courseChoices.map((cartItem) => {
        if (menu) {
          b.push({
            idItem: cartItem.item.id,
            idSku: cartItem.sku.id,
            idCourse: course.id,
            idDepartment: cartItem.item.idDepartment,
            idCategory: cartItem.item.idCategory,
            idTax: cartItem.item.department?.idTax,
            price: cartItem.price,
            multiplier: cartItem.multiplier,
            quantity: cartItem.quantity,
            courseSkuOptionValues: cartItem.additionRemovals,
          });
        } else {
          b.push({
            idItem: cartItem.item.id,
            idSku: cartItem.sku.id,
            idCourse: course.id,
            idDepartment: cartItem.item.idDepartment,
            idCategory: cartItem.item.idCategory,
            idTax: cartItem.item.department?.idTax,
            price: cartItem.price,
            componentPrice: cartItem.componentPrice,
            multiplier: cartItem.multiplier,
            quantity: cartItem.quantity,
            courseSkuOptionValues: cartItem.additionRemovals,
          });
        }
      });
    });
    return b;
  };

  const billItems: BillItem[] = cart.items.map((cartItem, index) => {
    if (cartItem.menu) {
      return {
        rowNumber: index + 1,
        subtotal: false,
        idItem: cartItem.item.id,
        idSku: cartItem.sku.id,
        idDepartment: cartItem.item.idDepartment,
        idCategory: cartItem.item.idCategory,
        idTax: cartItem.item.department?.idTax,
        billItemCourseSkus: billItemCourseSkus(cartItem.menu.courses, cartItem.item.menu),
        price: cartItem.price,
        quantity: cartItem.quantity,
        shippingCost: false,
        menu: cartItem.item.menu,
        composition: cartItem.item.composition,
        itemOptionValues: [],
      };
    }
    return {
      rowNumber: index + 1,
      subtotal: false,
      idItem: cartItem.item.id,
      idSku: cartItem.sku.id,
      idDepartment: cartItem.item.idDepartment,
      idCategory: cartItem.item.idCategory,
      idTax: cartItem.item.department?.idTax,
      itemOptionValues: cartItem.additionRemovals,
      price: cartItem.price,
      quantity: cartItem.quantity,
      shippingCost: false,
      menu: false,
      composition: false,
    };
  });

  amount = getCartTotal(cart) + deliveryCost;

  if (deliveryCost > 0 && deliveryCostBillItem) {
    billItems.push(deliveryCostBillItem);
  }
  if (variationBillItem) {
    billItems.push(variationBillItem);
    amount = getCartTotal(cart) - (getCartTotal(cart) * variation!.variation!) / 100 + deliveryCost;
  }

  return {
    order: {
      ...deliveryInfo,
      dueDate: orderInfo.hourSlot ? orderInfo.hourSlot.start : undefined,
      dueDatetime: orderInfo.hourSlot ? getDatetime(fromUnixTime(orderInfo.hourSlot.start!)) : undefined,
      deliveryMode: orderInfo.deliveryMode,
      idTable: orderInfo.idTable,
      prepay: orderInfo.paymentType === ExternalOrdersPaymentType.CASH_ON_DELIVERY || orderInfo.paymentType === ExternalOrdersPaymentType.CASH_ON_TABLE ? false : true,
      prepaymentType: orderInfo.paymentType === SourceType.CREDIT_CARD || orderInfo.paymentType === SourceType.PAYPAL ? ExternalOrdersPaymentType.TSPAY : orderInfo.paymentType,
      phoneNumber: orderInfo.phoneNumber,
    },
    date: getUnixTime(now),
    datetime: getDatetime(now),
    note: orderInfo.note,
    amount: amount,
    billItems,
  };
};

export const getOrderStatusColor = (status: ExternalWorkflowStatus): string => {
  switch (status) {
    case ExternalWorkflowStatus.REQUESTED:
      return "var(--ion-color-warning)";
    case ExternalWorkflowStatus.ACCEPTED:
    case ExternalWorkflowStatus.PROCESSING:
    case ExternalWorkflowStatus.READY:
    case ExternalWorkflowStatus.SHIPPED:
    case ExternalWorkflowStatus.SHIPPING:
    case ExternalWorkflowStatus.DELIVERING:
    case ExternalWorkflowStatus.COMPLETED:
      return "var(--ion-color-success)";
    case ExternalWorkflowStatus.REJECTED:
    case ExternalWorkflowStatus.CANCELED:
      return "var(--ion-color-danger)";
  }
};

export const getProductOptions = (item: Item): Option[] => {
  if (item.multivariant) {
    const optionValueBindingIds = _.flatten(item.sku?.map((sku) => sku.skuOptionValues?.map((skuOptionValue) => skuOptionValue.idOptionValueBinding) || []));
    const optionIds = _.uniq([...(item.optionValueBindings || []), ...(item.category?.optionValueBindings || [])].filter((ovb) => optionValueBindingIds?.includes(ovb.id)).map((ovb) => ovb.optionValue?.idOption));
    return _.uniqBy(
      _.sortBy(
        [...(item.optionBindings || []), ...(item.category?.optionBindings || [])].filter((ob) => optionIds.includes(ob.idOption)),
        (ob) => ob.position
      ),
      "idOption"
    ).map((ob) => ob.option!);
  } else {
    return [];
  }
};

export const hasAtLeastOneSkuWithoutOptions = (item: Item): boolean => {
  return item.multivariant === true && _.some(item.sku, (sku) => !sku.skuOptionValues || sku.skuOptionValues.length === 0);
};

export const getCustomerVariation = (customer: Customer, circuitVariation: number | undefined): Variation | undefined => {
  let customerVariation: number | undefined = undefined;
  let temp: number | undefined = undefined;
  if (customer.variation1) {
    temp = customer.variation1 / 100;
    customerVariation = customerVariation == undefined ? temp : customerVariation! + temp! - customerVariation! * temp!;
  }
  if (customer.variation2) {
    temp = customer.variation2 / 100;
    customerVariation = customerVariation == undefined ? temp : customerVariation! + temp! - customerVariation! * temp!;
  }
  if (customer.variation3) {
    temp = customer.variation3 / 100;
    customerVariation = customerVariation == undefined ? temp : customerVariation! + temp! - customerVariation! * temp!;
  }
  if (customer.variation4) {
    temp = customer.variation4 / 100;
    customerVariation = customerVariation == undefined ? temp : customerVariation! + temp! - customerVariation! * temp!;
  }

  if (customerVariation === undefined) {
    if (circuitVariation !== undefined && circuitVariation !== null) {
      return { variation: circuitVariation, variationType: VariationType.FIDELITY_CIRCUIT };
    } else {
      return undefined;
    }
  } else {
    if (circuitVariation === undefined || circuitVariation === null) circuitVariation = 0;
    customerVariation = customerVariation! * 100;
    if (customerVariation! > circuitVariation) {
      return { variation: customerVariation, variationType: VariationType.CUSTOMER };
    } else {
      return { variation: circuitVariation, variationType: VariationType.FIDELITY_CIRCUIT };
    }
  }
  return undefined;
};

export const getProductOptionValues = (item: Item, optionId: string): OptionValue[] => {
  const optionValueBindingIds = _.flatten(item.sku?.map((sku) => sku.skuOptionValues?.map((skuOptionValue) => skuOptionValue.idOptionValueBinding) || []));
  return _.uniqBy(
    _.sortBy(
      [...(item.optionValueBindings || []), ...(item.category?.optionValueBindings || [])].filter((ovb) => optionValueBindingIds?.includes(ovb.id) && ovb.optionValue?.idOption === optionId).map((ovb) => ovb.optionValue!),
      (ovb) => ovb?.position
    ),
    "id"
  );
};

export const getSkuOptionValues = (item: Item, sku: Sku): OptionValue[] => {
  const optionValueBindingIds = sku.skuOptionValues?.map((skuOptionValue) => skuOptionValue.idOptionValueBinding);
  return _.uniqBy(
    _.sortBy(
      [...(item.optionValueBindings || []), ...(item.category?.optionValueBindings || [])].filter((ovb) => optionValueBindingIds?.includes(ovb.id)).map((ovb) => ovb.optionValue!),
      (ovb) => ovb?.position
    ),
    "id"
  );
};

export const getProductAdditionalRemovals = (item: Item): Option[] => {
  const optionIds = _.uniq([...(item.optionValueBindings || []), ...(item.category?.optionValueBindings || [])].map((ovb) => ovb.optionValue?.idOption));
  return _.uniqBy(
    _.sortBy(
      [...(item.optionBindings || []), ...(item.category?.optionBindings || [])].filter((ob) => optionIds.includes(ob.idOption) && ob.bindingType === BindingType.OPTIONAL),
      (ob) => ob.position
    ),
    "idOption"
  ).map((ob) => ob.option!);
};

export const getProductAdditionalRemovalsValues = (item: Item, optionId: string): (OptionValue & { optionValueBinding: OptionValueBinding })[] => {
  const itemOptionBinding = item.optionBindings?.find((ob) => ob.idOption === optionId && ob.bindingType === BindingType.OPTIONAL);
  let itemOptionValues: (OptionValue & { optionValueBinding: OptionValueBinding })[] = [];
  if (itemOptionBinding) {
    itemOptionValues = (item.optionValueBindings || []).filter((ovb) => ovb.optionValue?.idOption === optionId).map((ovb) => ({ ...ovb.optionValue!, optionValueBinding: ovb }));
  }

  const categoryOptionBinding = item.category?.optionBindings?.find((ob) => ob.idOption === optionId && ob.bindingType === BindingType.OPTIONAL);
  let categoryOptionValues: (OptionValue & { optionValueBinding: OptionValueBinding })[] = [];
  if (categoryOptionBinding) {
    categoryOptionValues = (item.category?.optionValueBindings || []).filter((ovb) => ovb.optionValue?.idOption === optionId && !itemOptionValues.find((iov) => iov.id === ovb.optionValue?.id)).map((ovb) => ({ ...ovb.optionValue!, optionValueBinding: ovb }));
  }

  const optionValues = [...itemOptionValues, ...categoryOptionValues];

  return _.sortBy(optionValues, (ov) => ov.position);
};

export const getAdditionRemovalPrice = (basePrice: number, additionRemovals?: BillItemOptionValue[]): number => {
  let price = basePrice;
  if (additionRemovals) {
    for (const additionRemoval of additionRemovals) {
      if (additionRemoval.price) {
        price += additionRemoval.price;
      }
    }
    for (const additionRemoval of additionRemovals) {
      if (additionRemoval.percentagePrice) {
        price += _.round((price / 100) * additionRemoval.percentagePrice, 2);
      }
    }
  }
  return price;
};

export const getCompletePrice = (item: Item, sku?: Sku, additionRemovals?: BillItemOptionValue[]): number => {
  const skuPrice = _.first(sku?.prices)?.price;
  const itemPrice = _.first(item.prices)?.price;
  const basePrice = skuPrice || itemPrice!;

  return getAdditionRemovalPrice(basePrice, additionRemovals);
};

export const getPriceCourse = (course: CartCourses, itemPrice?: boolean): number => {
  let price = 0;
  for (const cartItem of course.courseChoices) {
    if (itemPrice) {
      price += getCompletePrice(cartItem.item, cartItem.sku);
    }
    price += getAdditionRemovalPrice(0, cartItem.additionRemovals);
  }
  return price;
};

export const getPriceMenu = (menu: MenuItem): number => {
  let price = 0;
  for (const course of menu.courses) {
    for (const cc of course.courseChoices) {
      if (menu.item?.composition) {
        if (cc.componentPrice != undefined) {
          price += getAdditionRemovalPrice(cc.componentPrice!, cc.additionRemovals) * cc.quantity;
        } else {
          price += cc.price * cc.quantity;
        }
      } else price += cc.price * cc.quantity;
    }
  }
  if (menu.item) price += getCompletePrice(menu.item!);
  return price;
};

export const copleteMenu = (menu: MenuItem): boolean => {
  let menuComplete = true;
  for (const course of menu.courses) {
    menuComplete = menuComplete && getCourseQuantity(course) >= course.min!;
  }
  return menuComplete;
};

export const getCourseQuantity = (course: CartCourses): number => {
  let quantity = 0;
  for (const cartItem of course.courseChoices) {
    quantity += cartItem.quantity;
  }
  return quantity;
};

export const addressToString = (address: Address) => {
  return `${address.street}, ${address.zipcode} ${address.city} ${address.district} ${address.country}`;
};

export const getOrderMenuPrice = (basePrice: number, billItemCourseSkus: BillItemCourseSkus[], menu: boolean): number => {
  let price = basePrice;
  if (billItemCourseSkus) {
    for (const billItemCourseSku of billItemCourseSkus) {
      if (menu) {
        price += getAdditionRemovalPrice(0, billItemCourseSku.courseSkuOptionValues);
      } else {
        const cp = billItemCourseSku.componentPrice !== undefined ? billItemCourseSku.componentPrice : billItemCourseSku.price;
        price += getAdditionRemovalPrice(cp, billItemCourseSku.courseSkuOptionValues) * billItemCourseSku.quantity!;
      }
    }
  }
  return price;
};

export const getCourceChoiceFromItem = (item: Item, courceChoices: CourseChoices[]): CourseChoices | undefined => {
  const ccFind = courceChoices.filter((cc) => cc.item?.id === item.id);
  if (ccFind.length > 0) {
    return ccFind[0];
  }
  const ccCatFind = courceChoices.filter((cc) => cc.category?.id === item.idCategory);
  if (ccCatFind.length > 0) {
    return ccCatFind[0];
  }
  return undefined;
};

export type Coordinates = {
  longitude: number;
  latitude: number;
};

const degreesToRadians = (degrees: number) => {
  const radians = (degrees * Math.PI) / 180;
  return radians;
};

const calcCoordsDistance = (starting: Coordinates, destination: Coordinates): number => {
  const startingLat = degreesToRadians(starting.latitude);
  const startingLong = degreesToRadians(starting.longitude);
  const destinationLat = degreesToRadians(destination.latitude);
  const destinationLong = degreesToRadians(destination.longitude);

  // Radius of the Earth in kilometers
  const radius = 6571;

  // Haversine equation
  const distanceInKilometers: number = Math.acos(Math.sin(startingLat) * Math.sin(destinationLat) + Math.cos(startingLat) * Math.cos(destinationLat) * Math.cos(startingLong - destinationLong)) * radius;

  return Math.floor(distanceInKilometers * 100) / 100;
};

export default calcCoordsDistance;
