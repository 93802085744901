import { IonIcon, IonInput, IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonLoading, useIonToast, IonModal } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { location, close } from "ionicons/icons";
import { Customer } from "../apollo-client/types";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { useCallback, useEffect, useState } from "react";
import GooglePlacesAutocomplete, { geocodeByAddress, geocodeByLatLng, geocodeByPlaceId, getLatLng } from "react-google-places-autocomplete";
import useGeolocation from "react-hook-geolocation";
import "./addressInput.css";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import calcCoordsDistance, { addressToString, Coordinates, getSalesPoint } from "../utils";
import { useTenant } from "../hooks/multiTenancy";
import { useSelectedSalesPoint } from "../hooks/selectedSalesPoint";

export type Address = Pick<Customer, "street" | "zipcode" | "district" | "city" | "country">;

const libraries: Libraries = ["drawing", "geometry", "places", "visualization"];

const getAddressFromResult = (result: google.maps.GeocoderResult): Address => {
  let street = result.address_components.find((ac) => ac.types.find((t) => t === "route"))?.long_name;
  const street_number = result.address_components.find((ac) => ac.types.find((t) => t === "street_number"))?.long_name;
  if (street_number) street = `${street}, ${street_number}`;
  return {
    street,
    city: result.address_components.find((ac) => ac.types.find((t) => t === "administrative_area_level_3"))?.long_name,
    district: result.address_components.find((ac) => ac.types.find((t) => t === "administrative_area_level_2"))?.short_name,
    zipcode: result.address_components.find((ac) => ac.types.find((t) => t === "postal_code"))?.long_name,
    country: result.address_components.find((ac) => ac.types.find((t) => t === "country"))?.short_name,
  };
};

type ModalProps = {
  fromProfile?: boolean;
  value?: Address;
  onChange?: (address: Address) => void;
  dismiss: () => void;
};

const AddressSelectModal: React.FC<ModalProps> = ({ fromProfile = false, value, onChange, dismiss }) => {
  const { t } = useTranslation();
  const geolocation = useGeolocation({ timeout: 300 });

  const { isLoaded: isMapLoaded } = useJsApiLoader({
    language: "it",
    id: "address-google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_APIKEY || "",
    libraries: libraries,
  });

  const [toast] = useIonToast();
  const tenant = useTenant();
  const [selectedSalesPointId] = useSelectedSalesPoint();
  const selectedSalesPoint = getSalesPoint(tenant, selectedSalesPointId);
  const SalesPointLat = selectedSalesPoint!.latitude;
  const SalesPointLng = selectedSalesPoint!.longitude;
  const maxDistance = selectedSalesPoint?.salesPoint?.externalOrdersSettings?.delivery?.shippingMaxDistance;

  const [, setMap] = useState(null);
  const [textAddress, setTextAddress] = useState<string>();
  const [position, setPosition] = useState<{ lat: number; lng: number }>({ lat: 0, lng: 0 });
  const [address, setAddress] = useState<Address>();
  const [loading, setLoading] = useState<boolean>(true);

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  useEffect(() => {
    const initValue = async () => {
      if (value) {
        setAddress(value);
        setTextAddress(addressToString(value));
        setLoading(false);
        const results = await geocodeByAddress(addressToString(value));
        if (results.length > 0) {
          setPosition(await getLatLng(results[0]));
        }
      }
    };
    if (isMapLoaded) {
      initValue();
    }
  }, [isMapLoaded]);

  useEffect(() => {
    const loadAfterGeolocation = async () => {
      let position;
      if (geolocation.latitude && geolocation.longitude) {
        position = { lat: geolocation.latitude, lng: geolocation.longitude };
        setPosition(position);
        try {
          const results = await geocodeByLatLng(position);
          if (results.length > 0) {
            setTextAddress(results[0].formatted_address);
            setAddress(getAddressFromResult(results[0]));
          }
        } catch (e) {}
        setLoading(false);
      } else if (geolocation.error) {
        setLoading(false);
      }
    };
    if (isMapLoaded && loading && !value) {
      loadAfterGeolocation();
    }
  }, [geolocation, isMapLoaded, value, loading]);

  // const onMarkerDragEnd = useCallback(async (e: any) => {
  //   setPosition(e.latLng);
  //   const results = await geocodeByLatLng(e.latLng)
  //   if(results.length > 0){
  //     setTextAddress(results[0].formatted_address)
  //     console.log(getAddressFromResult(results[0]));
  //     setAddress(getAddressFromResult(results[0]));
  //   }
  // }, []);

  const onPlaceSelected = async ({ value }: { value: google.maps.GeocoderResult }) => {
    if (value && value.place_id) {
      const geocodeResults = await geocodeByPlaceId(value.place_id);
      if (geocodeResults.length > 0) {
        setTextAddress(geocodeResults[0].formatted_address);
        setAddress(getAddressFromResult(geocodeResults[0]));
        setPosition(await getLatLng(geocodeResults[0]));
      }
    }
  };

  const confirm = useCallback(() => {
    if (maxDistance && maxDistance > 0 && !fromProfile) {
      const SalesPointCoordinates: Coordinates = { latitude: SalesPointLat!, longitude: SalesPointLng! };
      const DeliveryCoordinates: Coordinates = { latitude: position.lat, longitude: position.lng };

      const r = calcCoordsDistance(SalesPointCoordinates, DeliveryCoordinates);

      if (r > maxDistance) {
        toast({
          cssClass: "cart-added-toast",
          color: "light",
          message: t("order.deliveryDistanceFail"),
          duration: 800,
          animated: true,
          position: "middle",
        });
        return;
      }
    }

    if (onChange && address) {
      onChange(address);
    }
    dismiss();
  }, [position]);

  return (
    <IonPage className="address-input-modal">
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="start">
            <IonButton onClick={() => dismiss()}>
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
          <IonTitle>{t("address.title")}</IonTitle>
          <IonButtons slot="end">
            <IonButton disabled={!position} onClick={confirm}>
              {t("address.confirm")}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonLoading isOpen={!isMapLoaded} message={t("stores.loading_position")} />
      <IonContent fullscreen>
        {isMapLoaded && !loading && (
          <>
            <GoogleMap
              mapContainerStyle={{ position: "absolute", top: "0px", width: "100%", height: "100%" }}
              center={position}
              zoom={12}
              onLoad={onLoad}
              onUnmount={onUnmount}
              options={{
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeControl: false,
              }}
            >
              {position && <Marker position={position} /*draggable onDragEnd={onMarkerDragEnd}*/ />}
            </GoogleMap>
            <div className="autocomplete">
              <GooglePlacesAutocomplete
                selectProps={{
                  value: { label: textAddress, value: {} },
                  onChange: onPlaceSelected,
                }}
                autocompletionRequest={{
                  types: ["address"],
                }}
              />
            </div>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

type AddressInputProps = {
  fromProfile?: boolean;
  disabled?: boolean;
  value?: Address;
  onChange?: (address: Address) => void;
};

const AddressInput: React.FC<AddressInputProps> = ({ fromProfile, disabled, value, onChange }) => {
  const [currentValue, setCurrentValue] = useState<Address | undefined>(value);
  const [v, setv] = useState<boolean>(false);

  useEffect(() => {
    if (value && value.street && value.city) {
      setCurrentValue(value);
    }
  }, [value]);

  const handleDismiss = () => {
    setv(false);
  };

  const onChangeInternal = (address: Address) => {
    setCurrentValue(address);
    if (onChange) {
      onChange(address);
    }
  };

  //const [showModal, dismissModal] = useIonModal(modalBody, { fromProfile, value: currentValue, onChange: onChangeInternal, dismiss: handleDismiss });

  const onClick = useCallback(() => {
    if (!disabled) {
      setv(true);
      //showModal();
    }
  }, [disabled]);

  return (
    <>
      <IonInput disabled={disabled} className="address-input" value={currentValue ? addressToString(currentValue) : null} readonly onClick={onClick}>
        <IonIcon icon={location} slot="end" className="location-icon" />
      </IonInput>
      <IonModal isOpen={v} onDidDismiss={() => setv(false)}>
        <AddressSelectModal fromProfile={fromProfile} value={currentValue} onChange={onChangeInternal} dismiss={handleDismiss}></AddressSelectModal>
      </IonModal>
    </>
  );
};

export default AddressInput;
