/* eslint-disable @typescript-eslint/no-explicit-any */
import { IonBackButton, IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonFooter, IonHeader, IonInput, IonItem, IonLabel, IonLoading, IonPage, IonRow, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import "./payOrderStripe.css";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { getSetupIntentQuery, getStripePaymentIntentQuery, orderPayedMutation, orderPayedWithMailMutation, ordersQuery, orderStripeClientSecretQuery, payableOrderQuery, paymentMethodsQuery } from "../../apollo-client/queries";
import { ExternalOrdersPaymentType, PrepaymentStatus, StripePaymentMethod } from "../../apollo-client/types";
import { useHistory, useParams } from "react-router";
import { useTenant } from "../../hooks/multiTenancy";
import { getPrice, getSalesPoint } from "../../utils";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useCallback, useEffect, useState } from "react";
import { useSelectedSalesPoint } from "../../hooks/selectedSalesPoint";

type ModalProps = {
  guestModal?: boolean;
};

const PayOrderStripe: React.FC<ModalProps> = ({ guestModal = false }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { idOrder } = useParams<{ idOrder: string }>();
  const tenant = useTenant();
  const [toast] = useIonToast();
  const [selectedSalesPointId] = useSelectedSalesPoint();
  const selectedSalesPoint = getSalesPoint(tenant, selectedSalesPointId);
  const [addCardVisible, setAddCardVisible] = useState<boolean>(false);
  const [eReceipt, setEReceipt] = useState<boolean>(false);
  const [eReceiptMail, setEReceiptMail] = useState<string>("");
  const [stripeSecret, setStripeSecret] = useState<string | undefined>(undefined);
  const [selectedCard, setSelectedCard] = useState<StripePaymentMethod>();

  const [existingPaymentMethods, setExistingPaymentMethods] = useState<StripePaymentMethod[] | undefined>(undefined);

  const currency = tenant.settings?.fidelityAccount?.account.currency;
  const stripe = useStripe();
  const elements = useElements();
  const [stripeLoading, setStripeLoading] = useState<boolean>(false);

  const ordersResponse = useQuery(payableOrderQuery, {
    variables: {
      filter: encodeURIComponent(JSON.stringify({ id: [idOrder] })),
    },
  });

  const eReceiptEnabled: boolean | undefined = selectedSalesPoint?.salesPoint.externalOrdersSettings?.eReceiptEnabled;
  const eCustomerEnabled: boolean | undefined = selectedSalesPoint?.salesPoint.externalOrdersSettings?.eCustomerEnabled;
  const order = ordersResponse.data?.orders?.records ? ordersResponse.data?.orders?.records[0] : undefined;
  const orderClientSecretResponse = useQuery(orderStripeClientSecretQuery, { variables: { idSalesPoint: selectedSalesPointId, idOrder } });
  const orderClientSecret = orderClientSecretResponse?.data?.orderStripeClientSecret?.stripeClientSecret;

  const paymentMethodsResponse = useQuery(paymentMethodsQuery, { skip: guestModal, variables: { idSalesPoint: selectedSalesPointId } });

  const setupIntentResponse = useQuery(getSetupIntentQuery, { skip: guestModal, variables: { idSalesPoint: selectedSalesPointId } });
  const stripeIntentResponse = useQuery(getStripePaymentIntentQuery, { skip: !guestModal, variables: { idSalesPoint: selectedSalesPointId, idOrder } });

  useEffect(() => {
    if (existingPaymentMethods && existingPaymentMethods.length > 0) {
      setAddCardVisible(false);
      setSelectedCard(existingPaymentMethods[0]);
    }
  }, [existingPaymentMethods]);

  useEffect(() => {
    if (paymentMethodsResponse.data) {
      setExistingPaymentMethods(paymentMethodsResponse?.data?.paymentMethods?.paymentMethods);
    }
  }, [paymentMethodsResponse.data]);

  useEffect(() => {
    if (setupIntentResponse?.data?.setupIntent) setStripeSecret(setupIntentResponse?.data?.setupIntent?.clientSecret);
  }, [setupIntentResponse.data]);

  useEffect(() => {
    if (guestModal) {
      ordersResponse.refetch();
    }
    if (stripeIntentResponse?.data?.stripePaymentIntent) setStripeSecret(stripeIntentResponse?.data?.stripePaymentIntent.stripeClientSecret);
  }, [stripeIntentResponse.data]);

  const [orderPayed, orderPayedResponse] = useMutation(orderPayedMutation);
  const [orderPayedWithMail, orderPayedWithMailResponse] = useMutation(orderPayedWithMailMutation);

  let loading = true;

  if (guestModal) {
    loading = ordersResponse.loading || stripeIntentResponse.loading;
  } else {
    loading = ordersResponse.loading || paymentMethodsResponse.loading;
  }

  let orderError = false;
  if (order && order.order && !order.order.prepay) orderError = true;
  if (order && order.order && order.order.prepay && order.order.prepaymentStatus !== PrepaymentStatus.WAITING) orderError = true;
  if (order && order.order && order.order.prepaymentType !== ExternalOrdersPaymentType.STRIPE) orderError = true;

  const addCard = useCallback(async () => {
    setAddCardVisible(true);
  }, [addCardVisible]);

  const confirmAddCard = useCallback(async () => {
    try {
      if (elements) {
        const card = elements.getElement(CardElement);
        if (card) {
          setStripeLoading(true);
          if (guestModal) {
            const stripeResponse = await stripe?.confirmCardPayment(stripeSecret!, { payment_method: { card } });
            if (!stripeResponse?.error) {
              if (eReceipt && eReceiptMail.length > 0) {
                await orderPayedWithMail({
                  variables: { idSalesPoint: selectedSalesPointId, idOrder: order!.id, email: eReceiptMail, prepaymentType: "STRIPE" },
                });
                toast({
                  cssClass: "order-payed-toast",
                  color: "light",
                  message: t("pay_order.payed"),
                  duration: 2000,
                  animated: true,
                  position: "middle",
                  onDidDismiss: () => {
                    history.replace(`/payment-response-mail/${order!.id}`);
                  },
                });
              } else {
                await orderPayed({
                  variables: { idSalesPoint: selectedSalesPointId, idOrder: order!.id, prepaymentType: "STRIPE" },
                });
                toast({
                  cssClass: "order-payed-toast",
                  color: "light",
                  message: t("pay_order.payed"),
                  duration: 2000,
                  animated: true,
                  position: "middle",
                  onDidDismiss: () => {
                    history.replace(`/payment-response/${order!.id}`);
                  },
                });
              }
            } else {
              toast(stripeResponse.error.message!, 5000);
            }
          } else {
            const stripeResponse = await stripe?.confirmCardSetup(stripeSecret!, { payment_method: { card } });
            if (!stripeResponse?.error) {
              await paymentMethodsResponse.refetch();
            } else {
              toast(stripeResponse.error.message!, 5000);
            }
          }
        }
      }
      setStripeLoading(false);
    } catch (e) {
      setStripeLoading(false);
      toast((e as any).message, 5000);
    }
  }, [elements, stripeSecret, eReceipt, eReceiptMail]);

  const confirm = useCallback(async () => {
    try {
      if (selectedCard) {
        setStripeLoading(true);
        const stripeResponse = await stripe?.confirmCardPayment(orderClientSecret, {
          payment_method: selectedCard.id,
        });
        if (!stripeResponse?.error) {
          await orderPayed({
            variables: { idSalesPoint: selectedSalesPointId, idOrder: order!.id, prepaymentType: "STRIPE" },
            refetchQueries: [ordersQuery, "GetOrders"],
          });
          toast({
            cssClass: "order-payed-toast",
            color: "light",
            message: t("pay_order.payed"),
            duration: 500,
            animated: true,
            position: "middle",
            onDidDismiss: () => {
              history.push("/app/orders");
            },
          });
        } else {
          toast(stripeResponse.error.message!, 5000);
        }
      }
      setStripeLoading(false);
    } catch (e) {
      setStripeLoading(false);
      toast((e as any).message, 5000);
    }
  }, [selectedCard, selectedSalesPointId, elements, orderClientSecret]);

  return (
    <IonPage className="pay-order">
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{t("pay_order.title")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonLoading isOpen={loading || ordersResponse.loading || stripeLoading || setupIntentResponse.loading || orderPayedWithMailResponse.loading || orderPayedResponse.loading || stripeIntentResponse.loading} message={t("loading")} />
      <IonContent fullscreen>
        {!loading && (
          <>
            {order && !guestModal && order.order && !order.order.prepay && <div className="error">{t("pay_order.not_prepay")}</div>}
            {order && !guestModal && order.order && order.order.prepay && order.order.prepaymentStatus !== PrepaymentStatus.WAITING && <div className="error">{t("pay_order.wrong_status")}</div>}
            {order && !guestModal && order.order && order.order.prepaymentType !== ExternalOrdersPaymentType.STRIPE && <div className="error">{t("pay_order.wrong_paymenttype")}</div>}
            {order && order.order && order.order.prepay && order.order.prepaymentStatus === PrepaymentStatus.WAITING && order.order.prepaymentType === ExternalOrdersPaymentType.STRIPE && (
              <>
                {existingPaymentMethods && !addCardVisible && !guestModal && (
                  <>
                    <div className="existing-credit-card">
                      <div className="label">{t("pay_order.existing_card")}:</div>
                      {existingPaymentMethods.map((epm, index) => (
                        <div key={index} className={`value ${selectedCard === epm ? "selected" : ""}`} onClick={() => setSelectedCard(epm)}>
                          {`${epm.brand} - XXXX XXXX XXXX ${epm.last4}`}
                        </div>
                      ))}
                    </div>
                    <div className="add-card">
                      <IonButton size="small" color="light" onClick={addCard}>
                        {t("pay_order.add_card")}
                      </IonButton>
                    </div>
                  </>
                )}
                {(addCardVisible || guestModal) && (
                  <div className="credit-card">
                    <IonLabel>{t("pay_order.card")}:</IonLabel>
                    <CardElement />
                    {!guestModal && (
                      <div className="add-card">
                        <IonButton size="small" color="light" onClick={() => setAddCardVisible(false)}>
                          {t("cancel")}
                        </IonButton>
                        <IonButton size="small" color="light" onClick={() => confirmAddCard()}>
                          {t("pay_order.confirm_card")}
                        </IonButton>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </>
        )}

        {eReceiptEnabled && (
          <div className="credit-card">
            <IonItem lines="none">
              <IonLabel>{t("pay_order.eReceiptMsg")}</IonLabel>
              <IonCheckbox checked={eReceipt} onIonChange={(e) => setEReceipt(e.detail.checked)} />
            </IonItem>
            <IonLabel position="stacked">{t("pay_order.eReceiptIntroMsg")}</IonLabel>
            <IonItem lines="none">
              <IonLabel position="stacked">{t("profile.email")}</IonLabel>
              <IonInput disabled={!eReceipt} value={eReceiptMail} onIonChange={(e) => setEReceiptMail(e.detail.value!)} />
            </IonItem>
            {eCustomerEnabled && (
              <>
                <IonRow>
                  <IonCol size="12" className="link register-link">
                    <a href={tenant.settings?.fidelityAccount?.termsAndConditionsUrl || tenant.settings?.termsAndConditionsUrl} target="_blank">
                      <span>{t("table_payment.terms_pre")}</span>
                      <span> </span>
                      <span className="terms">{t("auth.terms")}</span>
                    </a>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" className="link register-link">
                    <a href={tenant.settings?.fidelityAccount?.privacyPolicyUrl || tenant.settings?.privacyPolicyUrl || "https://www.cassaincloud.it/privacy"} target="_blank">
                      <span>{t("table_payment.privacy_pre")}</span>
                      <span> </span>
                      <span className="privacy">{t("auth.privacy")}</span>
                    </a>
                  </IonCol>
                </IonRow>
              </>
            )}
          </div>
        )}
      </IonContent>
      {!loading && order && (
        <IonFooter className="confirm-bar">
          <div className="content">
            <div className="recap">
              <div className="title">{t("new_order.total")}:</div>
              <div className="value">{getPrice(order!.amount!, currency)}</div>
            </div>
            {guestModal && (
              <IonButton disabled={orderError} onClick={() => confirmAddCard()} expand="block">
                {t("table_payment.pay")}
              </IonButton>
            )}
            {!guestModal && (
              <IonButton disabled={!selectedCard} onClick={() => confirm()} expand="block">
                {t("new_order.confirm")}
              </IonButton>
            )}
          </div>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default PayOrderStripe;
