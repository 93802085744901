import { Route } from "react-router-dom";
import { IonRouterOutlet, IonSpinner, isPlatform, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Main from "./pages/main/main";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import { initTenant, useTenant } from "./hooks/multiTenancy";

/* Theme variables */
import "./theme/variables.css";
import "./app.css";

import Auth from "./pages/auth/auth";
import Login from "./pages/auth/login";
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from "@apollo/client";
import { createApolloClient } from "./apollo-client";
import Register from "./pages/auth/register";
import { useAuth } from "./hooks/auth";
import Stores from "./pages/main/stores";
import { Helmet } from "react-helmet";
import PasswordReset from "./pages/auth/passwordReset";
import { useTranslation } from "react-i18next";
import NewOrder from "./pages/main/newOrder";
import { persistAppState } from "./hooks/persistence";
import Localization from "./localization";
import { useEffect, useState } from "react";
import OneTimePayment from "./pages/oneTimePayment/oneTimePayment";
import TablePayment from "./pages/tablePayment/tablePayment";
import PayOrder from "./pages/main/payOrder";
import TsPayResponse from "./pages/main/tsPayResponse";
import PayOrderTSPay from "./pages/main/payOrderTSPay";
import MainSelection from "./pages/tablePayment/mainSelection";
import PaymentResponse from "./pages/tablePayment/paymentResponse";
import PayOrderSatispay from "./pages/main/payOrderSatispay";
import SatispayResponse from "./pages/main/satispayResponse";

const App: React.FC = () => {
  const { t } = useTranslation();
  const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject>>();

  persistAppState();
  initTenant();

  setupIonicReact({
    backButtonText: isPlatform("ios") ? t("back") : "",
  });

  const tenant = useTenant();
  const [auth, , logout] = useAuth();

  useEffect(() => {
    if (tenant.settings) {
      setApolloClient(createApolloClient(tenant.settings.apiKey, auth, logout));
    }
  }, [tenant, auth]);

  return (
    <>
      {(tenant.loading || !apolloClient) && <IonSpinner className={"app-loader"} />}
      {tenant.settings && apolloClient && (
        <Localization>
          <ApolloProvider client={apolloClient}>
            <Helmet>
              <title>{tenant.settings.fidelityAccount?.name}</title>
              <meta name="description" content={tenant.settings.fidelityAccount?.payoff || tenant.settings?.payoff} />
              <link rel="stylesheet" type="text/css" href={`${tenant.baseURL}/theme.css`} />
              <link rel="stylesheet" type="text/css" href={`${tenant.baseURL}/overrides.css`} />
              <link rel="icon" type="image/png" href={`${tenant.baseURL}/favicon.ico`} sizes="16x16" />
            </Helmet>

            {!tenant.settings?.digitalPaymentOnly && (
              <IonReactRouter>
                <IonRouterOutlet>
                  <Route exact path="/">
                    <Auth />
                  </Route>
                  <Route exact path="/login">
                    <Login />
                  </Route>
                  <Route exact path="/register">
                    <Register />
                  </Route>
                  <Route exact path="/password-reset">
                    <PasswordReset />
                  </Route>
                  <Route exact path="/stores">
                    <Stores />
                  </Route>
                  <Route exact path="/new-order">
                    <NewOrder />
                  </Route>
                  <Route exact path="/table/:idSalesPoint/:idElement">
                    <MainSelection />
                  </Route>
                  <Route exact path="/select/table/:idSalesPoint/:idElement">
                    <TablePayment payTable={true} />
                  </Route>
                  <Route exact path="/order-payment/:idSalesPoint/:idElement">
                    <TablePayment />
                  </Route>
                  <Route exact path="/stripepay/:idOrder">
                    <PayOrder guestModal={true} />
                  </Route>
                  <Route exact path="/tspay/:idOrder/:sourceType">
                    <PayOrderTSPay guestModal={true} />
                  </Route>
                  <Route exact path="/satispay/:idOrder/:idMainOrder">
                    <PayOrderSatispay guestModal={true} />
                  </Route>
                  <Route exact path="/one-time-payment/:idOrder">
                    <OneTimePayment />
                  </Route>
                  <Route exact path="/tspay-response/:idOrder/linkToSave">
                    <TsPayResponse />
                  </Route>
                  <Route exact path="/prepay/tspay-response/:idOrder/linkToSave">
                    <TsPayResponse guestModal={true} />
                  </Route>
                  <Route exact path="/satispay-response/:idOrder">
                    <SatispayResponse />
                  </Route>
                  <Route exact path="/prepay/satispay-response/:idOrder/:idMainOrder">
                    <SatispayResponse guestModal={true} />
                  </Route>
                  <Route exact path="/payment-response-mail/:idOrder">
                    <PaymentResponse withMail={true} />
                  </Route>
                  <Route exact path="/payment-response/:idOrder">
                    <PaymentResponse withMail={false} />
                  </Route>
                  <Route path="/app">
                    <Main />
                  </Route>
                </IonRouterOutlet>
              </IonReactRouter>
            )}

            {tenant.settings?.digitalPaymentOnly && (
              <IonReactRouter>
                <IonRouterOutlet>
                  <Route exact path="/">
                    <Auth />
                  </Route>
                  <Route exact path="/table/:idSalesPoint/:idElement">
                    <TablePayment payTable={true} />
                  </Route>
                  <Route exact path="/order-payment/:idSalesPoint/:idElement">
                    <TablePayment />
                  </Route>
                  <Route exact path="/stripepay/:idOrder">
                    <PayOrder guestModal={true} />
                  </Route>
                  <Route exact path="/tspay/:idOrder/:sourceType">
                    <PayOrderTSPay guestModal={true} />
                  </Route>
                  <Route exact path="/satispay/:idOrder/:idMainOrder">
                    <PayOrderSatispay guestModal={true} />
                  </Route>
                  <Route exact path="/tspay-response/:idOrder/linkToSave">
                    <TsPayResponse />
                  </Route>
                  <Route exact path="/prepay/tspay-response/:idOrder/linkToSave">
                    <TsPayResponse guestModal={true} />
                  </Route>
                  <Route exact path="/satispay-response/:idOrder/createPayment">
                    <SatispayResponse />
                  </Route>
                  <Route exact path="/prepay/satispay-response/:idOrder/:idMainOrder/createPayment">
                    <SatispayResponse guestModal={true} />
                  </Route>
                  <Route exact path="/payment-response-mail/:idOrder">
                    <PaymentResponse withMail={true} />
                  </Route>
                  <Route exact path="/payment-response/:idOrder">
                    <PaymentResponse withMail={false} />
                  </Route>
                  <Route path="/app">
                    <Main />
                  </Route>
                </IonRouterOutlet>
              </IonReactRouter>
            )}
          </ApolloProvider>
        </Localization>
      )}
    </>
  );
};

export default App;
