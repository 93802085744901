import { IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonFooter, IonHeader, IonInput, IonItem, IonLabel, IonLoading, IonPage, IonRow, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import "./tsPayResponse.css";
import { useTenant } from "../../hooks/multiTenancy";
import { useSelectedSalesPoint } from "../../hooks/selectedSalesPoint";
import { getSalesPoint } from "../../utils";
import { useMutation } from "@apollo/client";
import { orderPayedMutation, orderPayedWithMailMutation } from "../../apollo-client/queries";

type ModalProps = {
  guestModal?: boolean;
};

const SatispayResponse: React.FC<ModalProps> = ({ guestModal = false }) => {
  const [orderPayed, orderPayedResponse] = useMutation(orderPayedMutation);
  const [orderPayedWithMail, orderPayedWithMailResponse] = useMutation(orderPayedWithMailMutation);
  const [toast] = useIonToast();
  const { t } = useTranslation();
  const { idOrder } = useParams<{ idOrder: string }>();
  const tenant = useTenant();
  const [selectedSalesPointId] = useSelectedSalesPoint();
  const selectedSalesPoint = getSalesPoint(tenant, selectedSalesPointId);
  const [eReceipt, setEReceipt] = useState<boolean>(false);
  const [eReceiptMail, setEReceiptMail] = useState<string>("");
  const eReceiptEnabled: boolean | undefined = selectedSalesPoint?.salesPoint.externalOrdersSettings?.eReceiptEnabled;
  const eCustomerEnabled: boolean | undefined = selectedSalesPoint?.salesPoint.externalOrdersSettings?.eCustomerEnabled;

  useEffect(() => {
    if (!eReceiptEnabled) confirm();
  }, [eReceiptEnabled]);

  const confirm = useCallback(async () => {
    const urlOrigin = window.location.origin;
    let url = `${urlOrigin}/app/orders`;
    if (guestModal) {
      url = `${urlOrigin}/payment-response/${idOrder}`;
    }
    try {
      if (eReceipt && eReceiptMail.length > 0) {
        if (guestModal) {
          url = `${urlOrigin}/payment-response-mail/${idOrder}`;
        }
        await orderPayedWithMail({
          variables: { idSalesPoint: selectedSalesPointId, idOrder: idOrder, email: eReceiptMail, prepaymentType: "SATISPAY" },
        });
        toast({
          cssClass: "order-payed-toast",
          color: "light",
          message: t("pay_order.payed"),
          duration: 1000,
          animated: true,
          position: "middle",
          onDidDismiss: () => {
            window.location.href = url;
          },
        });
      } else {
        await orderPayed({
          variables: { idSalesPoint: selectedSalesPointId, idOrder: idOrder, prepaymentType: "SATISPAY" },
        });
        toast({
          cssClass: "order-payed-toast",
          color: "light",
          message: t("pay_order.payed"),
          duration: 1000,
          animated: true,
          position: "middle",
          onDidDismiss: () => {
            window.location.href = url;
          },
        });
      }
    } catch (e) {
      toast({
        cssClass: "order-payed-toast",
        color: "light",
        message: t("pay_order.tsPayErrorMsg"),
        duration: 2000,
        animated: true,
        position: "middle",
        onDidDismiss: () => {
          window.location.href = url;
        },
      });
    }
  }, [selectedSalesPointId, , eReceipt, eReceiptMail]);

  return (
    <IonPage className="pay-order">
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="start"></IonButtons>
          <IonTitle>{t("pay_order.title")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonLoading isOpen={orderPayedResponse.loading || orderPayedWithMailResponse.loading} />
      {eReceiptEnabled && (
        <>
          <IonContent fullscreen>
            <div className="credit-card">
              <IonItem lines="none">
                <IonLabel>{t("pay_order.eReceiptMsg")}</IonLabel>
                <IonCheckbox checked={eReceipt} onIonChange={(e) => setEReceipt(e.detail.checked)} />
              </IonItem>
              <IonItem lines="none">
                <IonLabel position="stacked">{t("profile.email")}</IonLabel>
                <IonInput disabled={!eReceipt} value={eReceiptMail} onIonChange={(e) => setEReceiptMail(e.detail.value!)} />
              </IonItem>
              {eCustomerEnabled && (
                <>
                  <IonRow>
                    <IonCol size="12" className="link register-link">
                      <a href={tenant.settings?.fidelityAccount?.termsAndConditionsUrl || tenant.settings?.termsAndConditionsUrl} target="_blank">
                        <span>{t("table_payment.terms_pre")}</span>
                        <span> </span>
                        <span className="terms">{t("auth.terms")}</span>
                      </a>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12" className="link register-link">
                      <a href={tenant.settings?.fidelityAccount?.privacyPolicyUrl || tenant.settings?.privacyPolicyUrl || "https://www.cassaincloud.it/privacy"} target="_blank">
                        <span>{t("table_payment.privacy_pre")}</span>
                        <span> </span>
                        <span className="privacy">{t("auth.privacy")}</span>
                      </a>
                    </IonCol>
                  </IonRow>
                </>
              )}
            </div>
          </IonContent>
          <IonFooter className="confirm-bar">
            <div className="content">
              <IonButton onClick={() => confirm()} expand="block">
                {t("new_order.confirm")}
              </IonButton>
            </div>
          </IonFooter>
        </>
      )}
      {!eReceiptEnabled && (
        <>
          <IonContent fullscreen></IonContent>
          <IonFooter className="confirm-bar"></IonFooter>
        </>
      )}
    </IonPage>
  );
};

export default SatispayResponse;
