import { ReactiveVar, useReactiveVar } from "@apollo/client";
import { useEffect } from "react";
import { cartVar } from "./cart";
import { menuVar } from "./menu";
import { idAppCustomerDeviceVar, idFidelityIdentifierVar } from "../pages/auth/login";
import { selectedSalesPointVar } from "./selectedSalesPoint";

const persistReactiveVar = <T>(rVar: ReactiveVar<T>, localStorageCode: string): void => {
  const reactiveVar = useReactiveVar(rVar);

  useEffect(() => {
    const initialValue = localStorage.getItem(localStorageCode);
    if (initialValue) {
      rVar(JSON.parse(initialValue));
    }
  }, []);

  useEffect(() => {
    if (reactiveVar !== undefined) {
      localStorage.setItem(localStorageCode, JSON.stringify(reactiveVar));
    } else {
      localStorage.removeItem(localStorageCode);
    }
  }, [reactiveVar]);
};

export const persistAppState = (): void => {
  persistReactiveVar(selectedSalesPointVar, "selectedSalesPoint");
  persistReactiveVar(cartVar, "cart");
  persistReactiveVar(menuVar, "menu");
  persistReactiveVar(idAppCustomerDeviceVar, "idAppCustomerDevice");
  persistReactiveVar(idFidelityIdentifierVar, "idFidelityIdentifier");
};
