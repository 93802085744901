import { IonButton, IonButtons, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonIcon, IonModal, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { call, globeOutline, mail, logoFacebook, storefront, close as cccc } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useTenant } from "../../hooks/multiTenancy";
import { useSelectedSalesPoint } from "../../hooks/selectedSalesPoint";
import { getSalesPoint } from "../../utils";
import "./home.css";
import { useState } from "react";
import SelectedSalesPointModal from "../../components/selectedSalesPointModal";
import { FidelitySalesPoint } from "../../apollo-client/types";

const link = (url: string) => {
  return url;
  if (url.indexOf("http") >= 0) {
    return url;
  } else {
    return `https://${url}`;
  }
};

type ModalProps = {
  items:
    | {
        salesPoint: string | undefined;
        description: string | undefined;
        url: string | undefined;
      }[]
    | undefined;
  close: () => void;
};

const SelectElementModal: React.FC<ModalProps> = ({ items, close }) => {
  const { t } = useTranslation();
  if (items) {
    return (
      <IonPage className="showPaymentsContainer">
        <IonHeader>
          <IonToolbar color="secondary">
            <IonTitle>{t("home.contacts")}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => close()}>
                <IonIcon icon={cccc} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="pageContainer">
          {items.map((item, index) => (
            <a key={index} href={item.url}>
              <IonCard key={item.salesPoint} className="link-card">
                <IonCardHeader>
                  <IonCardTitle>{item.salesPoint}</IonCardTitle>
                  <IonCardSubtitle>{item.description}</IonCardSubtitle>
                </IonCardHeader>
              </IonCard>
            </a>
          ))}
        </IonContent>
      </IonPage>
    );
  } else return <></>;
};

const Home: React.FC = () => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const [selectedSalesPointId, changeSalesPoint] = useSelectedSalesPoint();
  const selectedSalesPoint = getSalesPoint(tenant, selectedSalesPointId);

  const [selectPVShow, setPV] = useState<boolean>(false);
  const [phoneNumberShow, setPN] = useState<boolean>(false);
  const [mailShow, setM] = useState<boolean>(false);

  const selectPv = (salesPoint: FidelitySalesPoint) => {
    console.log(salesPoint);
    setPV(false);
    changeSalesPoint(salesPoint.salesPoint.id, true);
  };

  return (
    <IonPage className="home">
      <IonContent fullscreen>
        <div className="background-image" style={{ backgroundImage: `url('${tenant.baseURL}/background.png')` }} />
        <div className="background-shadow" />
        <div className="content">
          <div className="links">
            <IonIcon icon={call} onClick={() => setPN(true)} />
            {tenant.settings?.fidelityAccount?.websiteLink && (
              <a href={link(tenant.settings?.fidelityAccount?.websiteLink)} target="_blank">
                <IonIcon icon={globeOutline} />
              </a>
            )}
            {tenant.settings?.fidelityAccount?.facebookLink && (
              <a href={link(tenant.settings?.fidelityAccount?.facebookLink)} target="_blank">
                <IonIcon icon={logoFacebook} />
              </a>
            )}
            <IonIcon icon={mail} onClick={() => setM(true)} />
          </div>
          <div className="brand">
            <img className="logo" src={`${tenant.baseURL}/logo.png`} />
            <div className="tagline">{tenant.settings?.fidelityAccount?.payoff || tenant.settings?.payoff}</div>
            {tenant.settings?.mobileCommerceSalesPoints && tenant.settings?.mobileCommerceSalesPoints.length > 1 && (
              <>
                <div className="stores-label">{t("home.stores")}</div>
                <IonButton className="stores-button" expand={"block"} onClick={() => setPV(true)}>
                  <IonIcon className="icon" icon={storefront} />
                  <div className="description">
                    <div className="name">{selectedSalesPoint?.name}</div>
                    <div className="city">{selectedSalesPoint?.city}</div>
                  </div>
                </IonButton>
              </>
            )}
          </div>
        </div>
      </IonContent>
      <IonModal isOpen={selectPVShow} onDidDismiss={() => setPV(false)}>
        <SelectedSalesPointModal onClick={selectPv}></SelectedSalesPointModal>
      </IonModal>
      <IonModal isOpen={phoneNumberShow} onDidDismiss={() => setPN(false)}>
        <SelectElementModal
          items={tenant.settings?.mobileCommerceSalesPoints?.map((fsp) => ({
            salesPoint: fsp.name,
            description: fsp.phoneNumber,
            url: `tel:${fsp.phoneNumber}`,
          }))}
          close={() => setPN(false)}
        ></SelectElementModal>
      </IonModal>
      <IonModal isOpen={mailShow} onDidDismiss={() => setM(false)}>
        <SelectElementModal
          items={tenant.settings?.mobileCommerceSalesPoints?.map((fsp) => ({
            salesPoint: fsp.name,
            description: fsp.email,
            url: `mailto:${fsp.email}`,
          }))}
          close={() => setM(false)}
        ></SelectElementModal>
      </IonModal>
    </IonPage>
  );
};

export default Home;
