import { IonButton, IonCard, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonList, IonPage, IonTitle, IonToolbar, useIonAlert } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { add, imageOutline, remove, storefront } from "ionicons/icons";
import { useSelectedSalesPoint } from "../../hooks/selectedSalesPoint";
import { CartItem, useCart } from "../../hooks/cart";
import { useHistory } from "react-router";
import { getAdditionRemovalPrice, getAvailableQuantity, getCartTotal, getCustomerVariation, getMainImage, getPrice, getPriceMenu, getSalesPoint } from "../../utils";
import { useTenant } from "../../hooks/multiTenancy";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../hooks/auth";
import { OptionValueType, Variation } from "../../apollo-client/types";
import { useMenu } from "../../hooks/menu";
import { userQuery } from "../../apollo-client/queries";
import { useQuery } from "@apollo/client";

import "./cart.css";

const Cart: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const tenant = useTenant();
  const [auth] = useAuth();
  const [selectedSalesPointId] = useSelectedSalesPoint();
  const [cart, , removeFromCart, updateItem, , ,] = useCart();
  const [, , , , , , , , clean] = useMenu();
  const [showAlert] = useIonAlert();

  const userResponse = useQuery(userQuery);
  const [variation, setVariation] = useState<Variation | undefined>(undefined);

  useEffect(() => {
    if (userResponse.data?.user.customer) {
      setVariation(getCustomerVariation(userResponse.data?.user.customer, undefined));
    }
  }, [userResponse.data]);

  if (variation) {
    console.log(variation);
  }

  const addQuantity = useCallback(
    (index: number, item: CartItem) => {
      const availableQuantity = getAvailableQuantity(item.sku, cart);
      if (availableQuantity === undefined || availableQuantity >= 1) {
        updateItem(index, { ...item, quantity: item.quantity + 1 });
      } else {
        showAlert({
          header: t("cart.quantity_alert.title"),
          message: t("cart.quantity_alert.message"),
          buttons: [t("cart.quantity_alert.cancel")],
        });
      }
    },
    [cart]
  );

  const subQuantity = useCallback(
    (index: number, item: CartItem) => {
      if (item.quantity === 1) {
        removeFromCart(item);
      } else {
        updateItem(index, { ...item, quantity: item.quantity - 1 });
      }
    },
    [cart]
  );

  const buy = useCallback(() => {
    if (auth.authenticated) {
      history.push("/new-order");
    } else {
      showAlert({
        header: t("cart.auth_alert.title"),
        message: t("cart.auth_alert.message"),
        buttons: [
          t("cart.auth_alert.cancel"),
          {
            text: t("cart.auth_alert.confirm"),
            handler: () => {
              history.push("/login");
            },
          },
        ],
      });
    }
  }, [auth]);

  const openCartItem = useCallback((ci: CartItem, index: number) => {
    if (ci.menu) {
      clean();
      history.push(`/app/products/menu-details/${ci.item.id}?cartItemIndex=${index}`);
    } else {
      history.push(`/app/products/product-details/${ci.item.id}?cartItemIndex=${index}`);
    }
  }, []);

  return (
    <IonPage className="cart">
      <IonHeader>
        <IonToolbar color="secondary">
          <IonTitle>{t("menu.cart")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {selectedSalesPointId && tenant.settings?.mobileCommerceSalesPoints && tenant.settings?.mobileCommerceSalesPoints?.length > 0 && (
          <div className="store-recap">
            <IonIcon icon={storefront} />
            <div className="text">{t("cart.store_recap", getSalesPoint(tenant, selectedSalesPointId))}</div>
          </div>
        )}
        {(!cart.items || cart.items.length === 0) && (
          <div className="empty-cart">
            <div className="message">{t("cart.empty")}</div>
            <IonButton onClick={() => history.push("/app/products")}>{t("cart.products")}</IonButton>
          </div>
        )}
        {cart.items && cart.items.length > 0 && (
          <>
            <IonList className="cart-items" lines="none">
              {cart.items.map((i, index) => {
                const image = getMainImage(i.item);
                return (
                  <IonItemSliding key={index}>
                    <IonItemOptions side="start">
                      <IonItemOption color="danger" onClick={() => removeFromCart(i)}>
                        {t("cart.delete")}
                      </IonItemOption>
                    </IonItemOptions>
                    <IonItem>
                      <IonCard className="cart-item" onClick={() => openCartItem(i, index)}>
                        {!i.menu && (
                          <div className="image" style={image ? { backgroundImage: `url('${image}')` } : {}}>
                            {!image && <IonIcon className="placeholder" icon={imageOutline} />}
                          </div>
                        )}
                        <div className="content">
                          {!i.menu && (
                            <div>
                              <div className="name">{i.item.description}</div>
                              {i.item.multivariant && i.sku && <div className="subname">{i.sku.descriptionReceipt}</div>}
                              {i.additionRemovals && i.additionRemovals.length > 0 && (
                                <div className="subname">
                                  {i.additionRemovals
                                    .map((ar) => {
                                      const addition = ar.optionValue?.valueType === OptionValueType.POSITIVE ? "+ " : "";
                                      const removal = ar.optionValue?.valueType === OptionValueType.NEGATIVE ? "- " : "";
                                      return `${addition}${removal}${ar.optionValue?.value}`;
                                    })
                                    .join(", ")}
                                </div>
                              )}
                              <div className="quantity">
                                <IonButton
                                  className="sub"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    subQuantity(index, i);
                                  }}
                                >
                                  <IonIcon icon={remove} />
                                </IonButton>
                                <div className="quantityValue">{i.quantity}</div>
                                <IonButton
                                  className="add"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    addQuantity(index, i);
                                  }}
                                >
                                  <IonIcon icon={add} />
                                </IonButton>
                              </div>
                              <div className="price">{getPrice(getAdditionRemovalPrice(i.price, i.additionRemovals), tenant.settings?.fidelityAccount?.account.currency, false, i.quantity)}</div>
                              <div className="errors">
                                {i.errors?.map((e, index) => (
                                  <div key={index}>{e}</div>
                                ))}
                              </div>
                            </div>
                          )}

                          {i.menu && (
                            <div>
                              <div className="name">{i.menu.item?.description}</div>
                              {i.menu.courses.map((c) =>
                                c.courseChoices.map((cc) => {
                                  return (
                                    <>
                                      <div className="subname">
                                        {cc.sku.descriptionReceipt} {cc.quantity > 1 && <span> qt:{cc.quantity}</span>}
                                      </div>
                                      {cc.additionRemovals && cc.additionRemovals.length > 0 && (
                                        <div className="ar">
                                          {cc.additionRemovals
                                            .map((ar) => {
                                              const addition = ar.optionValue?.valueType === OptionValueType.POSITIVE ? "+ " : "";
                                              const removal = ar.optionValue?.valueType === OptionValueType.NEGATIVE ? "- " : "";
                                              return `${addition}${removal}${ar.optionValue?.value}`;
                                            })
                                            .join(", ")}
                                        </div>
                                      )}
                                    </>
                                  );
                                })
                              )}
                              <div className="quantity">
                                <IonButton
                                  className="sub"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    subQuantity(index, i);
                                  }}
                                >
                                  <IonIcon icon={remove} />
                                </IonButton>
                                <IonInput type="number" readonly value={i.quantity} />
                                <IonButton
                                  className="add"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    addQuantity(index, i);
                                  }}
                                >
                                  <IonIcon icon={add} />
                                </IonButton>
                              </div>
                              <div className="price">{getPrice(getPriceMenu(i.menu), tenant.settings?.fidelityAccount?.account.currency, false, i.quantity)}</div>

                              <div className="errors">
                                {i.errors?.map((e, index) => (
                                  <div key={index}>{e}</div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </IonCard>
                    </IonItem>
                  </IonItemSliding>
                );
              })}
            </IonList>
          </>
        )}
      </IonContent>
      {cart.items && cart.items.length > 0 && variation?.variation && (
        <IonFooter className="buy-bar">
          <div className="content">
            <div className="recap">
              <div className="title">{t("cart.subtotal")}:</div>
              <div className="value">{getPrice(getCartTotal(cart), tenant.settings?.fidelityAccount?.account.currency)}</div>
            </div>
            <div className="recap">
              <div className="title">{t("cart.discount")}:</div>
              <div className="value">{variation.variation}%</div>
            </div>
            <div className="recap">
              <div className="title">{t("cart.total")}:</div>
              <div className="value">{getPrice(getCartTotal(cart) - (getCartTotal(cart) * variation.variation) / 100, tenant.settings?.fidelityAccount?.account.currency)}</div>
            </div>
            <IonButton onClick={buy} expand="block">
              {t("cart.buy")}
            </IonButton>
          </div>
        </IonFooter>
      )}
      {cart.items && cart.items.length > 0 && variation === undefined && (
        <IonFooter className="buy-bar">
          <div className="content">
            <div className="recap">
              <div className="title">{t("cart.total")}:</div>
              <div className="value">{getPrice(getCartTotal(cart), tenant.settings?.fidelityAccount?.account.currency)}</div>
            </div>
            <IonButton onClick={buy} expand="block">
              {t("cart.buy")}
            </IonButton>
          </div>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default Cart;
