import { gql } from "@apollo/client";

export const registerWithEmailMutation = gql`
  mutation RegisterWithEmail($email: String!, $password: String!) {
    register(input: { username: $email, password: $password }) @rest(type: "FidelityCustomer", path: "/customer/enterprise-register", method: "POST") {
      id
    }
  }
`;

export const loginWithEmailMutation = gql`
  mutation LoginWithEmail($email: String!, $password: String!) {
    login(username: $email, password: $password) @rest(type: "AccessToken", path: "/access-token?{args}", method: "GET") {
      accessToken {
        token
      }
      appCustomerDevice {
        id
        idFidelityIdentifier
      }
    }
  }
`;

export const logoutUserMutation = gql`
  mutation LogoutUser($idAppCustomerDevice: String!) {
    logoutUser(idAppCustomerDevice: $idAppCustomerDevice) @rest(type: "Logout", path: "/access-token?idAppCustomerDevice={args.idAppCustomerDevice}", method: "DELETE") {
      id
    }
  }
`;

export const loginWithFacebookMutation = gql`
  mutation LoginWithFacebook($facebookToken: String!) {
    login(facebookToken: $facebookToken) @rest(type: "AccessToken", path: "/access-token?{args}", method: "GET") {
      accessToken {
        token
      }
    }
  }
`;

export const passwordResetMutation = gql`
  mutation PasswordReset($email: String!) {
    passwordReset(input: { username: $email }) @rest(type: "PasswordReset", path: "/customer/recovery-request", method: "POST") {
      id
    }
  }
`;

export const userQuery = gql`
  query GetUser {
    user @rest(type: "FidelityCustomer", path: "/customer", method: "GET") {
      id
      username
      prepaidAccounts
      customer {
        id
        name
        email
        phoneNumber
        street
        city
        district
        zipcode
        country
        lotteryCode
        variation1
        variation2
        variation3
        variation4
      }
    }
  }
`;

export const updateUserMutation = gql`
  mutation UpdateUser($customer: FidelityCustomer) {
    updateUser(input: $customer) @rest(type: "FidelityCustomer", path: "/customer", method: "PUT") {
      id
      username
      customer {
        name
        email
        phoneNumber
        street
        city
        district
        zipcode
        country
        lotteryCode
        variation1
        variation2
        variation3
        variation4
      }
    }
  }
`;

export const accountConfigurationsQuery = gql`
  query GetAccountConfigurations {
    salesPoints @rest(type: "AccountConfigurations", path: "/fidelity-account-configuration", method: "GET") {
      id
      account {
        id
        currency {
          id
          code
          name
          numberOfDecimals
        }
      }
      fidelitySalesPoints {
        id
        salesPoint {
          externalOrdersSettings {
            mobileCommerce {
              defaultViewProducts
            }
            maximumBillItemQuantityAllowed
            acceptedPayments {
              paymentType
              stripeProfile {
                id
                publishableKey
              }
            }
            delivery {
              foreignShippingFee
              nationalShippingFee
              otherDeliveryEnabled
              pickUpEnabled
              shippingAverageTime
              shippingEnabled
              shippingMaxDistance
              tableEnabled
            }
          }
        }
      }
      fidelityCircuit {
        id
        appDefault
        freeReloadBonus
        name
        variation
        idFidelityAccount
      }
    }
  }
`;

const categoriesFields = encodeURIComponent(
  JSON.stringify({
    id: true,
    description: true,
    imageUrl: true,
  })
);

export const categoriesQuery = gql`
query GetCategoriesQuery($idSalesPoint: Int!, $start: Int, $limit: Int) {
    categories(idSalesPoint: $idSalesPoint, start: $start, limit: $limit)
      @rest(type: "CategoryResponse", path: "/configs/{args.idSalesPoint}/categories?start={args.start}&limit={args.limit}&fields=${categoriesFields}", method: "GET") {
        records @type(name: "Category") {
          id
          description
          imageUrl
        }
    }
  }
`;

export const tableWithIdQuery = gql`
  query GetTableWithIdQuery($idSalesPoint: Int!, $filter: TableFilter) {
    table(idSalesPoint: $idSalesPoint, filter: $filter) @rest(type: "TableResponse", path: "/configs/{args.idSalesPoint}/tables?filter={args.filter}", method: "GET") {
      records @type(name: "Table") {
        name
      }
    }
  }
`;

const productsFields = encodeURIComponent(
  JSON.stringify({
    id: true,
    description: true,
    composition: true,
    menu: true,
    prices: true,
    itemImages: {
      idItem: true,
      imageUrl: true,
      position: true,
    },
  })
);

export const productsQuery = gql`
query GetProductsQuery($idSalesPoint: Int!, $start: Int, $limit: Int, $filter: ProductsFilter) {
    products(idSalesPoint: $idSalesPoint, start: $start, limit: $limit, filter: $filter)
      @rest(type: "ProductsResponse", path: "/configs/{args.idSalesPoint}/items?start={args.start}&limit={args.limit}&filter={args.filter}&fields=${productsFields}", method: "GET") {
        records @type(name: "Item") {
          id
          description
          composition
          menu
          prices {
            price
          }
          itemImages {
            imageUrl
            position
          }
        }
    }
  }
`;

const menuProductsFields = encodeURIComponent(
  JSON.stringify({
    id: true,
    description: true,
    composition: true,
    multivariant: true,
    menu: true,
    idDepartment: true,
    department: {
      id: true,
      idTax: true,
    },
    idCategory: true,
    category: {
      id: true,
      optionBindings: {
        idItem: true,
        idCategory: true,
        idOption: true,
        option: {
          id: true,
          description: true,
        },
        bindingType: true,
        position: true,
      },
      optionValueBindings: {
        id: true,
        idItem: true,
        idCategory: true,
        idOptionValue: true,
        isDefault: true,
        optionValue: {
          id: true,
          idOption: true,
          valueType: true,
          value: true,
          position: true,
        },
        prices: true,
      },
    },
    idSku: true,
    sku: {
      id: true,
      idItem: true,
      descriptionReceipt: true,
      prices: true,
      skuOptionValues: {
        idSku: true,
        idOptionValueBinding: true,
      },
      stockConfigs: {
        id: true,
        idSku: true,
        live: true,
        stockStatus: {
          id: true,
          stockLevel: true,
          outgoingQuantity: true,
        },
      },
    },
    optionBindings: {
      idItem: true,
      idCategory: true,
      idOption: true,
      option: {
        id: true,
        description: true,
      },
      bindingType: true,
      position: true,
    },
    optionValueBindings: {
      id: true,
      idItem: true,
      idCategory: true,
      idOptionValue: true,
      isDefault: true,
      optionValue: {
        id: true,
        idOption: true,
        valueType: true,
        value: true,
        position: true,
      },
      prices: true,
    },
    prices: true,
    itemImages: {
      idItem: true,
      imageUrl: true,
      position: true,
    },
  })
);

export const menuProductsQuery = gql`
query GetMenuProductsQuery($idSalesPoint: Int!, $start: Int, $limit: Int, $filter: ProductsFilter) {
    products(idSalesPoint: $idSalesPoint, start: $start, limit: $limit, filter: $filter)
      @rest(type: "ProductsResponse", path: "/configs/{args.idSalesPoint}/items?start={args.start}&limit={args.limit}&filter={args.filter}&fields=${menuProductsFields}", method: "GET") {
        records @type(name: "Item") {
          id
          description
          composition
          multivariant
          menu
          idDepartment
          department {
            id
            idTax
          }
          idCategory
          category {
            optionBindings {
              id
              idItem
              idCategory
              idOption
              option {
                id
                description
              }
              bindingType
              position
            }
            optionValueBindings {
              id
              idItem
              idCategory
              idOptionValue
              isDefault
              optionValue {
                id
                idOption
                valueType
                value
                position
              }
              prices {
                price
                percentagePrice
              }
            }
          }
          sku {
            id
            descriptionReceipt
            skuOptionValues {
              idOptionValueBinding
            }
            prices {
              price
            }
            stockConfigs {
              live
              stockStatus {
                stockLevel
                outgoingQuantity
              }
            }
          }
          optionBindings {
            id
            idItem
            idCategory
            idOption
            option {
              id
              description
            }
            bindingType
            position
          }
          optionValueBindings {
            id
            idItem
            idCategory
            idOptionValue
            isDefault
            optionValue {
              id
              idOption
              valueType
              value
              position
            }
            prices {
              price
              percentagePrice
            }
          }
          prices {
            price
          }
          itemImages {
            imageUrl
            position
          }
        }
    }
  }
`;

const productDetailsFields = encodeURIComponent(
  JSON.stringify({
    id: true,
    description: true,
    descriptionExtended: true,
    sku: {
      id: true,
      idItem: true,
      descriptionReceipt: true,
      prices: true,
      skuOptionValues: {
        idSku: true,
        idOptionValueBinding: true,
      },
      stockConfigs: {
        id: true,
        idSku: true,
        live: true,
        stockStatus: {
          id: true,
          stockLevel: true,
          outgoingQuantity: true,
        },
      },
    },
    idDepartment: true,
    department: {
      id: true,
      idTax: true,
    },
    idCategory: true,
    category: {
      id: true,
      optionBindings: {
        idItem: true,
        idCategory: true,
        idOption: true,
        option: {
          id: true,
          description: true,
        },
        bindingType: true,
        position: true,
      },
      optionValueBindings: {
        id: true,
        idItem: true,
        idCategory: true,
        idOptionValue: true,
        isDefault: true,
        optionValue: {
          id: true,
          idOption: true,
          valueType: true,
          value: true,
          position: true,
        },
        prices: true,
      },
    },
    composition: true,
    menu: true,
    multivariant: true,
    optionBindings: {
      idItem: true,
      idCategory: true,
      idOption: true,
      option: {
        id: true,
        description: true,
      },
      bindingType: true,
      position: true,
    },
    optionValueBindings: {
      id: true,
      idItem: true,
      idCategory: true,
      idOptionValue: true,
      isDefault: true,
      optionValue: {
        id: true,
        idOption: true,
        valueType: true,
        value: true,
        position: true,
      },
      prices: true,
    },
    courses: true,
    prices: true,
    itemImages: {
      idItem: true,
      imageUrl: true,
      position: true,
    },
  })
);

export const productDetailsQuery = gql`
query GetProductDetailsQuery($idSalesPoint: Int!, $filter: ProductsFilter) {
    productDetails(idSalesPoint: $idSalesPoint, filter: $filter)
      @rest(type: "ProductDetailsResponse", path: "/configs/{args.idSalesPoint}/items?filter={args.filter}&fields=${productDetailsFields}", method: "GET") {
        records @type(name: "Item") {
          id
          description
          descriptionExtended
          sku {
            id
            descriptionReceipt
            skuOptionValues {
              idOptionValueBinding
            }
            prices {
              price
            }
            stockConfigs {
              live
              stockStatus {
                stockLevel
                outgoingQuantity
              }
            }
          }
          idDepartment
          department {
            id
            idTax
          }
          idCategory
          category {
            optionBindings {
              id
              idItem
              idCategory
              idOption
              option {
                id
                description
              }
              bindingType
              position
            }
            optionValueBindings {
              id
              idItem
              idCategory
              idOptionValue
              isDefault
              optionValue {
                id
                idOption
                valueType
                value
                position
              }
              prices {
                price
                percentagePrice
              }
            }
          }
          idTax
          multivariant
          composition
          menu
          optionBindings {
            id
            idItem
            idCategory
            idOption
            option {
              id
              description
            }
            bindingType
            position
          }
          optionValueBindings {
            id
            idItem
            idCategory
            idOptionValue
            isDefault
            optionValue {
              id
              idOption
              valueType
              value
              position
            }
            prices {
              price
              percentagePrice
            }
          }
          prices {
            price
          }
          courses {
            id
            orderTicketCourseNumber
            min
            max
            position
            courseChoices {
              id
              multiplier
              item {
                id
                description
                multivariant
              }
              category {
                id
                description
              }
              prices {
                price
              }
            }
            description
          }
          itemImages {
            imageUrl
            position
          }
        }
    }
  }
`;

export const workingPeriodSlotsQuery = gql`
  query GetWorkingPeriodSlotsQuery($idSalesPoint: Int!, $filter: WorkingPeriodSlotFilter) {
    workingPeriodSlots(idSalesPoint: $idSalesPoint, filter: $filter) @rest(type: "WorkingPeriodSlotResponse", path: "/orders/{args.idSalesPoint}/working-periods-available?filter={args.filter}", method: "GET") {
      slots @type(name: "WorkingPeriodSlot") {
        start
        availableDeliverableOrders
        availablePiecesOrderable
        availableOrdersAmount
      }
    }
  }
`;

export const createOrderMutation = gql`
  mutation CreateOrder($idSalesPoint: Int!, $order: Order, $idAppCustomerDevice: string, $idFidelityIdentifier: string) {
    createOrder(idSalesPoint: $idSalesPoint, input: $order, idAppCustomerDevice: $idAppCustomerDevice, idFidelityIdentifier: $idFidelityIdentifier)
      @rest(type: "Bill", path: "/orders/{args.idSalesPoint}?idAppCustomerDevice={args.idAppCustomerDevice}&idFidelityIdentifier={args.idFidelityIdentifier}", method: "POST") {
      id
    }
  }
`;

export const createOrderMutationWithSourceType = gql`
  mutation CreateOrderWithSourceType($idSalesPoint: Int!, $order: Order, $idAppCustomerDevice: string, $idFidelityIdentifier: string, $sourceTypes: SourceType) {
    createOrderWithSourceType(idSalesPoint: $idSalesPoint, input: $order, idAppCustomerDevice: $idAppCustomerDevice, idFidelityIdentifier: $idFidelityIdentifier, sourceTypes: $sourceTypes)
      @rest(type: "Bill", path: "/orders/{args.idSalesPoint}?idAppCustomerDevice={args.idAppCustomerDevice}&idFidelityIdentifier={args.idFidelityIdentifier}&sourceTypes={args.sourceTypes}", method: "POST") {
      id
    }
  }
`;

const ordersFields = encodeURIComponent(
  JSON.stringify({
    id: true,
    order: {
      number: true,
      deliveryMode: true,
      prepay: true,
      prepaymentType: true,
      prepaymentStatus: true,
      externalWorkflowStatus: true,
      rejectionReason: true,
    },
    amount: true,
  })
);

export const ordersQuery = gql`
query GetOrders($start: Int, $limit: Int, $filter: OrdersFilter, $sorts: OrdersSort) {
    orders(start: $start, limit: $limit, filter: $filter, sorts: $sorts)
      @rest(type: "OrdersResponse", path: "/orders?start={args.start}&limit={args.limit}&fields=${ordersFields}&filter={args.filter}&sorts={args.sorts}", method: "GET") {
        records @type(name: "Bill") {
          id
          order {
            number
            deliveryMode
            prepay
            prepaymentType
            prepaymentStatus
            externalWorkflowStatus
            rejectionReason
          }
          amount
        }
    }
  }
`;

const orderDetailsFields = encodeURIComponent(
  JSON.stringify({
    id: true,
    order: {
      number: true,
      deliveryMode: true,
      prepay: true,
      prepaymentType: true,
      prepaymentStatus: true,
      externalWorkflowStatus: true,
      rejectionReason: true,
      code: true,
      idTable: true,
      theTable: {
        id: true,
        name: true,
      },
    },
    billItems: {
      id: true,
      menu: true,
      composition: true,
      idSku: true,
      billItemCourseSkus: true,
      coverCharge: true,
      subtotal: true,
      variation: true,
      rowNumber: true,
      percentageVariation: true,
      sku: {
        id: true,
        descriptionReceipt: true,
        idItem: true,
        item: {
          id: true,
          description: true,
          multivariant: true,
          itemImages: {
            idItem: true,
            imageUrl: true,
            position: true,
          },
        },
      },
      itemOptionValues: {
        idOptionValue: true,
        price: true,
        percentagePrice: true,
        optionValue: {
          id: true,
          value: true,
          valueType: true,
        },
      },
      price: true,
      quantity: true,
      shippingCost: true,
    },
    amount: true,
  })
);

export const orderDetailsQuery = gql`
query GetOrderDetails($filter: OrderFilter) {
    orders(filter: $filter)
      @rest(type: "OrdersResponse", path: "/orders?start=0&limit=1&fields=${orderDetailsFields}&filter={args.filter}", method: "GET") {
        records @type(name: "Bill") {
          id
          order {
            number
            deliveryMode
            prepay
            prepaymentType
            prepaymentStatus
            externalWorkflowStatus
            rejectionReason
            code
            theTable{
              name
            }
          }
          billItems {
            id
            idSku
            menu
            composition
            rowNumber
            coverCharge
            subtotal
            variation
            percentageVariation
            billItemCourseSkus {
              quantity
              price
              componentPrice
              courseSkuOptionValues{
                price
                percentagePrice
                optionValue {
                  value
                  valueType
                }
              }
              idSku
              sku{
                descriptionReceipt
              }
            }
            sku {
              id
              descriptionReceipt
              idItem
              item {
                id
                description
                multivariant,
                itemImages {
                  imageUrl
                  position
                }
              }
            }

            itemOptionValues {
              price
              percentagePrice
              optionValue {
                value
                valueType
              }
            }
            price
            quantity
            shippingCost
          }
          amount
        }
    }
  }
`;

export const getSetupIntentQuery = gql`
  query GetSetupIntent($idSalesPoint: Int!) {
    setupIntent(idSalesPoint: $idSalesPoint) @rest(type: "SetupIntent", path: "/stripe/{args.idSalesPoint}/create-setup-intent", method: "GET") {
      clientSecret
    }
  }
`;

export const getStripePaymentIntentQuery = gql`
  query GetStripePaymentIntent($idSalesPoint: Int!, $idOrder: String!) {
    stripePaymentIntent(idSalesPoint: $idSalesPoint, idOrder: $idOrder) @rest(type: "StripePaymentIntent", path: "/orders/{args.idSalesPoint}/{args.idOrder}/stripe-payment-intent", method: "GET") {
      id
      stripeClientSecret
    }
  }
`;

export const getTSPayLinkToSaveQuery = gql`
  query GetTSPayLinkToSave($idSalesPoint: Int!, $idOrder: String!, $callbackUrl: String!, $sourceTypes: SourceType!) {
    TSPayLinkToSave(idSalesPoint: $idSalesPoint, idOrder: $idOrder, callbackUrl: $callbackUrl, sourceTypes: $sourceTypes) @rest(type: "TSPayLink", path: "/orders/{args.idSalesPoint}/{args.idOrder}/link-to-save-url?callbackUrl={args.callbackUrl}&sourceTypes={args.sourceTypes}", method: "GET") {
      id
      confirmationMethod
      tspayLinkToSaveUrl
    }
  }
`;

export const getSatispayRedirectUrlQuery = gql`
  query GetSatispayRedirectUrl($idSalesPoint: Int!, $idOrder: String!, $callbackUrl: String!) {
    SatispayRedirectUrl(idSalesPoint: $idSalesPoint, idOrder: $idOrder, callbackUrl: $callbackUrl) @rest(type: "SatispayLink", path: "/orders/{args.idSalesPoint}/{args.idOrder}/satispay-payment?callbackUrl={args.callbackUrl}", method: "GET") {
      id
      confirmationMethod
      satispayRedirectUrl
    }
  }
`;

export const paymentMethodsQuery = gql`
  query GetPaymentMethods($idSalesPoint: Int!) {
    paymentMethods(idSalesPoint: $idSalesPoint) @rest(type: "StripePaymentMethodResponse", path: "/stripe/{args.idSalesPoint}/payment-methods", method: "GET") {
      paymentMethods(type: "StripePaymentMethod") {
        id
        brand
        expYear
        expoMonth
        last4
      }
    }
  }
`;

const payableOrderFields = encodeURIComponent(
  JSON.stringify({
    id: true,
    order: {
      prepay: true,
      prepaymentType: true,
      prepaymentStatus: true,
      prepaymentTransactionId: true,
    },
    amount: true,
  })
);

export const payableOrderQuery = gql`
query GetPayableOrder($filter: OrderFilter) {
    orders(filter: $filter)
      @rest(type: "OrdersResponse", path: "/orders?start=0&limit=1&fields=${payableOrderFields}&filter={args.filter}", method: "GET") {
        records @type(name: "Bill") {
          id
          order {
            prepay
            prepaymentType
            prepaymentStatus
            prepaymentTransactionId
          }
          amount
        }
    }
  }
`;

export const orderStripeClientSecretQuery = gql`
  query GetOrderStripeClientSecret($idSalesPoint: Int!, $idOrder: String!) {
    orderStripeClientSecret(idSalesPoint: $idSalesPoint, idOrder: $idOrder) @rest(type: "OrderStripeClientSecret", path: "/orders/{args.idSalesPoint}/{args.idOrder}/stripe-client-secret", method: "GET") {
      stripeClientSecret
    }
  }
`;

export const orderPayedMutation = gql`
  mutation OrderPayed($idSalesPoint: Int!, $idOrder: String!, $prepaymentType: String!) {
    orderPayed(input: { prepaymentType: $prepaymentType }, idSalesPoint: $idSalesPoint, idOrder: $idOrder) @rest(type: "PrepaymentType", path: "/orders/{args.idSalesPoint}/{args.idOrder}/payed", method: "PUT") {
      id
    }
  }
`;

export const orderPayedWithMailMutation = gql`
  mutation OrderPayedWithMail($idSalesPoint: Int!, $idOrder: String!, $email: String, $prepaymentType: String!) {
    orderPayedWithMail(input: { prepaymentType: $prepaymentType }, idSalesPoint: $idSalesPoint, idOrder: $idOrder, email: $email) @rest(type: "PrepaymentType", path: "/orders/{args.idSalesPoint}/{args.idOrder}/payed?email={args.email}", method: "PUT") {
      id
    }
  }
`;

export const cancelOrderMutation = gql`
  mutation CancelOrder($idSalesPoint: Int!, $idOrder: String!) {
    cancelOrder(input: EMPTY, idSalesPoint: $idSalesPoint, idOrder: $idOrder) @rest(type: "CancelOrder", path: "/orders/{args.idSalesPoint}/{args.idOrder}/cancel", method: "PUT") {
      id
    }
  }
`;

export const orderCreatePaymentMutation = gql`
  mutation OrderCreatePayment($idSalesPoint: Int!, $idOrder: String!, $paymentRequest: SplitPaymentRequest!) {
    orderCreatePayment(idSalesPoint: $idSalesPoint, idOrder: $idOrder, input: $paymentRequest) @rest(type: "OrderCreatePayment", path: "/orders/{args.idSalesPoint}/{args.idOrder}/risto-remote-payments", method: "POST") {
      id
      confirmationMethod
      stripeClientSecret
      tspayLinkToSaveUrl
      satispayRedirectUrl
    }
  }
`;

export const orderCreatePaymentWithSourceTypeMutation = gql`
  mutation OrderCreatePaymentWithSourceType($idSalesPoint: Int!, $idOrder: String!, $paymentRequest: SplitPaymentRequest!, $sourceType: SourceType!) {
    orderCreatePaymentWithSourceType(idSalesPoint: $idSalesPoint, idOrder: $idOrder, input: $paymentRequest, sourceType: $sourceType) @rest(type: "OrderCreatePayment", path: "/orders/{args.idSalesPoint}/{args.idOrder}/risto-remote-payments?sourceTypes={args.sourceType}", method: "POST") {
      id
      confirmationMethod
      stripeClientSecret
      tspayLinkToSaveUrl
      satispayRedirectUrl
    }
  }
`;

export const getPaymentsQuery = gql`
  query getPayments($idSalesPoint: Int!, $idOrder: String!) {
    getPayments(idSalesPoint: $idSalesPoint, idOrder: $idOrder) @rest(type: "GetPayments", path: "/orders/{args.idSalesPoint}/{args.idOrder}/risto-remote-payments", method: "GET") {
      totalCount
      records {
        amount
        description
        user
        id
      }
    }
  }
`;
