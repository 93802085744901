import { makeVar, useReactiveVar } from "@apollo/client";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { BillItemOptionValue, Item, Sku } from "../apollo-client/types";
import { MenuItem } from "./menu";

export type CartItem = {
  item: Item;
  sku: Sku;
  quantity: number;
  price: number;
  additionRemovals?: BillItemOptionValue[];
  errors?: string[];
  menu?: MenuItem;
  multiplier?: number;
  componentPrice?: number;
};

export type Cart = {
  items: CartItem[];
};

export const cartVar = makeVar<Cart>({ items: [] });

export const useCart = (): [Cart, (_add: CartItem) => void, (_remove: CartItem) => void, (_index: number, _update: CartItem) => void, () => void, (_errors: { code: string; idSku: string; rowNumbers: number[] }[]) => void, (_replace: CartItem[]) => void] => {
  const cart = useReactiveVar(cartVar);
  const { t } = useTranslation();

  const addToCart = (item: CartItem) => {
    cartVar({ ...cart, items: _.concat(cart.items, item) });
  };

  const removeFromCart = (item: CartItem) => {
    const items = [...cart.items];
    _.remove(items, (i) => i === item);
    cartVar({ ...cart, items });
  };

  const updateItem = (index: number, item: CartItem) => {
    const items = [...cart.items];
    items[index] = item;
    cartVar({ ...cart, items });
  };

  const clean = () => {
    cartVar({ ...cart, items: [] });
  };

  const replace = (items: CartItem[]) => {
    cartVar({ ...cart, items });
  };

  const errors = (errors: { code: string; idSku: string; rowNumbers: number[] }[]) => {
    const items = [...cart.items];
    items.forEach((item, index) => {
      const itemErrors = errors.filter((e) => e.rowNumbers && e.rowNumbers.includes(index + 1));
      const itemErrorMessages = itemErrors?.map((e) => {
        if (e.idSku) {
          return t(`new_order.errors.${e.code}`, { product: item.sku.descriptionReceipt });
        } else {
          return t(`new_order.errors.${e.code}`);
        }
      });
      item.errors = itemErrorMessages || [];
    });
    cartVar({ ...cart, items });
  };

  return [cart, addToCart, removeFromCart, updateItem, clean, errors, replace];
};
