import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonLoading, IonPage, IonRow, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import "./profile.css";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { Customer, FidelityCustomer } from "../../apollo-client/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@apollo/client";
import { updateUserMutation, userQuery } from "../../apollo-client/queries";
import AddressInput, { Address } from "../../components/addressInput";

const schema = yup.object().shape({});

const Profile: React.FC = () => {
  const { t } = useTranslation();
  const [toast] = useIonToast();
  const { handleSubmit, control } = useForm<Customer & { address: Address }>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
  });

  const userResponse = useQuery(userQuery);
  const user: FidelityCustomer = userResponse.data?.user;

  const [updateUser, updateUserResponse] = useMutation(updateUserMutation);

  let address: Address | undefined = undefined;
  if (user?.customer && user.customer.street && user.customer.city) {
    address = {
      street: user?.customer.street,
      city: user?.customer.city,
      zipcode: user?.customer.zipcode,
      district: user?.customer.district,
      country: user?.customer.country,
    };
  }

  const onSubmit = async (data: Customer & { address: Address }) => {
    try {
      const customer = {
        customer: {
          name: data.name,
          phoneNumber: data.phoneNumber,
          lotteryCode: data.lotteryCode,
          ...data.address,
        },
      };
      updateUser({ variables: { customer } });
    } catch (e) {
      toast("error", 5000);
    }
  };

  return (
    <IonPage className="profile">
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{t("profile.title")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonLoading isOpen={userResponse.loading || updateUserResponse.loading} message={t("loading")} />
      <IonContent fullscreen>
        {user && (
          <div className="content">
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <IonRow>
                <IonCol>
                  <IonItem lines="none">
                    <IonLabel position="stacked">{t("profile.email")}</IonLabel>
                    <IonInput value={user?.customer.email} disabled />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonItem lines="none">
                    <IonLabel position="stacked">{t("profile.name")}</IonLabel>
                    <Controller name="name" control={control} defaultValue={user?.customer.name} render={({ field }) => <IonInput onIonChange={field.onChange} onIonBlur={field.onBlur} value={field.value} />} />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonItem lines="none">
                    <IonLabel position="stacked">{t("profile.phone")}</IonLabel>
                    <Controller name="phoneNumber" control={control} defaultValue={user?.customer.phoneNumber} render={({ field }) => <IonInput onIonChange={field.onChange} onIonBlur={field.onBlur} value={field.value} />} />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonItem lines="none">
                    <IonLabel position="stacked">{t("profile.address")}</IonLabel>
                    <Controller name="address" control={control} defaultValue={address} render={({ field }) => <AddressInput fromProfile={true} onChange={field.onChange} value={field.value} />} />
                  </IonItem>
                </IonCol>
              </IonRow>
              {/* <IonRow>
                <IonCol>
                  <IonItem lines="none">
                    <IonLabel position="stacked">{t('profile.card')}</IonLabel>
                    <IonInput readonly ><IonIcon icon={card} slot="end" className="card-icon" /></IonInput>
                  </IonItem>
                </IonCol>
              </IonRow> */}
              <IonRow>
                <IonCol>
                  <IonItem lines="none">
                    <IonLabel position="stacked">{t("profile.lotteria")}</IonLabel>
                    <Controller name="lotteryCode" control={control} defaultValue={user?.customer.lotteryCode} render={({ field }) => <IonInput onIonChange={field.onChange} onIonBlur={field.onBlur} value={field.value} />} />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton className="save" type="submit" expand="block" disabled={updateUserResponse.loading}>
                    {t("profile.save")}
                  </IonButton>
                </IonCol>
              </IonRow>
            </form>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Profile;
