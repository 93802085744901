import { ActionSheetButton, IonActionSheet, IonButton, IonCard, IonCol, IonContent, IonGrid, IonHeader, IonInfiniteScroll, IonInfiniteScrollContent, IonLoading, IonPage, IonRefresher, IonRefresherContent, IonRow, IonTitle, IonToolbar, isPlatform } from "@ionic/react";
import "./orders.css";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { ordersQuery } from "../../apollo-client/queries";
import { RefresherEventDetail } from "@ionic/core";
import { useCallback, useEffect, useState } from "react";
import { Bill, DeliveryMode, ExternalOrdersPaymentType, PrepaymentStatus, SourceType } from "../../apollo-client/types";
import { useAuth } from "../../hooks/auth";
import { useHistory } from "react-router";
import { getOrderStatusColor, getPrice, getSalesPoint } from "../../utils";
import { useTenant } from "../../hooks/multiTenancy";
import { useSelectedSalesPoint } from "../../hooks/selectedSalesPoint";

const PAGE_SIZE = 20;

const Orders: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const tenant = useTenant();
  const [auth] = useAuth();
  const [selectedSalesPointId] = useSelectedSalesPoint();
  const [orders, setOrders] = useState<Bill[]>();
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [buttons, setButtons] = useState<(string | ActionSheetButton<unknown>)[] | undefined>([]);

  const selectedSalesPoint = getSalesPoint(tenant, selectedSalesPointId);
  const externalOrdersSettings = selectedSalesPoint?.salesPoint?.externalOrdersSettings;
  const eopTSPay = externalOrdersSettings?.acceptedPayments?.filter((eop) => eop.paymentType === ExternalOrdersPaymentType.TSPAY);

  const st = eopTSPay && eopTSPay?.length > 0 ? eopTSPay[0].sourceTypes : undefined;

  const ordersResponse = useQuery(ordersQuery, {
    skip: !selectedSalesPoint || !auth.authenticated,
    variables: {
      start: 0,
      limit: PAGE_SIZE,
      filter: encodeURIComponent(JSON.stringify({ idSalesPoint: [selectedSalesPoint!.salesPoint!.id] })),
      sorts: encodeURIComponent(JSON.stringify({ datetime: -1 })),
    },
  });

  useEffect(() => {
    if (ordersResponse.data) {
      setOrders(ordersResponse.data.orders.records);
      setDisableInfiniteScroll(false);
    }
  }, [ordersResponse]);

  const nextPage = async (e: CustomEvent<void>) => {
    const moreRecords = await ordersResponse.fetchMore({
      variables: {
        start: orders?.length,
        limit: PAGE_SIZE,
        filter: encodeURIComponent(JSON.stringify({ idSalesPoint: [selectedSalesPoint!.salesPoint!.id] })),
        sorts: encodeURIComponent(JSON.stringify({ datetime: -1 })),
      },
    });
    setOrders([...(orders || []), ...moreRecords.data.orders.records]);
    if (moreRecords.data.orders.records.length < PAGE_SIZE) {
      setDisableInfiniteScroll(true);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e?.target as any).complete();
  };

  const refresh = async (event: CustomEvent<RefresherEventDetail>) => {
    await ordersResponse.refetch();
    event.detail.complete();
  };

  const pay = useCallback(
    (e: React.MouseEvent<HTMLElement>, order: Bill) => {
      e.stopPropagation();
      setButtons([
        {
          text: t("payment_types.CREDIT_CARD"),
          handler: () => {
            history.push(`/app/orders/tspay/${order.id}/${SourceType.CREDIT_CARD}`);
          },
        },
        {
          text: t("payment_types.PAYPAL"),
          handler: () => {
            history.push(`/app/orders/tspay/${order.id}/${SourceType.PAYPAL}`);
          },
        },
      ]);

      if (order.order?.prepaymentType === ExternalOrdersPaymentType.STRIPE) {
        history.push(`/app/orders/stripepay/${order.id}`);
      }
      if (order.order?.prepaymentType === ExternalOrdersPaymentType.SATISPAY) {
        history.push(`/app/orders/satispay/${order.id}`);
      }
      if (order.order?.prepaymentType === ExternalOrdersPaymentType.TSPAY) {
        if (st && st.length > 1) {
          setShowActionSheet(true);
        } else {
          if (st && st.length == 1) {
            history.push(`/app/orders/tspay/${order.id}/${st[0]}`);
          }
        }
      }
    },
    [history]
  );

  return (
    <IonPage className="orders">
      <IonHeader>
        <IonToolbar color="secondary">
          <IonTitle>{t("menu.orders")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonLoading isOpen={ordersResponse.loading} showBackdrop={false} cssClass="infinite-scroll-loader" spinner={isPlatform("ios") ? "lines" : "circular"} />
      <IonContent fullscreen>
        {!auth.authenticated && (
          <div className="no-auth">
            <div className="message">{t("orders.no_auth")}</div>
            <IonButton onClick={() => history.push("/")}>{t("orders.login")}</IonButton>
          </div>
        )}
        {auth.authenticated && (
          <>
            <IonRefresher slot="fixed" onIonRefresh={refresh}>
              <IonRefresherContent></IonRefresherContent>
            </IonRefresher>
            {!ordersResponse.loading && orders?.length === 0 && <div className="no-results-placeholder">{t("orders.no_results")}</div>}
            {orders && orders?.length > 0 && (
              <IonGrid className="orders-grid">
                <IonRow>
                  {orders?.map((o) => {
                    return (
                      <IonCol key={o.id} size="12">
                        <IonCard key={o.id} className="order" onClick={() => history.push(`/app/orders/${o.id}`)}>
                          <div className="section order-title">
                            <div>{t("orders.order", { number: o.order?.number })}</div>
                            <div
                              className="status"
                              style={{
                                color: getOrderStatusColor(o.order!.externalWorkflowStatus!),
                              }}
                            >
                              {t(`order_status.${o.order?.externalWorkflowStatus}`)}
                            </div>
                          </div>
                          <div className="section section-horizontal">
                            <div className="title">{t("orders.delivery")}</div>
                            {o.order?.deliveryMode && <div className="value">{t(`delivery_modes.${o.order?.deliveryMode}`)}</div>}
                          </div>
                          <div className="section section-horizontal">
                            <div className="title">{t("orders.payment")}</div>
                            <div className="value">
                              <div>{getPrice(o.amount!, tenant.settings?.fidelityAccount?.account.currency)}</div>
                              {!o.order?.prepay && !(o.order?.deliveryMode == DeliveryMode.TABLE) && t(`payment_types.CASH_ON_DELIVERY`)}
                              {!o.order?.prepay && o.order?.deliveryMode == DeliveryMode.TABLE && t(`payment_types.CASH_ON_TABLE`)}
                              {o.order?.prepaymentType && t(`payment_types.${o.order?.prepaymentType}`)}
                            </div>
                          </div>
                          {o.order?.prepaymentStatus === PrepaymentStatus.WAITING && (
                            <div className="section order-pay">
                              <IonButton onClick={(e) => pay(e, o)}>{t("orders.pay")}</IonButton>
                            </div>
                          )}
                        </IonCard>
                      </IonCol>
                    );
                  })}
                </IonRow>
              </IonGrid>
            )}
            <IonInfiniteScroll disabled={disableInfiniteScroll} threshold="200px" onIonInfinite={(e: CustomEvent<void>) => nextPage(e)}>
              <IonInfiniteScrollContent loadingText={t("loading")} />
            </IonInfiniteScroll>
          </>
        )}
        <IonActionSheet isOpen={showActionSheet} onDidDismiss={() => setShowActionSheet(false)} cssClass="my-custom-class" buttons={buttons}></IonActionSheet>
      </IonContent>
    </IonPage>
  );
};

export default Orders;
