import { IonIcon, IonInput, IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonItem, IonLabel, IonModal, IonLoading } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { close, qrCode } from "ionicons/icons";
import { useCallback, useEffect, useState } from "react";
import QrReader from "react-qr-reader";
import "./qrInput.css";
import { tableWithIdQuery } from "../apollo-client/queries";
import { useLazyQuery } from "@apollo/client";

type QRInputProps = {
  message?: string;
  title?: string;
  disabled?: boolean;
  table?: boolean;
  selectedSalesPointId?: number;
  selectedTable?: string;
  onChange?: (value: string) => void;
};

const QRInput: React.FC<QRInputProps> = ({ title, message, table, selectedSalesPointId, selectedTable, disabled, onChange }) => {
  const [qrValue, setQRValue] = useState<string | { idTable: string; tableName: string }>();
  const [idTable, setIdTable] = useState<string>();
  const [showModal, setShowModal] = useState(false);
  const [getTable, tableResponse] = useLazyQuery(tableWithIdQuery);

  useEffect(() => {
    if (selectedTable) {
      getTable({
        variables: {
          idSalesPoint: selectedSalesPointId,
          filter: encodeURIComponent(JSON.stringify({ id: [selectedTable] })),
        },
      });
      setIdTable(selectedTable);
      selectedTable = undefined;
    }
  }, []);

  const onClick = useCallback(() => {
    if (!disabled) {
      setShowModal(true);
    }
  }, [disabled, showModal]);

  useEffect(() => {
    if (tableResponse.data && idTable) {
      if (tableResponse.data.table.records[0].name) setQRValue({ idTable, tableName: tableResponse.data.table.records[0].name });
    }
  }, [tableResponse.data, idTable]);

  const { t } = useTranslation();

  const confirm = useCallback(() => {
    if (onChange) {
      if (table) {
        onChange((qrValue as { idTable: string; tableName: string }).idTable);
      } else {
        onChange(qrValue as string);
      }
    }
    setShowModal(false);
  }, [qrValue]);

  const onScan = async (data: string | null) => {
    if (data) {
      if (table) {
        if (data.startsWith("http")) {
          const idString = data.substring(data.lastIndexOf("/") + 1);
          await getTable({
            variables: {
              idSalesPoint: selectedSalesPointId,
              filter: encodeURIComponent(JSON.stringify({ id: [idString] })),
            },
          });
          setIdTable(idString);
        } else {
          setQRValue(JSON.parse(data));
        }
      } else {
        setQRValue(data);
      }
    }
  };

  return (
    <>
      <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
        <IonPage className="qr-input-modal">
          <IonHeader>
            <IonToolbar color="secondary">
              <IonButtons slot="start">
                <IonButton onClick={() => setShowModal(false)}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>
              <IonTitle>{title}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonLoading isOpen={tableResponse.loading} />
          <IonContent fullscreen>
            {message && <div className="message">{message}</div>}
            <div className="qr-container">
              <QrReader
                delay={300}
                onError={(e) => {
                  console.log(e);
                }}
                onScan={onScan}
                style={{ width: "300px" }}
              />
            </div>
            <div className="content">
              <IonItem lines="none">
                <IonLabel position="stacked">{title}</IonLabel>
                <IonInput
                  readonly={table}
                  value={table && qrValue ? (qrValue as { idTable: string; tableName: string }).tableName : (qrValue as string)}
                  onIonChange={(v) => {
                    if (!table) setQRValue(v.detail.value!);
                  }}
                />
              </IonItem>
              <IonButton disabled={!qrValue} expand="block" onClick={confirm}>
                {t("qr.confirm")}
              </IonButton>
            </div>
          </IonContent>
        </IonPage>
      </IonModal>

      <IonInput disabled={disabled} className="qr-input" value={table && qrValue ? (qrValue as { idTable: string; tableName: string }).tableName : (qrValue as string)} readonly onClick={onClick}>
        <IonIcon icon={qrCode} slot="end" className="qr-icon" />
      </IonInput>
    </>
  );
};

export default QRInput;
