import { ApolloClient, from, InMemoryCache, NormalizedCacheObject, ServerError } from "@apollo/client";
import { RestLink } from "apollo-link-rest";
import { onError } from "@apollo/client/link/error";
import { Auth } from "../hooks/auth";

export const createApolloClient = (apiKey: string, auth: Auth, logout: (client: ApolloClient<NormalizedCacheObject>) => void) => {
  const browserLanguage = navigator.language;

  const headers: { [key: string]: string } = {
    "api-key": apiKey,
    "api-version": process.env.REACT_APP_API_VERSION || "0.0.1",
    "Accept-Language": browserLanguage,
  };
  if (auth.authenticated && auth.token) headers["access-token"] = auth.token;

  const restLink = new RestLink({
    uri: process.env.REACT_APP_API_BASEURL,
    credentials: "include",
    headers,
  });

  const errorLink = onError(({ networkError }) => {
    if (networkError && ((networkError as ServerError).statusCode === 401 || (networkError as ServerError).statusCode === 403) && auth.authenticated) {
      logout(apolloClient);
    }
  });

  const cache = new InMemoryCache();

  const apolloClient = new ApolloClient({
    link: from([errorLink, restLink]),
    cache: cache,
  });

  return apolloClient;
};
