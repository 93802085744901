import { IonBackButton, IonButton, IonButtons, IonCard, IonContent, IonHeader, IonIcon, IonItem, IonList, IonLoading, IonPage, IonTitle, IonToolbar, useIonAlert, useIonPopover, useIonToast } from "@ionic/react";
import "./order.css";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { cancelOrderMutation, orderDetailsQuery, ordersQuery } from "../../apollo-client/queries";
import { Bill, ExternalWorkflowStatus, OptionValueType, PrepaymentStatus } from "../../apollo-client/types";
import { useHistory, useParams } from "react-router";
import { getAdditionRemovalPrice, getOrderMenuPrice, getOrderStatusColor, getPrice } from "../../utils";
import { useTenant } from "../../hooks/multiTenancy";
import { ellipsisVertical } from "ionicons/icons";
import { useCallback } from "react";
import { useSelectedSalesPoint } from "../../hooks/selectedSalesPoint";
import { useCart } from "../../hooks/cart";

const Order: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { idOrder } = useParams<{ idOrder: string }>();
  const tenant = useTenant();
  const [cart, , , , , , replaceCart] = useCart();
  const [selectedSalesPointId] = useSelectedSalesPoint();

  const [showAlert] = useIonAlert();
  const [toast] = useIonToast();

  const ordersResponse = useQuery(orderDetailsQuery, {
    variables: {
      filter: encodeURIComponent(JSON.stringify({ id: [idOrder] })),
    },
  });
  const order: Bill = ordersResponse.data?.orders?.records ? ordersResponse.data?.orders?.records[0] : undefined;

  const popoverBody = (
    <div className="order-popover">
      <IonList lines="none">
        <IonItem button onClick={() => copyClick()}>
          {t("order.copy")}
        </IonItem>
        {order?.order?.externalWorkflowStatus === ExternalWorkflowStatus.REQUESTED && (
          <IonItem detail={false} button onClick={() => cancelOrderClick()}>
            {t("order.delete")}
          </IonItem>
        )}
      </IonList>
    </div>
  );
  const [presentPopover, dismissPopover] = useIonPopover(popoverBody, { onHide: () => dismissPopover() });

  const pay = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      history.push(`/app/orders/${order.id}/pay`);
    },
    [history, order]
  );

  const [cancelOrder, cancelOrderResponse] = useMutation(cancelOrderMutation);

  const copy = () => {
    const newItems = (order.billItems || [])
      ?.filter((billItem) => billItem.sku && billItem.sku.item && billItem.quantity && billItem.price && !billItem.menu && !billItem.composition)
      .map((billItem) => ({
        item: billItem.sku!.item!,
        sku: billItem.sku!,
        quantity: billItem.quantity!,
        price: billItem.price!,
        additionRemovals: billItem.itemOptionValues,
      }));
    replaceCart(newItems);
    history.push("/app/cart");
  };

  const copyClick = () => {
    dismissPopover();
    if (cart.items.length > 0) {
      showAlert({
        header: t("order.copy_alert.title"),
        message: t("order.copy_alert.message"),
        buttons: [
          t("order.copy_alert.cancel"),
          {
            text: t("order.copy_alert.confirm"),
            handler: () => {
              copy();
            },
          },
        ],
      });
    } else {
      copy();
    }
  };

  const cancelOrderClick = useCallback(() => {
    showAlert({
      header: t("order.cancel_alert.title"),
      message: t("order.cancel_alert.message"),
      buttons: [
        t("order.cancel_alert.cancel"),
        {
          text: t("order.cancel_alert.confirm"),
          handler: async () => {
            dismissPopover();
            try {
              await cancelOrder({
                variables: {
                  idSalesPoint: selectedSalesPointId,
                  idOrder: order.id,
                },
                refetchQueries: [ordersQuery, "GetOrders"],
              });
              history.push(`/app/orders`);
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (e: any) {
              toast(e.message, 5000);
            }
          },
        },
      ],
    });
  }, [order, selectedSalesPointId]);

  return (
    <IonPage className="order">
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{t("order.title")}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={(e) => presentPopover({ event: e.nativeEvent })}>
              <IonIcon slot="icon-only" icon={ellipsisVertical} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonLoading isOpen={ordersResponse.loading || cancelOrderResponse.loading} message={t("loading")} />
      <IonContent fullscreen>
        {order && (
          <div className="content">
            <IonCard className="header">
              <div className="section order-title">
                <div>{t("orders.order", { number: order.order?.number })}</div>
                <div className="status" style={{ color: getOrderStatusColor(order.order!.externalWorkflowStatus!) }}>
                  {t(`order_status.${order.order?.externalWorkflowStatus}`)}
                </div>
              </div>
              {order.order?.rejectionReason && (
                <div className="section section-horizontal">
                  <div className="title">{t(`orders.rejectionReason`)}</div>
                  <div className="value">{order.order?.rejectionReason}</div>
                </div>
              )}
              <div className="section section-horizontal">
                <div className="title">{t("orders.delivery")}</div>
                {order.order?.deliveryMode && <div className="value">{t(`delivery_modes.${order.order?.deliveryMode}`)}</div>}
              </div>
              <div className="section section-horizontal">
                <div className="title">{t("orders.payment")}</div>
                <div className="value">
                  <div>{getPrice(order.amount!, tenant.settings?.fidelityAccount?.account.currency)}</div>
                  {!order.order?.prepay && t(`payment_types.CASH_ON_DELIVERY`)}
                  {order.order?.prepaymentType && t(`payment_types.${order.order?.prepaymentType}`)}
                </div>
              </div>
              {order.order?.prepaymentStatus === PrepaymentStatus.WAITING && false && (
                <div className="section order-pay">
                  <IonButton onClick={pay}>{t("orders.pay")}</IonButton>
                </div>
              )}
            </IonCard>
            <IonCard key={order.id} className="body">
              {(order.billItems || []).map((billItem) => {
                const description = billItem.shippingCost ? t("order.delivery") : billItem.sku?.descriptionReceipt;

                if (billItem.menu || billItem.composition) {
                  return (
                    <div key={billItem.id} className="item">
                      <div className="quantity">{billItem.quantity}</div>
                      <div className="title">
                        <div className="menuName">{description}</div>
                        {(billItem.billItemCourseSkus || []).map((courseSku) => {
                          return (
                            <>
                              <div className="ccName">
                                {courseSku.sku?.descriptionReceipt}
                                {courseSku.quantity! > 1 && <span> qt:{courseSku.quantity}</span>}
                              </div>
                              <div className="ar">
                                {courseSku.courseSkuOptionValues &&
                                  courseSku.courseSkuOptionValues.length > 0 &&
                                  courseSku.courseSkuOptionValues
                                    .map((ar) => {
                                      const addition = ar.optionValue?.valueType === OptionValueType.POSITIVE ? "+ " : "";
                                      const removal = ar.optionValue?.valueType === OptionValueType.NEGATIVE ? "- " : "";
                                      return `${addition}${removal}${ar.optionValue?.value}`;
                                    })
                                    .join(", ")}
                              </div>
                            </>
                          );
                        })}
                      </div>
                      <div className="value">{getPrice(getOrderMenuPrice(billItem.price!, billItem.billItemCourseSkus!, billItem.menu!), tenant.settings?.fidelityAccount?.account.currency, undefined, billItem.quantity)}</div>
                    </div>
                  );
                }

                return (
                  <div key={billItem.id} className="item">
                    <div className="quantity">{billItem.quantity}</div>
                    <div className="title">
                      <div>{description}</div>
                      {billItem.itemOptionValues &&
                        billItem.itemOptionValues.length > 0 &&
                        billItem.itemOptionValues
                          .map((ar) => {
                            const addition = ar.optionValue?.valueType === OptionValueType.POSITIVE ? "+ " : "";
                            const removal = ar.optionValue?.valueType === OptionValueType.NEGATIVE ? "- " : "";
                            return `${addition}${removal}${ar.optionValue?.value}`;
                          })
                          .join(", ")}
                    </div>
                    <div className="value">{getPrice(getAdditionRemovalPrice(billItem.price!, billItem.itemOptionValues), tenant.settings?.fidelityAccount?.account.currency)}</div>
                  </div>
                );
              })}
            </IonCard>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Order;
