import { useEffect } from 'react';
import axios from 'axios';
import { useAuth } from './auth';
import { getMobileCommerceSalesPoints } from '../utils';
import { FidelityAccount, FidelitySalesPoint } from '../apollo-client/types';
import { makeVar, useReactiveVar } from '@apollo/client';

export type TenantConfigurations = {
  code: string,
  apiKey: string,
  termsAndConditionsUrl?: string,
  privacyPolicyUrl?: string,
  payoff?: string,
  homeEnabled?: boolean
  digitalPaymentOnly?: boolean
  fidelityAccount?: FidelityAccount
  mobileCommerceSalesPoints?: FidelitySalesPoint[]
};

export type Tenant = {
  loading: boolean,
  settings?: TenantConfigurations
  baseURL?: string
}

export const tenantVar = makeVar<Tenant>({ loading: true });

export const initTenant = (): Tenant => {

  const [auth] = useAuth();
  const tenant = useReactiveVar(tenantVar);

  useEffect(() => {

    async function firstLoad() {
      if (!window.location.hostname) {
        tenantVar({ loading: false });
        return;
      }

      const hostname = window.location.hostname;
      const domain = hostname.replace(`.${process.env.REACT_APP_DOMAIN || ''}`, '');

      const { data: settings, status: settingsStatus } = await axios.get<TenantConfigurations>(`${process.env.REACT_APP_TENANTS_CONFIGS_BASEURL}/${domain}/settings.json`);
      if (settingsStatus !== 200) {
        tenantVar({ loading: false });
        return;
      }

      const { data: fidelityAccount, status: fidelityAccountStatus } = await axios.get<FidelityAccount>(
        `${process.env.REACT_APP_API_BASEURL}/fidelity-account-configuration`,
        {
          headers: {
            'api-key': settings.apiKey,
            'api-version': process.env.REACT_APP_API_VERSION || '0.0.1',
            'access-token': auth.token
          }
        }
      );
      if (fidelityAccountStatus !== 200) {
        tenantVar({ loading: false });
        return;
      }

      const mobileCommerceSalesPoints = getMobileCommerceSalesPoints(fidelityAccount);

      // manifest 
      const myDynamicManifest = {
        "name": fidelityAccount.name,
        "short_name": fidelityAccount.name,
        "description": fidelityAccount.payoff || tenant.settings?.payoff,
        "start_url": "/",
        "scope": "/",
        "icons": [{
          "src": "logo.png",
          "sizes": "512x512",
          "type": "image/png"
        }]
      }
      const stringManifest = JSON.stringify(myDynamicManifest);
      const blob = new Blob([stringManifest], { type: 'application/json' });
      const manifestURL = URL.createObjectURL(blob);
      document.querySelector('#tenant-manifest')?.setAttribute('href', manifestURL);
      document.title = fidelityAccount.name;

      tenantVar({
        loading: false,
        settings: { ...settings, fidelityAccount, mobileCommerceSalesPoints },
        baseURL: `${process.env.REACT_APP_TENANTS_CONFIGS_BASEURL}/${domain}`
      })
    }

    firstLoad()

  }, []);

  return tenant;

}


export const useTenant = (): Tenant => {
  return useReactiveVar(tenantVar);
}

