import { makeVar, useReactiveVar } from "@apollo/client";
import _ from "lodash";
import { CartCourses, Course, Item } from "../apollo-client/types";
import { getCourseQuantity } from "../utils";
import { CartItem } from "./cart";

export type MenuItem = {
  item?: Item;
  quantity?: number;
  price?: number;
  courses: CartCourses[];
};

export const menuVar = makeVar<MenuItem>({ item: undefined, quantity: undefined, price: undefined, courses: [] });

export const useMenu = (): [
  MenuItem,
  (_newMenu: Item, _course: Course[]) => void,
  (_openMenu: MenuItem) => void,
  (_addItem: CartItem, _courseId: string) => void,
  (_updateItem: CartItem, _courseId: string, _index: number) => void,
  (_removeItem: CartItem, _courseId: string) => void,
  (_addQuantityItem: number, _courseId: string, _multiplier: number) => void,
  (_removeQuantityItem: number, _courseId: string, _multiplier: number) => void,
  () => void
] => {
  const menuItem = useReactiveVar(menuVar);

  const newMenu = (item: Item, courses: Course[]) => {
    if (item.id !== menuItem.item?.id) {
      const cartCourse = courses.map((c) => {
        return {
          id: c.id,
          orderTicketCourseNumber: c.orderTicketCourseNumber,
          position: c.position,
          description: c.description,
          min: c.min,
          max: c.max,
          courseChoices: [],
        };
      });
      menuVar({ item: item, courses: cartCourse, quantity: undefined, price: item.prices![0].price });
    }
  };

  const openMenu = (mI: MenuItem) => {
    const cartCourse = mI.courses.map((c) => {
      return {
        id: c.id,
        orderTicketCourseNumber: c.orderTicketCourseNumber,
        position: c.position,
        description: c.description,
        min: c.min,
        max: c.max,
        courseChoices: c.courseChoices,
      };
    });
    menuVar({ item: mI.item, courses: cartCourse, quantity: mI.quantity, price: mI.price });
  };

  const addToMenuAtCourse = (item: CartItem, courseId: string) => {
    const courses = [...menuItem.courses];
    const selectCourse = courses.filter((course) => course.id === courseId)[0];
    if (selectCourse.max! - getCourseQuantity(selectCourse) >= item.quantity) {
      const courseChoices = [...selectCourse.courseChoices];
      courseChoices.push(item);
      selectCourse.courseChoices = courseChoices;
      menuVar({ item: menuItem.item, courses: courses, quantity: menuItem.quantity, price: menuItem.price });
    }
  };

  const updateItemAtCourse = (item: CartItem, courseId: string, index: number) => {
    const courses = [...menuItem.courses];
    const selectCourse = courses.filter((course) => course.id === courseId)[0];
    const courseChoices = [...selectCourse.courseChoices];
    courseChoices[index] = item;
    _.remove(courses, (c) => c === selectCourse);
    selectCourse.courseChoices = courseChoices;
    courses.push(selectCourse);
    menuVar({ item: menuItem.item, courses: courses, quantity: menuItem.quantity, price: menuItem.price });
  };

  const removeToMenuAtCourse = (item: CartItem, courseId: string) => {
    const courses = [...menuItem.courses];
    const selectCourse = courses.filter((course) => course.id === courseId)[0];
    if (selectCourse.courseChoices.length > selectCourse.min!) {
      const courseChoices = [...selectCourse.courseChoices];
      _.remove(courseChoices, (cc) => cc === item);
      _.remove(courses, (c) => c === selectCourse);
      selectCourse.courseChoices = courseChoices;
      courses.push(selectCourse);
      menuVar({ item: menuItem.item, courses: courses, quantity: menuItem.quantity, price: menuItem.price });
    }
  };

  const addQuantityItem = (index: number, courseId: string) => {
    const courses = [...menuItem.courses];
    const selectCourse = courses.filter((course) => course.id === courseId)[0];
    if (selectCourse?.max && getCourseQuantity(selectCourse) < selectCourse.max!) {
      const selectItem = selectCourse.courseChoices[index];
      const courseChoices = [...selectCourse.courseChoices];
      selectItem.quantity = selectItem.quantity + 1;
      selectCourse.courseChoices = courseChoices;
      menuVar({ item: menuItem.item, courses: courses, quantity: menuItem.quantity, price: menuItem.price });
    }
  };

  const removeQuantityItem = (index: number, courseId: string) => {
    const courses = [...menuItem.courses];
    const selectCourse = courses.filter((course) => course.id === courseId)[0];
    const selectItem = selectCourse.courseChoices[index];
    const courseChoices = [...selectCourse.courseChoices];
    if (getCourseQuantity(selectCourse) >= 1) {
      let quantity = selectItem.quantity;
      quantity = quantity - 1;
      if (quantity > 0) {
        selectItem.quantity = quantity;
      } else {
        _.remove(courseChoices, (i) => i === selectItem);
      }
      selectCourse.courseChoices = courseChoices;
      menuVar({ item: menuItem.item, courses: courses, quantity: menuItem.quantity, price: menuItem.price });
    }
  };

  const clean = () => {
    menuVar({ ...menuItem, item: undefined, quantity: undefined, price: undefined, courses: [] });
  };

  return [menuItem, newMenu, openMenu, addToMenuAtCourse, updateItemAtCourse, removeToMenuAtCourse, addQuantityItem, removeQuantityItem, clean];
};
