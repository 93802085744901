import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { qrCode } from "ionicons/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTenant } from "../hooks/multiTenancy";
import { getPrice } from "../utils";
import QRCode from "react-qr-code";
import { close as ci } from "ionicons/icons";
import "./showPayments.css";

type ModalProps = {
  payments: { description?: string; amount: number; id: string }[];
  amountNotPayed: number;
  close: () => void;
};

const ShowPaymentsModal: React.FC<ModalProps> = ({ payments, amountNotPayed, close }) => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const [orderQr, setOrderQr] = useState<string | undefined>(undefined);
  const [descQr, setDescQr] = useState<string | undefined>(undefined);
  const [amountQr, setAmountQr] = useState<number | undefined>(undefined);

  const openQr = (idOrder: string, description: string, amount: number) => {
    setDescQr(description);
    setAmountQr(amount);
    setOrderQr(`DA${idOrder}`);
  };

  return (
    <>
      {orderQr && (
        <IonPage className="showPaymentsContainer">
          <IonHeader>
            <IonToolbar color="secondary">
              <IonTitle>{t("table_payment.paymentsConfirm")}</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setOrderQr(undefined)}>
                  <IonIcon icon={ci} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="pageContainer">
            <div className="qrHeader">
              <div className="line">
                <IonLabel>
                  {t("table_payment.paymentFromName")} {descQr}
                </IonLabel>
              </div>
              <div className="line">
                <IonLabel>
                  {t("table_payment.paymentFromAmount")} <b>{getPrice(amountQr!, tenant.settings?.fidelityAccount?.account.currency)}</b>
                </IonLabel>
              </div>
            </div>
            <div className="qrContainer">
              <QRCode size={300} style={{ height: "auto", maxWidth: "100%", width: "100%" }} value={orderQr} viewBox={`0 0 300 300`} />
            </div>
            <div className="qrFooter">
              <IonLabel>{t("table_payment.orderQrMsg")}</IonLabel>
            </div>
          </IonContent>
        </IonPage>
      )}
      {!orderQr && (
        <IonPage className="showPaymentsContainer">
          <IonHeader>
            <IonToolbar color="secondary">
              <IonTitle>{t("table_payment.paymentsConfirm")}</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => close()}>
                  <IonIcon icon={ci} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonList lines="none" className="list">
              {payments.map((p) => {
                if (p.description && p.description !== "") {
                  return (
                    <div className="paymentsContent">
                      <div className="title">{p.description}</div>
                      <div className="value">
                        <b>{getPrice(p.amount!, tenant.settings?.fidelityAccount?.account.currency)}</b>
                      </div>
                      <div className="action">
                        <IonButton onClick={() => openQr(p.id!, p.description!, p.amount)}>
                          <IonIcon icon={qrCode} />
                        </IonButton>
                      </div>
                    </div>
                  );
                }
                return (
                  <IonItem className="paymentsContent">
                    <div className="title">{t("table_payment.payOnCashRegistry")}</div>
                    <div className="value">
                      <b>{getPrice(p.amount!, tenant.settings?.fidelityAccount?.account.currency)}</b>
                    </div>
                  </IonItem>
                );
              })}
              <IonItem className="paymentsContent totalFooter">
                <div className="titleTot">{t("table_payment.paymentAmount")}</div>
                <div className="valueTot">
                  <b>{getPrice(amountNotPayed, tenant.settings?.fidelityAccount?.account.currency)} </b>
                </div>
              </IonItem>
            </IonList>
          </IonContent>
        </IonPage>
      )}
    </>
  );
};

export default ShowPaymentsModal;
