import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonLabel, IonLoading, IonNote, IonPage, IonRow, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import "./auth.css";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { passwordResetMutation } from "../../apollo-client/queries";
import { useHistory } from "react-router";
import { useTenant } from "../../hooks/multiTenancy";

type PasswordResetForm = {
  email: string;
};

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

const PasswordReset: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [toast] = useIonToast();
  const tenant = useTenant();
  const [passwordReset, { loading: passwordResetLoading }] = useMutation(passwordResetMutation);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PasswordResetForm>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
  });

  const onSubmit = async (data: PasswordResetForm) => {
    try {
      await passwordReset({ variables: data });
      history.push("/");
    } catch (e) {
      toast("ERROR", 5000);
    }
  };

  return (
    <IonPage className="auth">
      <IonLoading isOpen={passwordResetLoading} message={t("loading")} />
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>{t("auth.password_reset")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="background-image" style={{ backgroundImage: `url('${tenant.baseURL}/background.png')` }} />
        <div className="background-shadow" />
        <div className="content top-bar">
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <IonGrid>
              <IonRow>
                <IonCol size="12">
                  <IonItem className={errors.email ? "error" : ""}>
                    <IonLabel position="floating">{t("auth.email")}</IonLabel>
                    <Controller name="email" control={control} render={({ field }) => <IonInput onIonChange={field.onChange} onIonBlur={field.onBlur} />} />
                  </IonItem>
                  {errors.email && <IonNote color="danger">{errors.email?.message}</IonNote>}
                </IonCol>
              </IonRow>
              <IonRow className="form-buttons">
                <IonCol size="12">
                  <IonButton type="submit" color="primary">
                    {t("auth.password_reset")}
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PasswordReset;
