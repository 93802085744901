import { FidelitySalesPoint } from "../apollo-client/types";
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle } from "@ionic/react";

import { useTenant } from "../hooks/multiTenancy";

type ModalProps = {
  onClick: (salesPoint: FidelitySalesPoint) => void;
};

const SelectedSalesPointModal: React.FC<ModalProps> = ({ onClick }) => {
  const tenant = useTenant();
  return (
    <div>
      {tenant.settings?.mobileCommerceSalesPoints?.map((salesPoint) => (
        <IonCard key={salesPoint.id} className="store-card" onClick={() => onClick(salesPoint)}>
          <IonCardHeader>
            <IonCardTitle>{salesPoint.name}</IonCardTitle>
            <IonCardSubtitle>{salesPoint.city}</IonCardSubtitle>
          </IonCardHeader>
        </IonCard>
      ))}
    </div>
  );
};

export default SelectedSalesPointModal;
