/* eslint-disable @typescript-eslint/no-explicit-any */
import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonNote, IonPage, IonPopover, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar, useIonAlert, useIonToast } from "@ionic/react";
import "./newOrder.css";
import { useTranslation } from "react-i18next";
import { useSelectedSalesPoint } from "../../hooks/selectedSalesPoint";
import calcCoordsDistance, { buildOrder, Coordinates, getCartTotal, getCustomerVariation, getDeliveryCost, getPrepaidAccountBalance, getPrice, getSalesPoint, isWorkingPeriodSlotAvailable } from "../../utils";
import { useTenant } from "../../hooks/multiTenancy";
import { useCart } from "../../hooks/cart";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, ControllerRenderProps, FieldValues, useForm } from "react-hook-form";
import { DeliveryMode, ExternalOrdersPaymentType, FidelityCustomer, SourceType, Variation, WorkingPeriodSlot } from "../../apollo-client/types";
import { helpCircle, storefront } from "ionicons/icons";
import AddressInput, { Address } from "../../components/addressInput";
import QRInput from "../../components/qrInput";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { createOrderMutation, createOrderMutationWithSourceType, ordersQuery, updateUserMutation, userQuery, workingPeriodSlotsQuery } from "../../apollo-client/queries";
import { add, format, fromUnixTime, getUnixTime, isSameDay } from "date-fns";
import { it, enUS } from "date-fns/locale";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import _ from "lodash";
import { useHistory } from "react-router";
//import useGeolocation from "react-hook-geolocation";
import { useJsApiLoader } from "@react-google-maps/api";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import { idAppCustomerDeviceVar, idFidelityIdentifierVar } from "../auth/login";

export type NewOrder = {
  deliveryMode?: DeliveryMode;
  address?: Address;
  otherDeliveryDestination?: string;
  idTable?: string;
  day?: number;
  hourSlot?: WorkingPeriodSlot;
  paymentType?: SourceType | ExternalOrdersPaymentType;
  phoneNumber?: string;
  name?: string;
  lotteryCode?: string;
  note?: string;
};

const libraries: Libraries = ["drawing", "geometry", "places", "visualization"];

const now = new Date();

const NewOrder: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [toast] = useIonToast();
  const tenant = useTenant();
  const history = useHistory();
  const [selectedSalesPointId] = useSelectedSalesPoint();
  const [cart, , , , cleanCart, setCartErrors] = useCart();
  const [showAlert] = useIonAlert();
  const [requireFields, setRequireFields] = useState<boolean>(true);
  const popover = useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [variation, setVariation] = useState<Variation | undefined>(undefined);

  //const geolocation = useGeolocation({ timeout: 3000 });

  useJsApiLoader({
    language: "it",
    id: "address-google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_APIKEY || "",
    libraries: libraries,
  });

  const currency = tenant.settings?.fidelityAccount?.account.currency;
  const selectedSalesPoint = getSalesPoint(tenant, selectedSalesPointId);
  const externalOrdersSettings = selectedSalesPoint?.salesPoint?.externalOrdersSettings;
  const SalesPointLat = selectedSalesPoint!.latitude;
  const SalesPointLng = selectedSalesPoint!.longitude;
  const maxDistance = selectedSalesPoint?.salesPoint?.externalOrdersSettings?.delivery?.shippingMaxDistance;

  const userResponse = useQuery(userQuery);
  const user: FidelityCustomer = userResponse.data?.user;
  const idAppCustomerDevice = useReactiveVar(idAppCustomerDeviceVar);
  const idFidelityIdentifier = useReactiveVar(idFidelityIdentifierVar);

  const workingPeriodSlotsResponse = useQuery(workingPeriodSlotsQuery, {
    fetchPolicy: "network-only",
    skip: !selectedSalesPoint,
    variables: {
      idSalesPoint: selectedSalesPoint!.salesPoint!.id,
      filter: encodeURIComponent(
        JSON.stringify({
          datetimeFrom: getUnixTime(now),
          datetimeTo: getUnixTime(add(now, { months: 1 })),
        })
      ),
    },
  });
  const workingPeriodSlots: WorkingPeriodSlot[] = workingPeriodSlotsResponse.data?.workingPeriodSlots.slots;

  const selectedTableObj = localStorage.getItem("selected-table");
  const selectedTable = selectedTableObj ? JSON.parse(selectedTableObj) : undefined;
  let st = false;

  if (selectedTable) {
    const diff = Date.now() - selectedTable.time;
    st = diff < 1200000;
  }

  const [createOrder, createOrderResponse] = useMutation(createOrderMutation);
  const [createOrderWithST, createOrderWithSTResponse] = useMutation(createOrderMutationWithSourceType);
  const [updateUser] = useMutation(updateUserMutation);

  const openPopover = () => {
    setPopoverOpen(true);
  };

  useEffect(() => {
    if (userResponse.data?.user.customer) {
      setVariation(getCustomerVariation(userResponse.data?.user.customer, undefined));
    }
  }, [userResponse.data]);

  const schema = yup.object().shape({
    deliveryMode: yup.string().required(t("required")),
    day: externalOrdersSettings?.orderTimeRequired && !externalOrdersSettings.delivery?.tableEnabled ? yup.number().required(t("required")) : yup.number().optional(),
    hourSlot: externalOrdersSettings?.orderTimeRequired && !externalOrdersSettings.delivery?.tableEnabled ? yup.mixed().required(t("required")) : yup.mixed().optional(),
    paymentType: yup.string().required(t("required")),
    phoneNumber: requireFields && !st ? yup.string().required(t("required")) : yup.string().nullable(),
    name: requireFields && !st ? yup.string().required(t("required")) : yup.string().nullable(),
  });

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useForm<NewOrder>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
  });

  const watchDeliveryMode = watch("deliveryMode");
  const watchAddress = watch("address");
  const watchDay = watch("day");

  //let popoverBody = <div />;
  if (externalOrdersSettings?.delivery?.freeShippingThreshold && currency) {
    //popoverBody = <div className="delivery-help">{t("new_order.delivery_help", { threshold: getPrice(externalOrdersSettings?.delivery?.freeShippingThreshold, currency) })}</div>;
  }
  //const [presentPopover, dismissPopover] = useIonPopover(popoverBody, { onHide: () => dismissPopover() });

  useEffect(() => {
    if (watchDeliveryMode === DeliveryMode.TABLE) {
      setRequireFields(false);
    } else {
      setRequireFields(true);
    }
    if (watchDeliveryMode === DeliveryMode.DELIVERY) {
      if (!watchAddress && user?.customer?.street) {
        const initValue = async () => {
          const address = user?.customer;
          const results = await geocodeByAddress(`${address.street}, ${address.zipcode} ${address.city} ${address.district} ${address.country}`);
          if (results.length > 0) {
            const position = await getLatLng(results[0]);
            if (maxDistance && maxDistance > 0) {
              const SalesPointCoordinates: Coordinates = { latitude: SalesPointLat!, longitude: SalesPointLng! };
              const DeliveryCoordinates: Coordinates = { latitude: position.lat, longitude: position.lng };
              const r = calcCoordsDistance(SalesPointCoordinates, DeliveryCoordinates);
              if (r > maxDistance) {
                toast({
                  cssClass: "cart-added-toast",
                  color: "light",
                  message: t("order.deliveryDistanceFail"),
                  duration: 800,
                  animated: true,
                  position: "middle",
                });
                return;
              }
              setValue("address", {
                street: user?.customer?.street,
                city: user?.customer?.city,
                district: user?.customer?.district,
                zipcode: user?.customer?.zipcode,
                country: user?.customer?.country,
              });
              return;
            }
          }
          return;
        };

        initValue();
      }
    }
  }, [watchDeliveryMode, watchAddress, user]);

  const onAddressChange = (address: Address, field: ControllerRenderProps<FieldValues, "address">) => {
    showAlert({
      header: t("new_order.address_alert.title"),
      message: t("new_order.address_alert.message"),
      buttons: [
        t("new_order.address_alert.cancel"),
        {
          text: t("new_order.address_alert.confirm"),
          handler: () => {
            const customer = {
              customer: {
                name: user?.customer?.name,
                phoneNumber: user?.customer?.phoneNumber,
                lotteryCode: user?.customer?.lotteryCode,
                ...address,
              },
            };
            updateUser({ variables: { customer } });
          },
        },
      ],
    });
    field.onChange(address);
  };

  const onSubmit = async (data: NewOrder) => {
    console.log(cart);
    console.log(buildOrder(cart, data, selectedSalesPoint!));
    try {
      if (selectedSalesPoint) {
        let createdOrderResponse;
        if (data.paymentType === SourceType.CREDIT_CARD || data.paymentType === SourceType.PAYPAL) {
          const st = data.paymentType;
          createdOrderResponse = await createOrderWithST({
            variables: {
              idSalesPoint: selectedSalesPoint?.salesPoint?.id,
              order: buildOrder(cart, data, selectedSalesPoint, variation),
              idAppCustomerDevice: idAppCustomerDevice,
              idFidelityIdentifier: idFidelityIdentifier,
              sourceTypes: st,
            },
            refetchQueries: [ordersQuery, "GetOrders"],
          });
        } else {
          createdOrderResponse = await createOrder({
            variables: {
              idSalesPoint: selectedSalesPoint?.salesPoint?.id,
              order: buildOrder(cart, data, selectedSalesPoint, variation),
              idAppCustomerDevice: idAppCustomerDevice,
              idFidelityIdentifier: idFidelityIdentifier,
            },
            refetchQueries: [ordersQuery, "GetOrders"],
          });
        }

        localStorage.removeItem("selected-table");
        cleanCart();
        if (data.paymentType === ExternalOrdersPaymentType.STRIPE) {
          history.replace(`/app/orders/stripepay/${createdOrderResponse.data.createOrder.id}`);
        } else if (data.paymentType === ExternalOrdersPaymentType.SATISPAY) {
          history.replace(`/app/orders/satispay/${createdOrderResponse.data.createOrder.id}`);
        } else if (data.paymentType === SourceType.CREDIT_CARD) {
          history.replace(`/app/orders/tspay/${createdOrderResponse.data.createOrderWithSourceType.id}/${SourceType.CREDIT_CARD}`);
        } else if (data.paymentType === SourceType.PAYPAL) {
          history.replace(`/app/orders/tspay/${createdOrderResponse.data.createOrderWithSourceType.id}/${SourceType.PAYPAL}`);
        } else {
          history.replace("/app/orders");
        }
      }
    } catch (e) {
      const error = e as any;
      if (error.networkError?.statusCode === 400 && error.networkError?.result?.errors && error.networkError.result.errors.length > 0) {
        const message = error.networkError.result.errors
          .map((e: any) => {
            if (e.idSku) {
              const product = cart.items.find((i) => i.sku.id === e.idSku)?.sku.descriptionReceipt;
              return t(`new_order.errors.${e.code}`, { product });
            } else {
              return t(`new_order.errors.${e.code}`);
            }
          })
          .join("\n\n");

        setCartErrors(error.networkError.result.errors);

        showAlert({
          header: t("new_order.error_alert.title"),
          message,
          buttons: [
            t("new_order.error_alert.cancel"),
            {
              text: t("new_order.error_alert.goto_cart"),
              handler: () => {
                history.push("/app/cart");
              },
            },
          ],
        });
      } else {
        toast((e as any).message, 5000);
      }
    }
  };

  let deliveryCost = null;
  if (watchDeliveryMode === DeliveryMode.DELIVERY && selectedSalesPoint && watchAddress) {
    deliveryCost = getDeliveryCost(cart, watchAddress, selectedSalesPoint);
  }

  const availableDays: Date[] = [];
  if (workingPeriodSlots && workingPeriodSlots.length > 0) {
    workingPeriodSlots.forEach((slot) => {
      const d = fromUnixTime(slot.start!);
      if (!availableDays.find((ad) => isSameDay(ad, d))) {
        availableDays.push(d);
      }
    });
  }

  const availableSlots: WorkingPeriodSlot[] = [];
  if (workingPeriodSlots && workingPeriodSlots.length > 0 && watchDay) {
    workingPeriodSlots.forEach((slot) => {
      const d = fromUnixTime(slot.start!);
      if (isSameDay(fromUnixTime(watchDay), d)) {
        availableSlots.push(slot);
      }
    });
  }

  const cartPieces = _.sum(cart.items.map((i) => i.quantity));

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <IonPage className="new-order">
        <IonHeader>
          <IonToolbar color="secondary">
            <IonButtons slot="start">
              <IonBackButton />
            </IonButtons>
            <IonTitle>{t("new_order.title")}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonLoading isOpen={userResponse.loading || workingPeriodSlotsResponse.loading || createOrderResponse.loading || createOrderWithSTResponse.loading} message={t("loading")} />
        <IonContent fullscreen>
          {selectedSalesPoint && user && (
            <>
              {tenant.settings?.mobileCommerceSalesPoints && tenant.settings?.mobileCommerceSalesPoints?.length > 0 && (
                <div className="store-recap">
                  <IonIcon icon={storefront} />
                  <div className="text">{t("cart.store_recap", selectedSalesPoint)}</div>
                </div>
              )}
              <div className="form-content">
                <IonRow>
                  <IonCol>
                    <IonItem lines="none" className={errors.deliveryMode ? "error" : ""}>
                      <IonLabel position="stacked">{t("new_order.delivery_mode")}</IonLabel>
                      <Controller
                        name="deliveryMode"
                        control={control}
                        defaultValue={st && selectedSalesPoint?.salesPoint?.externalOrdersSettings?.delivery?.tableEnabled ? DeliveryMode.TABLE : undefined}
                        render={({ field }) => (
                          <IonSelect onIonChange={field.onChange} value={field.value} interface="alert">
                            {selectedSalesPoint?.salesPoint?.externalOrdersSettings?.delivery?.pickUpEnabled && <IonSelectOption value={DeliveryMode.PICKUP}>{t("new_order.pickup")}</IonSelectOption>}
                            {selectedSalesPoint?.salesPoint?.externalOrdersSettings?.delivery?.shippingEnabled && <IonSelectOption value={DeliveryMode.DELIVERY}>{t("new_order.delivery")}</IonSelectOption>}
                            {selectedSalesPoint?.salesPoint?.externalOrdersSettings?.delivery?.otherDeliveryEnabled && <IonSelectOption value={DeliveryMode.OTHER}>{selectedSalesPoint?.salesPoint?.externalOrdersSettings?.mobileCommerce?.otherDeliveryName}</IonSelectOption>}
                            {selectedSalesPoint?.salesPoint?.externalOrdersSettings?.delivery?.tableEnabled && <IonSelectOption value={DeliveryMode.TABLE}>{t("new_order.table_order")}</IonSelectOption>}
                          </IonSelect>
                        )}
                      />
                    </IonItem>
                    {errors.deliveryMode && <IonNote color="danger">{errors.deliveryMode?.message}</IonNote>}
                  </IonCol>
                </IonRow>
                {watchDeliveryMode === DeliveryMode.DELIVERY && (
                  <IonRow>
                    <IonCol>
                      <IonItem lines="none" disabled={watchDeliveryMode !== DeliveryMode.DELIVERY} className={errors.address ? "error" : ""}>
                        <IonLabel position="stacked">{t("profile.address")}</IonLabel>
                        <Controller name="address" control={control} render={({ field }) => <AddressInput fromProfile={false} onChange={(address) => onAddressChange(address, field)} value={field.value} />} />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                )}
                {watchDeliveryMode === DeliveryMode.OTHER && selectedSalesPoint?.salesPoint?.externalOrdersSettings?.delivery?.otherDeliveryEnabled && (
                  <IonRow>
                    <IonCol>
                      <IonItem lines="none" disabled={watchDeliveryMode !== DeliveryMode.OTHER} className={errors.otherDeliveryDestination ? "error" : ""}>
                        <IonLabel position="stacked">{selectedSalesPoint?.salesPoint?.externalOrdersSettings?.mobileCommerce?.otherDeliveryName}</IonLabel>
                        <Controller
                          name="otherDeliveryDestination"
                          control={control}
                          render={({ field }) => <QRInput table={false} title={selectedSalesPoint?.salesPoint?.externalOrdersSettings?.mobileCommerce?.otherDeliveryName} message={selectedSalesPoint?.salesPoint?.externalOrdersSettings?.mobileCommerce?.otherDeliveryInfo} onChange={field.onChange} />}
                        />
                      </IonItem>
                      {errors.otherDeliveryDestination && <IonNote color="danger">{errors.otherDeliveryDestination?.message}</IonNote>}
                    </IonCol>
                  </IonRow>
                )}
                {watchDeliveryMode === DeliveryMode.TABLE && selectedSalesPoint?.salesPoint?.externalOrdersSettings?.delivery?.tableEnabled && (
                  <IonRow>
                    <IonCol>
                      <IonItem lines="none" disabled={watchDeliveryMode !== DeliveryMode.TABLE} className={errors.idTable ? "error" : ""}>
                        <IonLabel position="stacked">{t("new_order.table")}</IonLabel>
                        <Controller
                          name="idTable"
                          control={control}
                          defaultValue={st && selectedSalesPoint?.salesPoint?.externalOrdersSettings?.delivery?.tableEnabled ? selectedTable.idTable : undefined}
                          render={({ field }) => (
                            <QRInput
                              table
                              selectedSalesPointId={selectedSalesPointId}
                              selectedTable={st && selectedSalesPoint?.salesPoint?.externalOrdersSettings?.delivery?.tableEnabled ? selectedTable.idTable : undefined}
                              title={t("new_order.table")}
                              message={t("new_order.table_info")}
                              onChange={field.onChange}
                            />
                          )}
                        />
                      </IonItem>
                      {errors.idTable && <IonNote color="danger">{errors.idTable?.message}</IonNote>}
                    </IonCol>
                  </IonRow>
                )}
                {externalOrdersSettings?.orderTimeRequired && !(watchDeliveryMode === DeliveryMode.TABLE) && (
                  <IonRow>
                    <IonCol size="6">
                      <IonItem lines="none" disabled={watchDeliveryMode == null || watchDeliveryMode == undefined} className={errors.day ? "error" : ""}>
                        <IonLabel position="stacked">{t("new_order.day")}</IonLabel>
                        <Controller
                          name="day"
                          control={control}
                          render={({ field }) => (
                            <IonSelect onIonChange={field.onChange} value={field.value} interface="alert">
                              {availableDays.map((d, index) => (
                                <IonSelectOption key={index} value={getUnixTime(d)}>
                                  {format(d, "EEE d MMM", { locale: i18n.language === "it" ? it : enUS })}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          )}
                        />
                      </IonItem>
                      {errors.day && <IonNote color="danger">{errors.day?.message}</IonNote>}
                    </IonCol>
                    <IonCol size="6">
                      <IonItem lines="none" disabled={!watchDay} className={errors.hourSlot ? "error" : ""}>
                        <IonLabel position="stacked">{t("new_order.hour")}</IonLabel>
                        <Controller
                          name="hourSlot"
                          control={control}
                          render={({ field }) => (
                            <IonSelect onIonChange={field.onChange} value={field.value} interface="alert">
                              {availableSlots.map((slot, index) => (
                                <IonSelectOption key={index} value={slot} disabled={!isWorkingPeriodSlotAvailable(slot, cartPieces, watchDeliveryMode)}>
                                  {slot.start && format(fromUnixTime(slot.start), "HH:mm")}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          )}
                        />
                      </IonItem>
                      {errors.hourSlot && <IonNote color="danger">{errors.hourSlot?.start?.message}</IonNote>}
                    </IonCol>
                  </IonRow>
                )}
                <IonRow>
                  <IonCol>
                    <IonItem lines="none" className={errors.paymentType ? "error" : ""}>
                      <IonLabel position="stacked">{t("new_order.payment_type")}</IonLabel>
                      <Controller
                        name="paymentType"
                        control={control}
                        render={({ field }) => (
                          <IonSelect onIonChange={field.onChange} value={field.value} interface="alert">
                            {(externalOrdersSettings?.acceptedPayments || [])
                              .filter(
                                (eop) =>
                                  eop.paymentType !== ExternalOrdersPaymentType.OTHER &&
                                  (eop.paymentType !== ExternalOrdersPaymentType.CASH_ON_DELIVERY || watchDeliveryMode !== DeliveryMode.TABLE) &&
                                  (eop.paymentType !== ExternalOrdersPaymentType.CASH_ON_TABLE || watchDeliveryMode == DeliveryMode.TABLE)
                              )
                              .map((eop) => {
                                let disabled = false;
                                if (eop.paymentType === ExternalOrdersPaymentType.CN_CREDIT) {
                                  const balance = getPrepaidAccountBalance(user, selectedSalesPoint?.id);
                                  disabled = !balance || balance < getCartTotal(cart);
                                }

                                let element = null;

                                if (eop.paymentType === ExternalOrdersPaymentType.TSPAY) {
                                  if (eop.sourceTypes) {
                                    const souceTypes = eop.sourceTypes as unknown as [SourceType];
                                    element = souceTypes.map((st) => {
                                      const content = <span>{t(`payment_types.${st}`)}</span>;
                                      return (
                                        <IonSelectOption disabled={disabled} key={st} value={st}>
                                          {content}
                                        </IonSelectOption>
                                      );
                                    });
                                  }
                                  return element;
                                }
                                const content = <span>{t(`payment_types.${eop.paymentType}`)}</span>;
                                return (
                                  <IonSelectOption disabled={disabled} key={eop.paymentType} value={eop.paymentType}>
                                    {content}
                                  </IonSelectOption>
                                );
                              })}
                          </IonSelect>
                        )}
                      />
                    </IonItem>
                    {errors.paymentType && <IonNote color="danger">{t("required")}</IonNote>}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonItem lines="none" className={errors.phoneNumber ? "error" : ""}>
                      <IonLabel position="stacked">{t("new_order.phone_number")}</IonLabel>
                      <Controller name="phoneNumber" control={control} defaultValue={user.customer.phoneNumber} render={({ field }) => <IonInput onIonChange={field.onChange} value={field.value} />} />
                    </IonItem>
                    {errors.phoneNumber && <IonNote color="danger">{t("required")}</IonNote>}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonItem lines="none" className={errors.name ? "error" : ""}>
                      <IonLabel position="stacked">{t("new_order.name")}</IonLabel>
                      <Controller name="name" control={control} defaultValue={user.customer.name} render={({ field }) => <IonInput onIonChange={field.onChange} value={field.value} />} />
                    </IonItem>
                    {errors.name && <IonNote color="danger">{t("required")}</IonNote>}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonItem lines="none" className={errors.lotteryCode ? "error" : ""}>
                      <IonLabel position="stacked">{t("new_order.lottery_code")}</IonLabel>
                      <Controller name="lotteryCode" control={control} defaultValue={user.customer.lotteryCode} render={({ field }) => <IonInput onIonChange={field.onChange} value={field.value} />} />
                    </IonItem>
                    {errors.lotteryCode && <IonNote color="danger">{t("required")}</IonNote>}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonItem lines="none" className={errors.note ? "error" : ""}>
                      <IonLabel position="stacked">{t("new_order.note")}</IonLabel>
                      <Controller name="note" control={control} render={({ field }) => <IonInput onIonChange={field.onChange} value={field.value} />} />
                    </IonItem>
                    {errors.note && <IonNote color="danger">{t("required")}</IonNote>}
                  </IonCol>
                </IonRow>
              </div>
            </>
          )}
        </IonContent>
        {selectedSalesPoint && user && (
          <IonFooter className="confirm-bar">
            <div className="content">
              <div className="recap subtotal">
                <div className="title">{t("new_order.subtotal")}:</div>
                {variation === undefined && <div className="value">{getPrice(getCartTotal(cart), currency)}</div>}
                {variation && variation.variation && <div className="value">{getPrice(getCartTotal(cart) - (getCartTotal(cart) * variation.variation) / 100, currency)}</div>}
              </div>
              {deliveryCost !== null && deliveryCost !== undefined && (
                <div className="recap delivery">
                  <div className="title">
                    <span>{t("new_order.delivery")}:</span>
                    {selectedSalesPoint?.salesPoint?.externalOrdersSettings?.delivery?.freeShippingThreshold && (
                      <>
                        {" "}
                        <IonIcon onClick={() => openPopover()} icon={helpCircle} />
                        <IonPopover ref={popover} isOpen={popoverOpen} onDidDismiss={() => setPopoverOpen(false)}>
                          <IonContent class="ion-padding">limite per la spedizione gratuita: {selectedSalesPoint?.salesPoint?.externalOrdersSettings?.delivery?.freeShippingThreshold}€</IonContent>
                        </IonPopover>
                      </>
                    )}
                  </div>
                  <div className="value">{getPrice(deliveryCost)}</div>
                </div>
              )}
              <div className="recap">
                <div className="title">{t("new_order.total")}:</div>
                {variation === undefined && <div className="value">{getPrice(getCartTotal(cart) + (deliveryCost || 0), currency)}</div>}
                {variation && variation.variation && <div className="value">{getPrice(getCartTotal(cart) - (getCartTotal(cart) * variation.variation) / 100 + (deliveryCost || 0), currency)}</div>}
              </div>
              <IonButton type="submit" expand="block">
                {t("new_order.confirm")}
              </IonButton>
            </div>
          </IonFooter>
        )}
      </IonPage>
    </form>
  );
};

export default NewOrder;
