import { IonContent, IonIcon, IonLoading, IonPage } from "@ionic/react";
import "./others.css";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { storefront, newspaper, personOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import { ApolloClient, NormalizedCacheObject, useApolloClient, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { logoutUserMutation, userQuery } from "../../apollo-client/queries";
import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import { useTenant } from "../../hooks/multiTenancy";
import { idAppCustomerDeviceVar } from "../auth/login";

const Others: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const tenant = useTenant();
  const apolloClient = useApolloClient() as ApolloClient<NormalizedCacheObject>;

  const [{ authenticated }, , logout] = useAuth();
  const userResponse = useQuery(userQuery, { skip: !authenticated });

  const [logoutUser] = useMutation(logoutUserMutation);
  const idAppCustomerDevice = useReactiveVar(idAppCustomerDeviceVar);

  const logoutAndRedirect = useCallback(() => {
    logoutUser({ variables: { idAppCustomerDevice } });
    logout(apolloClient);
    history.push("/");
  }, []);

  return (
    <IonPage className="others">
      <IonLoading isOpen={userResponse.loading} message={t("loading")} />
      <IonContent fullscreen>
        <div className="content">
          {authenticated && <div className="username">{!userResponse.loading && (userResponse.data?.user?.customer?.name || userResponse.data?.user?.username)}</div>}
          <div className="login-logout">
            {authenticated && <a onClick={logoutAndRedirect}>{t("others.logout")}</a>}
            {!authenticated && <Link to="/">{t("others.login")}</Link>}
          </div>
          <div className="menu">
            <Link className="menu-item" to="/app/others/stores">
              <IonIcon icon={storefront} />
              <span>{t("others.stores")}</span>
            </Link>
            {authenticated && (
              <>
                <Link className="menu-item" to="/app/others/profile">
                  <IonIcon icon={personOutline} />
                  <span>{t("others.profile")}</span>
                </Link>
                <Link className="menu-item" to="/app/others/myId">
                  <IonIcon icon={personOutline} />
                  <span>{t("others.myId")}</span>
                </Link>
              </>
            )}
            <a className="menu-item" href={tenant.settings?.fidelityAccount?.termsAndConditionsUrl || tenant.settings?.termsAndConditionsUrl} target="_blank">
              <IonIcon icon={newspaper} />
              <span>{t("others.terms")}</span>
            </a>
            <a className="menu-item" href={tenant.settings?.fidelityAccount?.privacyPolicyUrl || tenant.settings?.privacyPolicyUrl || "https://www.cassaincloud.it/privacy"} target="_blank">
              <IonIcon icon={newspaper} />
              <span>{t("others.privacy")}</span>
            </a>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Others;
