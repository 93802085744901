import React from "react";
import i18next from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enTranslation from "./locales/en/translation.json";
import itTranslation from "./locales/it/translation.json";

interface LocalizationProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
}

i18next
  .use(initReactI18next) // inizializza react-i18next
  .use(LanguageDetector) // rileva la lingua del browser
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      it: {
        translation: itTranslation,
      },
    },
    fallbackLng: "it", // lingua di fallback se la lingua rilevata non è supportata
    detection: {
      order: ["navigator"], // ordine di rilevamento della lingua
    },
    interpolation: {
      escapeValue: false, // consenti l'uso di HTML nei file di traduzione
    },
  });

const Localization = ({ children }: LocalizationProps) => {
  return <I18nextProvider i18n={i18next}>{children}</I18nextProvider>;
};

export default Localization;
