import { IonButton, IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import "./auth.css";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router";
import { useTenant } from "../../hooks/multiTenancy";
import { useAuth } from "../../hooks/auth";
import FacebookLogin, { ReactFacebookFailureResponse, ReactFacebookLoginInfo } from "react-facebook-login";
import { useMutation } from "@apollo/client";
import { loginWithFacebookMutation } from "../../apollo-client/queries";

const Auth: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const tenant = useTenant();
  const [auth, setToken] = useAuth();

  const [loginMutation, { loading: loginLoading }] = useMutation(loginWithFacebookMutation);

  const facebookResponse = async (userInfo: ReactFacebookLoginInfo | ReactFacebookFailureResponse) => {
    if ((userInfo as ReactFacebookLoginInfo).id) {
      const fbAcccessToken = (userInfo as ReactFacebookLoginInfo).accessToken;
      const res = await loginMutation({ variables: { facebookToken: fbAcccessToken } });
      setToken(res.data.login.accessToken.token);
      history.push("/app");
    }
  };

  if (auth.authenticated) {
    return <Redirect to="/app" />;
  }

  return (
    <IonPage className="auth">
      <IonContent fullscreen>
        <div className="background-image" style={{ backgroundImage: `url('${tenant.baseURL}/background.png')` }} />
        <div className="background-shadow" />
        <div className="content">
          <img className="logo" src={`${tenant.baseURL}/logo.png`} />
          <div className="tagline">{tenant.settings?.fidelityAccount?.payoff || tenant.settings?.payoff}</div>
          {!tenant.settings?.digitalPaymentOnly && (
            <div className="form auth-buttons">
              <IonGrid>
                <IonRow>
                  {false && tenant.settings?.fidelityAccount?.facebookId && (
                    <IonCol size="12">
                      <FacebookLogin appId={tenant.settings?.fidelityAccount?.facebookId || ""} reAuthenticate callback={facebookResponse} cssClass={`facebook-button ${loginLoading ? "loading" : ""}`} textButton={t("auth.facebook")} icon="fa-facebook" isMobile={true} disableMobileRedirect={true} />
                    </IonCol>
                  )}

                  <>
                    <IonCol size="6">
                      <IonButton color="primary" routerLink="/register">
                        {t("auth.register")}
                      </IonButton>
                    </IonCol>
                    <IonCol size="6">
                      <IonButton color="primary" routerLink="/login">
                        {t("auth.login")}
                      </IonButton>
                    </IonCol>
                    <IonCol size="12">
                      <IonButton className="trial" routerLink={"/app"}>
                        {t("auth.trial")}
                      </IonButton>
                    </IonCol>
                  </>
                </IonRow>
              </IonGrid>
            </div>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Auth;
