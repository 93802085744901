import { IonBackButton, IonButton, IonButtons, IonCard, IonCol, IonContent, IonFooter, IonHeader, IonIcon, IonLoading, IonPage, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import "./stores.css";
import { useTranslation } from "react-i18next";
import { call, mail, storefront, location as locationIcon } from "ionicons/icons";
import { useTenant } from "../../hooks/multiTenancy";
import { useSelectedSalesPoint } from "../../hooks/selectedSalesPoint";
import { useCallback, useEffect, useState } from "react";
import useGeolocation from "react-hook-geolocation";
import { getDistance } from "geolib";
import _ from "lodash";
import { FidelitySalesPoint } from "../../apollo-client/types";
import { getSalesPoint } from "../../utils";

const Stores: React.FC = () => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const geolocation = useGeolocation({ timeout: 2000 });

  const [selectedSalesPointId, changeSalesPoint] = useSelectedSalesPoint();
  const [tmpSelectedSalesPointId, setTmpSelectedSalesPointId] = useState<number | undefined>(selectedSalesPointId);

  const tmpSelectedSalesPoint = getSalesPoint(tenant, tmpSelectedSalesPointId);

  const distance = useCallback(
    (fidelitySalesPoint: FidelitySalesPoint): number | null => {
      if (geolocation.latitude && geolocation.longitude && fidelitySalesPoint.latitude && fidelitySalesPoint.longitude) {
        return getDistance(
          {
            latitude: geolocation.latitude,
            longitude: geolocation.longitude,
          },
          {
            latitude: fidelitySalesPoint.latitude,
            longitude: fidelitySalesPoint.longitude,
          }
        );
      }
      return null;
    },
    [geolocation]
  );

  const fidelitySalesPoints = [
    ...(tenant.settings?.mobileCommerceSalesPoints || []).filter((sp) => sp.salesPoint.id === selectedSalesPointId),
    ...(tenant.settings?.mobileCommerceSalesPoints || [])
      .filter((sp) => sp.salesPoint.id !== selectedSalesPointId)
      .sort((a, b) => {
        const da = distance(a);
        const db = distance(b);
        if (da === null) {
          return +1;
        }
        if (db === null) {
          return -1;
        }
        return da - db;
      }),
  ];

  useEffect(() => {
    setTmpSelectedSalesPointId(selectedSalesPointId || fidelitySalesPoints[0].salesPoint.id);
  }, [selectedSalesPointId]);

  const save = useCallback(() => {
    if (tmpSelectedSalesPointId) {
      changeSalesPoint(tmpSelectedSalesPointId);
    }
  }, [tmpSelectedSalesPointId]);

  return (
    <IonPage className="stores">
      <IonHeader>
        <IonToolbar color="secondary">
          {selectedSalesPointId && (
            <IonButtons slot="start">
              <IonBackButton defaultHref="/app/others" />
            </IonButtons>
          )}
          <IonTitle>{t("stores.title")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonLoading isOpen={geolocation.latitude === null && !geolocation.error} message={t("stores.loading_position")} />
      {(geolocation.latitude !== null || geolocation.error) && (
        <>
          <IonContent fullscreen>
            <div className="content">
              {fidelitySalesPoints.map((fidelitySalesPoint) => (
                <IonCard className={`store ${fidelitySalesPoint.salesPoint.id === tmpSelectedSalesPointId ? "selected" : ""}`} key={fidelitySalesPoint.id} onClick={() => setTmpSelectedSalesPointId(fidelitySalesPoint.salesPoint.id)}>
                  <div className="image" style={{ backgroundImage: `url('${fidelitySalesPoint.image}')` }} />
                  <div className="description">
                    <IonRow>
                      <IonCol>{fidelitySalesPoint.name}</IonCol>
                      <IonCol className="distance">{distance(fidelitySalesPoint) !== null && `${_.toInteger(distance(fidelitySalesPoint)! / 1000)}km`}</IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol className="address">
                        <IonIcon className="icon" icon={storefront} />
                        <span className="text">{`${fidelitySalesPoint.street}, ${fidelitySalesPoint.zipcode} ${fidelitySalesPoint.city} ${fidelitySalesPoint.district} ${fidelitySalesPoint.country}`}</span>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol className="buttons">
                        <IonButton onClick={() => window.open(`tel:${fidelitySalesPoint.phoneNumber}`)}>
                          <IonIcon icon={call} />
                        </IonButton>
                        <IonButton onClick={() => window.open(`mailto:${fidelitySalesPoint.email}`)}>
                          <IonIcon icon={mail} />
                        </IonButton>
                        <IonButton onClick={() => window.open(`http://maps.google.com/maps?daddr=${fidelitySalesPoint.latitude},${fidelitySalesPoint.longitude}`, "_blank")}>
                          <IonIcon icon={locationIcon} />
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </div>
                </IonCard>
              ))}
            </div>
          </IonContent>
          <IonFooter className="save-bar">
            <div className="content">
              <div className="selected-recap">{`${t("stores.selected")}: ${tmpSelectedSalesPoint?.name}`}</div>
              <IonButton disabled={!tmpSelectedSalesPointId || tmpSelectedSalesPointId === selectedSalesPointId} onClick={save} expand="block">
                {t("stores.save")}
              </IonButton>
            </div>
          </IonFooter>
        </>
      )}
    </IonPage>
  );
};

export default Stores;
