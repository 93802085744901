import { IonBadge, IonIcon, IonLabel, IonRouterOutlet, IonSpinner, IonTabBar, IonTabButton, IonTabs } from "@ionic/react";
import { home, list, cart as cartIcon, documentText, ellipsisHorizontal } from "ionicons/icons";
import { Redirect, Route } from "react-router";
import Home from "./home";
import { useTranslation } from "react-i18next";
import Cart from "./cart";
import Orders from "./orders";
import Others from "./others";
import Stores from "./stores";
import { useTenant } from "../../hooks/multiTenancy";
import Categories from "./categories";
import { useSelectedSalesPoint } from "../../hooks/selectedSalesPoint";
import Products from "./products";
import ProductDetails from "./product";
import Profile from "./profile";
import Order from "./order";
import { useCart } from "../../hooks/cart";
import PayOrder from "./payOrder";
import "./main.css";
import ProductsSearch from "./productsSearch";
import PayOrderTSPay from "./payOrderTSPay";
import Menu from "./menu/menu";
import Course from "./menu/course";
import PayOrderSatispay from "./payOrderSatispay";
import MyId from "./myId";

const Main: React.FC = () => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const [selectedSalesPoint] = useSelectedSalesPoint();

  const [cart] = useCart();
  const currentCartItemsCount = cart.items.map((i) => i.quantity).reduce((a, b) => a + b, 0);

  if (tenant.settings?.digitalPaymentOnly) {
    return <Redirect to={"/"} />;
  }

  if (!selectedSalesPoint) {
    if (tenant.settings?.mobileCommerceSalesPoints && tenant.settings.mobileCommerceSalesPoints.length > 1) {
      return <Stores />;
    } else {
      return <IonSpinner className={"app-loader"} />;
    }
  } else {
    return (
      <>
        <IonTabs className="main-tabs">
          <IonRouterOutlet>
            <Route exact strict path="/app/home">
              <Home />
            </Route>
            <Route exact strict path="/app/products">
              <Categories />
            </Route>
            <Route exact strict path="/app/products/:idCategory">
              <Products />
            </Route>
            <Route exact strict path="/app/products/search">
              <ProductsSearch />
            </Route>
            <Route exact strict path="/app/products/search-results">
              <Products />
            </Route>
            <Route exact strict path="/app/products/product-details/:idProduct">
              <ProductDetails />
            </Route>
            <Route exact strict path="/app/products/menu/product-details/:idProduct">
              <ProductDetails itemMenu={true} />
            </Route>
            <Route exact strict path="/app/products/composition/product-details/:idProduct">
              <ProductDetails itemComposition={true} />
            </Route>
            <Route exact strict path="/app/products/menu-details/:idProduct">
              <Menu />
            </Route>
            <Route exact strict path="/app/products/course/:idCourse">
              <Course />
            </Route>
            <Route exact strict path="/app/cart">
              <Cart />
            </Route>
            <Route exact strict path="/app/orders">
              <Orders />
            </Route>
            <Route exact strict path="/app/orders/:idOrder">
              <Order />
            </Route>
            <Route exact strict path="/app/orders/stripePay/:idOrder">
              <PayOrder />
            </Route>
            <Route exact strict path="/app/orders/satispay/:idOrder">
              <PayOrderSatispay />
            </Route>
            <Route exact strict path="/app/orders/tspay/:idOrder/:sourceType">
              <PayOrderTSPay />
            </Route>
            <Route exact strict path="/app/others">
              <Others />
            </Route>
            <Route exact strict path="/app/others/stores">
              <Stores />
            </Route>
            <Route exact strict path="/app/others/profile">
              <Profile />
            </Route>
            <Route exact strict path="/app/others/myId">
              <MyId />
            </Route>
            <Route exact strict path="/app/">
              <Redirect to={tenant.settings?.fidelityAccount?.homeEnabled || tenant.settings?.homeEnabled ? "/app/home" : "/app/products"} />
            </Route>
          </IonRouterOutlet>

          <IonTabBar color="secondary" slot="bottom">
            {(tenant.settings?.fidelityAccount?.homeEnabled || tenant.settings?.homeEnabled) && (
              <IonTabButton tab="home" href="/app/home">
                <IonIcon icon={home} />
                <IonLabel>{t("menu.home")}</IonLabel>
              </IonTabButton>
            )}

            <IonTabButton tab="products" href="/app/products">
              <IonIcon icon={list} />
              <IonLabel>{t("menu.products")}</IonLabel>
            </IonTabButton>

            <IonTabButton tab="cart" href="/app/cart">
              <IonIcon icon={cartIcon} />
              {currentCartItemsCount > 0 && <IonBadge className="cart-badge">{currentCartItemsCount}</IonBadge>}
              <IonLabel>{t("menu.cart")}</IonLabel>
            </IonTabButton>

            <IonTabButton tab="orders" href="/app/orders">
              <IonIcon icon={documentText} />
              <IonLabel>{t("menu.orders")}</IonLabel>
            </IonTabButton>

            <IonTabButton tab="others" href="/app/others">
              <IonIcon icon={ellipsisHorizontal} />
              <IonLabel>{t("menu.others")}</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </>
    );
  }
};

export default Main;
