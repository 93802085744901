import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonPage, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTenant } from "../hooks/multiTenancy";
import { getPrice } from "../utils";
import { close as ci } from "ionicons/icons";
import "./splitPayments.css";

type ModalProps = {
  totalAmount: number;
  onSelect: (paymentName: string, paymentAmount: number) => void;
  close: () => void;
};

const SplitPaymentsModal: React.FC<ModalProps> = ({ totalAmount, onSelect, close }) => {
  const { t } = useTranslation();
  const [paymentName, setPaymentName] = useState<string | undefined>(undefined);
  const [paymentAmount, setPaymentAmount] = useState<number | undefined>(undefined);
  const [load, setLoad] = useState<boolean>(false);
  const tenant = useTenant();
  const [toast] = useIonToast();

  const formatN = (val: string) => {
    if (Number(val)) {
      const v = parseFloat(val).toFixed(2);
      if (parseFloat(v) > totalAmount) {
        setPaymentAmount(totalAmount);
        return totalAmount;
      }
      setPaymentAmount(parseFloat(v));
      return parseFloat(v);
    }
  };

  const confirm = useCallback(async () => {
    if (paymentName && paymentAmount) {
      setLoad(true);
      onSelect(paymentName, paymentAmount);
    } else toast("Inserire un nome e l'importo desiderato", 5000);
  }, [paymentAmount, paymentName]);

  return (
    <IonPage className="splitContainer">
      <IonHeader>
        <IonToolbar color="secondary">
          <IonTitle>{t("table_payment.splitPay")}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => close()}>
              <IonIcon icon={ci} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonLoading isOpen={load} message={t("loading")} />
      <IonContent className="splitContent">
        <IonList lines="none" className="splitList">
          <IonItem>
            <IonLabel>Identifica chi sta effettuando il pagamento</IonLabel>
          </IonItem>
          <IonItem>
            <IonInput placeholder="Nome" value={paymentName} onIonChange={(e) => setPaymentName(e.detail.value!)}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel>Inserisci la quota che desideri pagare​</IonLabel>
          </IonItem>
          <IonItem>
            <div className="amountContainer">
              <IonInput class="custom" inputmode="decimal" type="number" value={paymentAmount} onIonChange={(e) => formatN(e.detail.value!)}></IonInput>
            </div>
          </IonItem>
          <IonItem>
            <div className="amountTotalBtn" onClick={() => setPaymentAmount(totalAmount)}>
              Importo mancante {getPrice(totalAmount, tenant.settings?.fidelityAccount?.account.currency)}
            </div>
          </IonItem>
          <IonButton disabled={paymentAmount === 0 || paymentName === undefined || paymentAmount === undefined || paymentName === ""} onClick={confirm} expand="block">
            {t("table_payment.confirm")}
          </IonButton>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default SplitPaymentsModal;
