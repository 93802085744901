import { useQuery } from "@apollo/client";
import { IonBackButton, IonButtons, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonLabel, IonLoading, IonPage, IonTitle, IonToolbar, useIonAlert } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { menuProductsQuery } from "../../../apollo-client/queries";
import { Item, OptionValueType } from "../../../apollo-client/types";
import { useMenu } from "../../../hooks/menu";
import { useTenant } from "../../../hooks/multiTenancy";
import { useSelectedSalesPoint } from "../../../hooks/selectedSalesPoint";
import { alert, checkmark, add, remove } from "ionicons/icons";
import { CartItem, useCart } from "../../../hooks/cart";
import _ from "lodash";
import { getAdditionRemovalPrice, getAvailableQuantity, getCourceChoiceFromItem, getCourseQuantity, getPrice } from "../../../utils";
import "./course.css";
import { useCallback, useEffect, useState } from "react";

const Course: React.FC = () => {
  const history = useHistory();
  const { idCourse } = useParams<{ idCourse: string }>();
  const tenant = useTenant();
  const currency = tenant.settings?.fidelityAccount?.account.currency;
  const [selectedSalesPointId] = useSelectedSalesPoint();
  const [showAlert] = useIonAlert();
  const [products, setProducts] = useState<Item[] | undefined>(undefined);
  const [cart, , ,] = useCart();
  const [menu, , , addToMenuAtCourse, , , addQuantityItem, removeQuantityItem] = useMenu();
  const { t } = useTranslation();

  const itemCourse = menu.item?.courses?.filter((c) => c.id === idCourse)[0];
  const cartCourse = menu.courses?.filter((c) => c.id === idCourse)[0];
  const isComposition = menu.item?.composition;
  const idCategories: [string?] = [];
  const idItems: [string?] = [];

  itemCourse?.courseChoices?.map((courseChoice) => {
    if (courseChoice.category) {
      idCategories.push(courseChoice.category!.id!);
    }
    if (courseChoice.item) {
      idItems.push(courseChoice.item!.id!);
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filter: any = {};
  filter.menu = false;
  filter.composition = false;
  if (idCategories.length > 0) {
    if (idItems!.length > 0) {
      filter.referencesOr = {
        idCategory: idCategories,
        id: idItems,
      };
    } else {
      filter.referencesOr = {
        idCategory: idCategories,
      };
    }
  } else {
    filter.referencesOr = {
      id: idItems,
    };
  }

  const productResponse = useQuery(menuProductsQuery, {
    variables: {
      idSalesPoint: selectedSalesPointId,
      filter: encodeURIComponent(JSON.stringify(filter)),
      start: 0,
      limit: 500,
    },
  });

  useEffect(() => {
    setProducts(productResponse.data?.products.records);
  }, [productResponse.data]);

  const openItem = (item: Item, index?: number) => {
    let par = "";
    let typeProduct = "menu";
    if (isComposition) typeProduct = "composition";
    if (itemCourse?.max && getCourseQuantity(cartCourse) === itemCourse?.max && index === undefined) {
      return;
    }
    if (index !== undefined) {
      par = `&menuCcItemIndex=${index}`;
    }
    if (itemCourse && itemCourse.courseChoices) {
      const cc = getCourceChoiceFromItem(item, itemCourse!.courseChoices!);
      if (cc) {
        if (isComposition) {
          if (cc!.prices) {
            par = par + `&compositionprice=${_.first(cc!.prices)?.price}`;
          }
        } else {
          par = par + `&multiplier=${cc?.multiplier}`;
        }
      }
    }
    history.push(`/app/products/${typeProduct}/product-details/${item.id}?idCourse=${idCourse}${par}`);
  };

  const notCompleteCurse = () => {
    showAlert({
      header: t("menuPage.menuNotCompleteTitle"),
      message: t("menuPage.menuNotCompleteMsg"),
      buttons: [t("product_details.quantity_alert.cancel")],
    });
  };

  const componentPrice = useCallback(
    (i: Item, quantity: number) => {
      const cc = getCourceChoiceFromItem(i, itemCourse!.courseChoices!);
      const price = cc?.prices ? _.first(cc!.prices)?.price : undefined;
      if (price !== undefined && price === 0) return "";
      const a = price ? getPrice(price, currency, false, quantity) : getPrice(i.prices![0], currency, false, quantity);
      return a;
    },
    [itemCourse]
  );

  const addItem = (item: Item) => {
    let multiplier = 1;
    if (item.multivariant) {
      openItem(item);
      return;
    }
    const defaultOptionValueBindings = _.unionBy(
      [...(item?.optionValueBindings || []), ...(item?.category?.optionValueBindings || [])].filter((ovb) => ovb.isDefault),
      "idOptionValue"
    );
    if (defaultOptionValueBindings.length > 0) {
      openItem(item);
      return;
    }
    const sku = _.first(item.sku)!;
    const availableQuantity = sku ? getAvailableQuantity(sku, cart) : 0;
    if (availableQuantity !== undefined && availableQuantity <= 0) {
      openItem(item);
      return;
    }
    let price = 0;
    let cPrice = 0;
    if (isComposition) {
      const cc = getCourceChoiceFromItem(item, itemCourse!.courseChoices!);
      if (cc?.prices) {
        cPrice = _.first(cc!.prices)!.price!;
        if (cPrice === 0) price = _.first(sku.prices)?.price || _.first(item.prices)?.price || 0;
      } else {
        cPrice = _.first(sku.prices)?.price || _.first(item.prices)?.price || 0;
      }
    }
    if (menu.item?.menu) {
      if (itemCourse && itemCourse.courseChoices) {
        const cc = getCourceChoiceFromItem(item, itemCourse!.courseChoices!);
        if (cc && cc.multiplier) {
          multiplier = cc.multiplier;
        }
      }
    }
    addToMenuAtCourse(
      {
        item: item,
        sku: sku,
        price: price,
        componentPrice: cPrice,
        additionRemovals: [],
        quantity: 1,
        multiplier,
      },
      idCourse
    );
  };

  const selected: CartItem[] | undefined = cartCourse?.courseChoices;
  let selectedElement: JSX.Element[];
  selectedElement = [];

  if (selected && selected.length == 0) {
    selectedElement = [
      <div className="emptyMsgContainer">
        <IonLabel className="emptyMsg">{t("menuPage.emptySelected")}</IonLabel>
      </div>,
    ];
  } else if (selected) {
    selectedElement = selected.map((item, index) => {
      return (
        <div className="ccSelectElementContainer">
          <div className="ccTitle">
            <div className="section-horizontal">
              <div className="preAction" key={index} onClick={() => removeQuantityItem(index, idCourse, item.multiplier!)}>
                <IonIcon className="icon" icon={remove} />
              </div>
              <div className="title" key={index} onClick={() => openItem(item.item, index)}>
                <IonLabel>{item.item.description}</IonLabel>
                <div className="ar">
                  {item.additionRemovals &&
                    item.additionRemovals.length > 0 &&
                    item.additionRemovals
                      .map((ar) => {
                        const addition = ar.optionValue?.valueType === OptionValueType.POSITIVE ? "+ " : "";
                        const removal = ar.optionValue?.valueType === OptionValueType.NEGATIVE ? "- " : "";
                        return `${addition}${removal}${ar.optionValue?.value}`;
                      })
                      .join(", ")}
                </div>
              </div>
              <div className="quantity">
                <div>
                  {item.multiplier && <IonLabel>Qt. {item.quantity * item.multiplier!}</IonLabel>}
                  {!item.multiplier && <IonLabel>Qt. {item.quantity}</IonLabel>}
                </div>
                {isComposition && item.componentPrice !== undefined && (
                  <div>
                    <IonLabel>{getPrice(getAdditionRemovalPrice(item.componentPrice, item.additionRemovals), currency, false, item.quantity)}</IonLabel>
                  </div>
                )}
                {!isComposition && (
                  <div>
                    <IonLabel>{getPrice(item.price, currency, false, item.quantity)}</IonLabel>
                  </div>
                )}
              </div>
              <div className="action" key={index} onClick={() => addQuantityItem(index, idCourse, item.multiplier!)}>
                <IonIcon className="icon" icon={add} />
              </div>
            </div>
          </div>
        </div>
      );
    });
  }
  let courseComplete = false;
  if (cartCourse.courseChoices && itemCourse) {
    courseComplete = getCourseQuantity(cartCourse) >= itemCourse!.min!;
  }

  return (
    <IonPage className="course-details">
      <IonFab className="cart-floating-button" style={{ top: "30px" }} slot="fixed">
        {courseComplete && (
          <IonFabButton onClick={() => history.goBack()}>
            <IonIcon icon={checkmark} />
          </IonFabButton>
        )}
        {!courseComplete && (
          <IonFabButton onClick={() => notCompleteCurse()}>
            <IonIcon icon={alert} />
          </IonFabButton>
        )}
      </IonFab>
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{menu.item?.description}</IonTitle>
        </IonToolbar>
        <div className="courseHeader">
          <div className="title">
            <IonLabel>{itemCourse?.description}</IonLabel>
          </div>

          <div className="courseLevelContainer">
            <div className="courseLevelElement">
              <IonLabel>{t("menuPage.selected")}</IonLabel>
              <IonLabel>{getCourseQuantity(cartCourse)}</IonLabel>
            </div>

            {itemCourse?.min != 0 && (
              <div className="courseLevelElement">
                <IonLabel>{t("menuPage.min")}</IonLabel>
                <IonLabel>{itemCourse?.min}</IonLabel>
              </div>
            )}
            {itemCourse?.max && (
              <div className="courseLevelElement">
                <IonLabel>{t("menuPage.max")}</IonLabel>
                <IonLabel>{itemCourse?.max}</IonLabel>
              </div>
            )}
          </div>
        </div>
      </IonHeader>
      <IonLoading isOpen={productResponse.loading} message={t("loading")} />
      <IonContent fullscreen>
        <div className="ccSelectContainer">{selectedElement}</div>
        {products && products.length > 0 && (
          <div className="ccItemContainer">
            {products!.map((item, index) => {
              return (
                <div>
                  <div className="ccElementContainer">
                    <div className="ccTitle">
                      <div className="section-horizontal">
                        <div className="title" key={index} onClick={() => openItem(item)}>
                          <IonLabel>{item.description}</IonLabel>
                        </div>
                        <div className="quantity">
                          {isComposition && (
                            <div>
                              <IonLabel>{componentPrice(item, 1)}</IonLabel>
                            </div>
                          )}
                        </div>
                        <div className="action" key={index + 200} onClick={() => addItem(item)}>
                          <IonIcon className="icon" icon={add} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Course;
