import { CartItem } from "../hooks/cart";

export type Currency = {
  code: string;
  numberOfDecimals: number;
};

export type Account = {
  code: string;
  currency: Currency;
};

export enum ExternalOrdersPaymentType {
  CN_CREDIT = "CN_CREDIT",
  TSPAY = "TSPAY",
  STRIPE = "STRIPE",
  SATISPAY = "SATISPAY",
  CASH_ON_DELIVERY = "CASH_ON_DELIVERY",
  OTHER = "OTHER",
  CASH_ON_TABLE = "CASH_ON_TABLE",
}

export type StripeProfile = {
  id?: number;
  description?: string;
  apiKey?: string;
  publishableKey?: string;
  idAccount?: number;
  idSalesPoint?: number;
};

export type StripePaymentMethod = {
  id?: string;
  brand?: string;
  expYear?: number;
  expMonth?: number;
  last4?: string;
};

export type ExternalOrdersPayment = {
  paymentType?: ExternalOrdersPaymentType;
  stripeProfileId?: number;
  stripeProfile?: StripeProfile;
  tsPayProfileId?: string;
  sourceTypes?: SourceType[];
};

export type ExternalOrdersDeliverySettings = {
  pickUpEnabled?: boolean;
  shippingEnabled?: boolean;
  otherDeliveryEnabled?: boolean;
  shippingMaxDistance?: number;
  shippingAverageTime?: number;
  nationalShippingFee?: number;
  foreignShippingFee?: number;
  shippingFeeDepartmentId?: string;
  freeShippingThreshold?: number;
  tableEnabled?: boolean;
};

export type ExternalOrdersWorkingTime = {
  start?: number;
  duration?: number;
  maximumDeliveryOrdersCount?: number;
  maximumPiecesOrdered?: number;
  maximumOrdersAmount?: number;
};

export type ExternalOrdersWorkingDay = {
  day?: number;
  workingTimes?: ExternalOrdersWorkingTime[];
};

export enum ExternalOrdersWorkflowConfirmationMethod {
  AUTOMATIC = "AUTOMATIC",
  EXPLICIT = "EXPLICIT",
}

export enum ExternalDefaultViewProducts {
  GRID = "GRID",
  LIST = "LIST",
}

export type ExternalOrdersWorkflowSettings = {
  confirmationMethod?: ExternalOrdersWorkflowConfirmationMethod;
  cancellationTimeThreshold?: number;
};

export type ExternalOrdersMobileCommerceSettings = {
  defaultDocumentNumberingId?: string;
  defaultSalesModeId?: string;
  otherDeliveryInfo?: string;
  otherDeliveryName?: string;
  defaultViewProducts?: ExternalDefaultViewProducts;
};

export type ExternalOrderSettings = {
  delivery?: ExternalOrdersDeliverySettings;
  workingDays?: ExternalOrdersWorkingDay[];
  workingTimeSlotSize?: number;
  orderTimeRequired?: boolean;
  minimumOrderProcessingTime?: number;
  minimumAmountAllowed?: number;
  acceptedPayments?: ExternalOrdersPayment[];
  eReceiptEnabled?: boolean;
  eCustomerEnabled?: boolean;
  workflowSettings?: ExternalOrdersWorkflowSettings;
  mobileCommerce?: ExternalOrdersMobileCommerceSettings;
  maximumBillItemQuantityAllowed?: number;
};

export type Module = {
  name?: string;
};

export type LicenseActivationModule = {
  module?: Module;
};

export type LicenseActivation = {
  validityStartDate?: number;
  validityEndDate?: number;
  licenseActivationModules?: LicenseActivationModule[];
};

export type License = {
  active?: boolean;
  licenseActivations?: LicenseActivation[];
};

export type SalesPoint = {
  id: number;
  externalOrdersSettings?: ExternalOrderSettings;
  licenses?: License[];
  country: string;
};

export type FidelitySalesPoint = {
  id: number;
  image?: string;
  street?: string;
  district?: string;
  zipcode?: string;
  city?: string;
  country?: string;
  name?: string;
  phoneNumber?: string;
  email?: string;
  latitude?: number;
  longitude?: number;
  salesPoint: SalesPoint;
};

export type FidelityCircuit = {
  id: number;
  appDefault?: boolean;
  freeReloadBonus?: number;
  name?: string;
  variation?: number;
  idFidelityAccount?: number;
};

export type Variation = {
  variation?: number;
  variationType?: VariationType;
};

export type FidelityAccount = {
  account: Account;
  name: string;
  payoff?: string;
  facebookId?: string;
  facebookLink?: string;
  websiteLink?: string;
  termsAndConditionsUrl?: string;
  privacyPolicyUrl?: string;
  homeEnabled?: boolean;
  email?: string;
  phoneNumber?: string;
  fidelitySalesPoints?: FidelitySalesPoint[];
  fidelityCircuit?: FidelityCircuit;
};

export type Category = {
  id: string;
  imageUrl: string;
  description: string;
  optionBindings?: OptionBinding[];
  optionValueBindings?: OptionValueBinding[];
};

export type Price = {
  price?: number;
  percentagePrice?: number;
};

export type ItemImage = {
  imageUrl: string;
  position: number;
};

export type StockStatus = {
  id?: string;
  stockLevel?: number;
  outgoingQuantity?: number;
};

export type StockConfig = {
  id?: string;
  live?: true;
  stockStatus?: StockStatus;
};

export type Sku = {
  id: string;
  item?: Item;
  descriptionReceipt?: string;
  skuOptionValues: SkuOptionValue[];
  stockConfigs?: StockConfig[];
  prices?: Price[];
};

export type Department = {
  id?: string;
  idTax?: string;
};

export enum OptionValueType {
  POSITIVE = "POSITIVE",
  NEGATIVE = "NEGATIVE",
  NEUTRAL = "NEUTRAL",
}

export type OptionValue = {
  id: string;
  idOption?: string;
  valueType?: OptionValueType;
  value?: string;
  position?: number;
  option?: Option;
};

export type Option = {
  id: string;
  description?: string;
  optionValues?: OptionValue[];
};

export enum BindingType {
  FEATURE = "FEATURE",
  OPTIONAL = "OPTIONAL",
}

export enum SourceType {
  CREDIT_CARD = "CREDIT_CARD",
  PAYPAL = "PAYPAL",
}

export type OptionBinding = {
  id: string;
  idItem?: string;
  idCategory?: string;
  idOption?: string;
  option?: Option;
  bindingType?: BindingType;
  position?: number;
};

export type OptionValueBinding = {
  id: string;
  idItem?: string;
  idCategory?: string;
  idOptionValue?: string;
  isDefault?: boolean;
  optionValue?: OptionValue;
  prices?: Price[];
};

export type SkuOptionValue = {
  idSku?: string;
  idOptionValueBinding?: string;
  optionValueBinding?: OptionValueBinding;
};

export type Course = {
  id: string;
  orderTicketCourseNumber?: number;
  min?: number;
  max?: number;
  idSalesPoint?: number;
  idItem?: number;
  position?: number;
  description?: string;
  courseChoices?: CourseChoices[];
};

export type CourseChoices = {
  id: string;
  idCourse?: string;
  multiplier?: number;
  item?: Item;
  idCategory?: string;
  idItem?: string;
  category?: Category;
  prices?: Price[];
};

export type CartCourses = {
  id: string;
  min?: number;
  max?: number;
  orderTicketCourseNumber?: number;
  position?: number;
  description?: string;
  courseChoices: CartItem[];
};

export type Item = {
  id?: string;
  description?: string;
  descriptionExtended?: string;
  soldOnlyInComposition?: boolean;
  idDepartment?: string;
  menu?: boolean;
  composition?: boolean;
  department?: Department;
  idCategory?: string;
  category?: Category;
  prices?: Price[];
  componentPrice?: number;
  itemImages?: ItemImage[];
  multivariant?: boolean;
  optionBindings?: OptionBinding[];
  optionValueBindings?: OptionValueBinding[];
  sku?: Sku[];
  courses?: Course[];
  multiplier?: number;
};

export type BillItemOptionValue = {
  idOptionValue?: string;
  price?: number;
  percentagePrice?: number;
  optionValue?: OptionValue;
};

export enum VariationType {
  CUSTOMER = "CUSTOMER",
  ORGANIZATION = "ORGANIZATION",
  FIDELITY_CIRCUIT = "FIDELITY_CIRCUIT",
  ROUNDING = "ROUNDING",
}

export type BillItem = {
  id?: string;
  rowNumber?: number;
  subtotal?: boolean;
  idItem?: string;
  idSku?: string;
  idDepartment?: string;
  idCategory?: string;
  idTax?: string;
  itemOptionValues?: BillItemOptionValue[];
  price?: number;
  quantity?: number;
  shippingCost?: boolean;
  percentageVariation?: number;
  variation?: number;
  variationType?: VariationType;
  sku?: Sku;
  item?: Item;
  menu?: boolean;
  composition?: boolean;
  coverCharge?: boolean;
  billItemCourseSkus?: BillItemCourseSkus[];
};

export type BillItemCourseSkus = {
  id?: string;
  idItem?: string;
  quantity?: number;
  idCourse?: string;
  idCategory?: string;
  idSku: string;
  sku?: Sku;
  multiplier?: number;
  idTax?: string;
  idDepartment?: string;
  price: number;
  componentPrice?: number;
  courseSkuOptionValues?: BillItemOptionValue[];
};

export enum DeliveryMode {
  PICKUP = "PICKUP",
  DELIVERY = "DELIVERY",
  OTHER = "OTHER",
  TABLE = "TABLE",
}

export enum ExternalWorkflowStatus {
  REQUESTED = "REQUESTED",
  REJECTED = "REJECTED",
  ACCEPTED = "ACCEPTED",
  PROCESSING = "PROCESSING",
  READY = "READY",
  SHIPPING = "SHIPPING",
  SHIPPED = "SHIPPED",
  DELIVERING = "DELIVERING",
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
}

export enum PrepaymentStatus {
  WAITING = "WAITING",
  AUTHORIZED = "AUTHORIZED",
  FAILED = "FAILED",
  PAYED = "PAYED",
  REFUNDED = "REFUNDED",
}

export type Order = {
  number?: number;
  externalWorkflowStatus?: ExternalWorkflowStatus;
  dueDate?: number;
  dueDatetime?: string;
  deliveryMode?: DeliveryMode;
  code?: string;
  idTable?: string;
  theTable?: Table;
  otherDeliveryDestination?: string;
  destinationStreet?: string;
  destinationCity?: string;
  destinationZipcode?: string;
  destinationDistrict?: string;
  destinationCountry?: string;
  transportNote?: string;
  prepay?: boolean;
  prepaymentType?: ExternalOrdersPaymentType;
  prepaymentStatus?: PrepaymentStatus;
  prepaymentTransactionId?: string;
  phoneNumber?: string;
  rejectionReason?: string;
};

export type SplitPaymentRequest = {
  amount?: number;
  description?: string;
  prepaymentType?: ExternalOrdersPaymentType;
  sourceType?: SourceType;
};

export type Table = {
  name: string;
};

export type OrderStripeClientSecret = {
  orderStripeClientSecret: string;
};

export type Bill = {
  id?: string;
  order?: Order;
  date?: number;
  datetime?: string;
  note?: string;
  amount?: number;
  billItems?: BillItem[];
};

export type Customer = {
  id?: string;
  name?: string;
  dateOfBirth?: string;
  gender?: string;
  vatNumber?: string;
  taxCode?: string;
  lotteryCode?: string;
  street?: string;
  city?: string;
  zipcode?: string;
  district?: string;
  country?: string;
  email?: string;
  phoneNumber?: string;
  note: string;
  variation1?: number;
  variation2?: number;
  variation3?: number;
  variation4?: number;
};

export type FidelityPrepaidAccount = {
  idFidelitySalesPoint: number;
  amount: number;
};

export type FidelityCustomer = {
  id: number;
  customer: Customer;
  prepaidAccounts: FidelityPrepaidAccount[];
};

export type WorkingPeriodSlot = {
  start?: number;
  availableDeliverableOrders?: number;
  availablePiecesOrderable?: number;
  availableOrdersAmount?: number;
};
