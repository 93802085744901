import { useQuery } from "@apollo/client";
import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonLoading, IonPage, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { getTSPayLinkToSaveQuery } from "../../apollo-client/queries";
import { useSelectedSalesPoint } from "../../hooks/selectedSalesPoint";
import "./payOrderTSPay.css";

type ModalProps = {
  guestModal?: boolean;
};

const PayOrderTSPay: React.FC<ModalProps> = ({ guestModal = false }) => {
  const { t } = useTranslation();
  const [toast] = useIonToast();
  const history = useHistory();
  const [selectedSalesPointId] = useSelectedSalesPoint();
  const { idOrder, sourceType } = useParams<{ idOrder: string; sourceType: string }>();

  const urlOrigin = window.location.origin;

  let responseUrl = `${urlOrigin}/tspay-response/${idOrder}`;
  if (guestModal) {
    responseUrl = `${urlOrigin}/prepay/tspay-response/${idOrder}`;
  }

  const tspayLinkResponse = useQuery(getTSPayLinkToSaveQuery, { variables: { idSalesPoint: selectedSalesPointId, idOrder, callbackUrl: responseUrl, sourceTypes: sourceType } });
  const tspayUrl = tspayLinkResponse?.data?.TSPayLinkToSave.tspayLinkToSaveUrl;

  const goPayment = () => {
    if (tspayLinkResponse.data) {
      window.location.href = tspayUrl;
    }
  };

  useEffect(() => {
    if (tspayLinkResponse.error) {
      let redirectUrl = "/app/orders";
      if (guestModal) {
        redirectUrl = `/order-payment/${selectedSalesPointId}/${idOrder}`;
      }
      toast({
        cssClass: "order-payed-toast",
        color: "light",
        message: t("pay_order.tsPayErrorMsg"),
        duration: 2000,
        animated: true,
        position: "middle",
        onDidDismiss: () => {
          history.replace(redirectUrl);
        },
      });
    }
    if (tspayLinkResponse.data) {
      if (tspayLinkResponse.data.TSPayLinkToSave.confirmationMethod !== "EXPLICIT") {
        window.location.href = tspayUrl;
      }
    }
  }, [tspayLinkResponse]);

  return (
    <IonPage className="pay-order">
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="start"></IonButtons>
          <IonTitle>{t("pay_order.title")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonLoading isOpen={tspayLinkResponse.loading} />
      <IonContent fullscreen>
        {tspayLinkResponse.data && tspayLinkResponse.data.TSPayLinkToSave.confirmationMethod === "EXPLICIT" && <div className="msg">Riceverai dalla tua banca una notifica di addebito pari a 0,00 €, in quanto l'addebito dell'effettivo importo da corrispondere avverrà automaticamente in seguito</div>}
      </IonContent>
      <IonFooter className="confirm-bar">
        <IonButton onClick={() => goPayment()} expand="block">
          {t("table_payment.pay")}
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default PayOrderTSPay;
