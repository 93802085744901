import { IonContent, IonHeader, IonLabel, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import QRCode from "react-qr-code";
import "./paymentResponse.css";

type ModalProps = {
  withMail?: boolean;
};

const PaymentResponse: React.FC<ModalProps> = ({ withMail = false }) => {
  const { idOrder } = useParams<{ idOrder: string }>();
  const { t } = useTranslation();

  const qrValue = `DA${idOrder}`;

  return (
    <IonPage className="mainSelection">
      <IonHeader>
        <IonToolbar color="secondary">
          <IonTitle>{t("pay_order.title")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="pageContainer">
        <div className="qrHeader">
          <div className="line">
            <IonLabel className="success" position="stacked">
              {t("table_payment.successPayment")}
            </IonLabel>
          </div>
          <div className="line">
            {withMail && <IonLabel position="stacked">{t("table_payment.closePaymentMsgWithEmail")}</IonLabel>}
            {!withMail && <IonLabel position="stacked">{t("table_payment.closePaymentMsg")}</IonLabel>}
          </div>
        </div>
        <div className="qrContainer">
          <QRCode size={300} style={{ height: "auto", maxWidth: "100%", width: "100%" }} value={qrValue} viewBox={`0 0 300 300`} />
        </div>
        <div className="qrFooter">
          <IonLabel>{t("table_payment.orderQrMsg")}</IonLabel>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PaymentResponse;
