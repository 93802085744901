import { IonBadge, IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { close } from "ionicons/icons";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BillItemOptionValue, Item, OptionValueType, Option, OptionValue, OptionValueBinding, Sku } from "../apollo-client/types";
import { useTenant } from "../hooks/multiTenancy";
import { getAdditionRemovalPrice, getPrice, getProductAdditionalRemovals, getProductAdditionalRemovalsValues } from "../utils";
import "./additionRemoval.css";

type ModalProps = {
  menu?: boolean;
  item: Item;
  sku?: Sku;
  additionRemovals?: BillItemOptionValue[];
  onChange?: (additionRemovals: BillItemOptionValue[]) => void;
  dismiss: () => void;
};

type Tabs = "additions" | "removals" | "others";
type ExtendedOption = Omit<Option, "optionValues"> & { optionValues: (OptionValue & { optionValueBinding: OptionValueBinding })[] };

const AdditionRemovalsModal: React.FC<ModalProps> = ({ menu = false, item, sku, additionRemovals, onChange, dismiss }) => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const currency = tenant.settings?.fidelityAccount?.account.currency;

  const options = getProductAdditionalRemovals(item);

  const additions: ExtendedOption[] = [];
  const removals: ExtendedOption[] = [];
  const others: ExtendedOption[] = [];

  options.forEach((option) => {
    const optionValues = getProductAdditionalRemovalsValues(item, option.id);
    const additionValues = optionValues.filter((ov) => ov.valueType === OptionValueType.POSITIVE);
    const removalValues = optionValues.filter((ov) => ov.valueType === OptionValueType.NEGATIVE);
    const otherValues = optionValues.filter((ov) => ov.valueType === OptionValueType.NEUTRAL);

    if (additionValues.length > 0) {
      additions.push({ ...option, optionValues: additionValues });
    }
    if (removalValues.length > 0) {
      removals.push({ ...option, optionValues: removalValues });
    }
    if (otherValues.length > 0) {
      others.push({ ...option, optionValues: otherValues });
    }
  });

  const tabs: { type: Tabs; options: ExtendedOption[] }[] = [];
  if (additions.length > 0) {
    tabs.push({ type: "additions", options: additions });
  }
  if (removals.length > 0) {
    tabs.push({ type: "removals", options: removals });
  }
  if (others.length > 0) {
    tabs.push({ type: "others", options: others });
  }

  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [currentAdditionRemovals, setCurrentAdditionRemovals] = useState<BillItemOptionValue[] | undefined>(additionRemovals);

  const onOptionValueClick = (ov: OptionValue & { optionValueBinding: OptionValueBinding }) => {
    const newAdditionalRemovals: BillItemOptionValue[] = currentAdditionRemovals ? [...currentAdditionRemovals] : [];
    const existing = currentAdditionRemovals?.find((ar) => ar.idOptionValue === ov.id);
    if (existing) {
      _.remove(newAdditionalRemovals, existing);
    } else {
      const price = _.first(ov.optionValueBinding.prices)?.price;
      if (!menu) {
        const percentagePrice = _.first(ov.optionValueBinding.prices)?.percentagePrice;
        newAdditionalRemovals.push({
          idOptionValue: ov.id,
          optionValue: ov,
          price: price || (percentagePrice ? price : 0),
          percentagePrice: percentagePrice,
        });
      } else {
        newAdditionalRemovals.push({
          idOptionValue: ov.id,
          optionValue: ov,
          price: price ? price : 0,
          percentagePrice: undefined,
        });
      }
    }
    setCurrentAdditionRemovals(newAdditionalRemovals);
  };

  const onConfirmClick = () => {
    if (onChange && currentAdditionRemovals) {
      onChange(currentAdditionRemovals);
    }
  };

  const itemPrice = _.first(item.prices)?.price;
  const skuPrice = _.first(sku?.prices)?.price;
  const basePrice = skuPrice ? skuPrice : itemPrice!;

  return (
    <IonPage className="addition-removal-modal-container">
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="start">
            <IonButton onClick={() => dismiss()}>
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
          <IonTitle>{t("product_details.addition_removals.title")}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onConfirmClick}>{t("product_details.addition_removals.confirm")}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="tabs">
          <div className="tab-bar">
            {tabs.map((tab) => (
              <IonButton key={tab.type} fill={selectedTab.type === tab.type ? "solid" : "clear"} color={selectedTab.type === tab.type ? "primary" : "dark"} expand="full" onClick={() => setSelectedTab(tab)}>
                {t(`product_details.addition_removals.${tab.type}`)}
                <IonBadge color={selectedTab.type === tab.type ? "primary" : "dark"} slot="end">
                  {currentAdditionRemovals?.filter((ar) => {
                    return tab.options.find((o) => o.optionValues?.find((ov) => ov.id === ar.idOptionValue));
                  }).length || 0}
                </IonBadge>
              </IonButton>
            ))}
          </div>
          <div className="tab">
            {selectedTab.options.map((option) => (
              <div key={option.id} className="option">
                <div className="title">{option.description}</div>
                <div className="values">
                  {option.optionValues?.map((optionValue) => {
                    const selected = currentAdditionRemovals?.find((ar) => ar.idOptionValue === optionValue.id);
                    const price = _.first(optionValue.optionValueBinding.prices);
                    return (
                      <IonButton key={optionValue.id} color={selected ? "primary" : "dark"} fill={selected ? "solid" : "outline"} expand="block" onClick={() => onOptionValueClick(optionValue)}>
                        <div className="value-container">
                          {selectedTab.type === "additions" && <div className="addition">+</div>}
                          {selectedTab.type === "removals" && <div className="removal">-</div>}
                          <div className="description">{optionValue.value}</div>
                          {menu && price && <div className="price">{getPrice(price, currency, true, undefined, false)}</div>}
                          {!menu && price && <div className="price">{getPrice(price, currency, true)}</div>}
                        </div>
                      </IonButton>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      </IonContent>
      <IonFooter>
        <div className="recap">
          <div className="description">{item.description}</div>
          <div className="price">{getPrice(getAdditionRemovalPrice(basePrice, currentAdditionRemovals), currency)}</div>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default AdditionRemovalsModal;
