import { useQuery } from "@apollo/client";
import { IonContent, IonFooter, IonHeader, IonLoading, IonPage, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { getSatispayRedirectUrlQuery } from "../../apollo-client/queries";
import { useSelectedSalesPoint } from "../../hooks/selectedSalesPoint";
import "./payOrderSatispay.css";

type ModalProps = {
  guestModal?: boolean;
};

const PayOrderSatispay: React.FC<ModalProps> = ({ guestModal = false }) => {
  const { t } = useTranslation();
  const [toast] = useIonToast();
  const history = useHistory();
  const [selectedSalesPointId] = useSelectedSalesPoint();
  const { idOrder, idMainOrder } = useParams<{ idOrder: string; idMainOrder: string }>();
  const urlOrigin = window.location.origin;

  let responseUrl = `${urlOrigin}/satispay-response/${idOrder}`;
  if (guestModal) {
    responseUrl = `${urlOrigin}/prepay/satispay-response/${idOrder}/${idMainOrder}`;
  }

  const satispayRedirectUrlResponse = useQuery(getSatispayRedirectUrlQuery, { variables: { idSalesPoint: selectedSalesPointId, idOrder, callbackUrl: responseUrl } });
  const satispayUrl = satispayRedirectUrlResponse?.data?.SatispayRedirectUrl.satispayRedirectUrl;

  useEffect(() => {
    if (satispayRedirectUrlResponse.error) {
      let redirectUrl = "/app/orders";
      if (guestModal) {
        redirectUrl = `/order-payment/${selectedSalesPointId}/${idOrder}`;
      }
      toast({
        cssClass: "order-payed-toast",
        color: "light",
        message: t("pay_order.tsPayErrorMsg"),
        duration: 2000,
        animated: true,
        position: "middle",
        onDidDismiss: () => {
          history.replace(redirectUrl);
        },
      });
    }
    if (satispayRedirectUrlResponse.data) {
      window.location.href = satispayUrl;
    }
  }, [satispayRedirectUrlResponse]);

  return (
    <IonPage className="pay-order">
      <IonHeader>
        <IonToolbar color="secondary">
          <IonTitle>{t("pay_order.title")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonLoading isOpen={satispayRedirectUrlResponse.loading} />
      <IonContent className="content" fullscreen></IonContent>
      <IonFooter className="confirm-bar"></IonFooter>
    </IonPage>
  );
};

export default PayOrderSatispay;
