import { ExternalOrdersPaymentType } from "../../apollo-client/types";
import { useTenant } from "../../hooks/multiTenancy";
import { getSalesPoint } from "../../utils";
import { Elements } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useSelectedSalesPoint } from "../../hooks/selectedSalesPoint";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import PayOrderStripe from "./payOrderStripe";

type ModalProps = {
  guestModal?: boolean;
};

const PayOrder: React.FC<ModalProps> = ({ guestModal = false }) => {
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();

  const tenant = useTenant();
  const [selectedSalesPointId] = useSelectedSalesPoint();
  const selectedSalesPoint = getSalesPoint(tenant, selectedSalesPointId);

  const stripePaymentType = selectedSalesPoint?.salesPoint.externalOrdersSettings?.acceptedPayments?.find((p) => p.paymentType === ExternalOrdersPaymentType.STRIPE);
  useEffect(() => {
    if (stripePaymentType?.stripeProfile?.publishableKey) {
      setStripePromise(loadStripe(stripePaymentType.stripeProfile?.publishableKey));
    }
  }, [stripePaymentType]);

  if (stripePromise) {
    return (
      <Elements stripe={stripePromise}>
        <PayOrderStripe guestModal={guestModal} />
      </Elements>
    );
  } else {
    return <div>reload</div>;
  }
};

export default PayOrder;
