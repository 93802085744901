import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonLoading, IonModal, IonPage, IonRefresher, IonRefresherContent, IonTitle, IonToolbar, isPlatform } from "@ionic/react";
import "./categories.css";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { categoriesQuery } from "../../apollo-client/queries";
import { useSelectedSalesPoint } from "../../hooks/selectedSalesPoint";
import { useCallback, useEffect, useState } from "react";
import { Category, FidelitySalesPoint } from "../../apollo-client/types";
import { RefresherEventDetail } from "@ionic/core";
import { imageOutline, storefront, search } from "ionicons/icons";
import { useTenant } from "../../hooks/multiTenancy";
import { useHistory } from "react-router";
import { getSalesPoint } from "../../utils";
import SelectedSalesPointModal from "../../components/selectedSalesPointModal";

const PAGE_SIZE = 20;

const Categories: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const tenant = useTenant();
  const [selectedSalesPointId, changeSalesPoint] = useSelectedSalesPoint();
  const [categories, setCategories] = useState<Category[]>();
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
  const [selectPVShow, setPV] = useState<boolean>(false);

  const categoriesResponse = useQuery(categoriesQuery, {
    skip: !selectedSalesPointId,
    variables: {
      idSalesPoint: selectedSalesPointId,
      start: 0,
      limit: PAGE_SIZE,
    },
  });

  const selectPv = (salesPoint: FidelitySalesPoint) => {
    console.log(salesPoint);
    setPV(false);
    changeSalesPoint(salesPoint.salesPoint.id);
  };

  useEffect(() => {
    if (categoriesResponse.data) {
      setCategories(categoriesResponse.data.categories.records);
      setDisableInfiniteScroll(false);
    }
  }, [categoriesResponse]);

  const nextPage = async (e: CustomEvent<void>) => {
    const moreRecords = await categoriesResponse.fetchMore({
      variables: {
        idSalesPoint: selectedSalesPointId,
        start: categories?.length,
        limit: PAGE_SIZE,
      },
    });
    setCategories([...(categories || []), ...moreRecords.data.categories.records]);
    if (moreRecords.data.categories.records.length < PAGE_SIZE) {
      setDisableInfiniteScroll(true);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e?.target as any).complete();
  };

  const refresh = async (event: CustomEvent<RefresherEventDetail>) => {
    await categoriesResponse.refetch();
    event.detail.complete();
  };

  const showStoresModal = useCallback(() => {
    setPV(true);
  }, []);

  const openCategory = useCallback((category: Category) => {
    history.push(`/app/products/${category.id}`);
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="secondary">
          {tenant.settings?.mobileCommerceSalesPoints && tenant.settings?.mobileCommerceSalesPoints?.length > 1 && (
            <>
              <IonTitle>{getSalesPoint(tenant, selectedSalesPointId)?.name}</IonTitle>
              <IonTitle size="small">{getSalesPoint(tenant, selectedSalesPointId)?.city}</IonTitle>
              <IonButtons slot="start">
                <IonButton onClick={() => showStoresModal()}>
                  <IonIcon slot="icon-only" icon={storefront} />
                </IonButton>
              </IonButtons>
            </>
          )}
          {tenant.settings?.mobileCommerceSalesPoints && tenant.settings?.mobileCommerceSalesPoints?.length == 1 && <IonTitle>{t("products.title")}</IonTitle>}
          <IonButtons slot="end">
            <IonButton onClick={() => history.push("/app/products/search")}>
              <IonIcon slot="icon-only" icon={search} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonLoading isOpen={categoriesResponse.loading} showBackdrop={false} cssClass="infinite-scroll-loader" spinner={isPlatform("ios") ? "lines" : "circular"} />
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {categories?.map((c) => (
          <div key={c.id} className="category" style={c.imageUrl ? { backgroundImage: `url('${c.imageUrl}')` } : {}} onClick={() => openCategory(c)}>
            <div className="content">
              {!c.imageUrl && <IonIcon className="placeholder" icon={imageOutline} />}
              <div className="description">{c.description}</div>
            </div>
          </div>
        ))}
        <IonInfiniteScroll disabled={disableInfiniteScroll} threshold="100px" onIonInfinite={(e: CustomEvent<void>) => nextPage(e)}>
          <IonInfiniteScrollContent loadingText={t("loading")}></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
      <IonModal isOpen={selectPVShow} onDidDismiss={() => setPV(false)}>
        <SelectedSalesPointModal onClick={selectPv}></SelectedSalesPointModal>
      </IonModal>
    </IonPage>
  );
};

export default Categories;
