import { IonBackButton, IonButtons, IonContent, IonHeader, IonLabel, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { useTranslation } from "react-i18next";
import "./myId.css";
import QRCode from "react-qr-code";
import { useQuery, useReactiveVar } from "@apollo/client";
import { userQuery } from "../../apollo-client/queries";
import { FidelityCustomer } from "../../apollo-client/types";
import { idAppCustomerDeviceVar } from "../auth/login";

const MyId: React.FC = () => {
  const { t } = useTranslation();

  const userResponse = useQuery(userQuery);
  const user: FidelityCustomer = userResponse.data?.user;

  const idAppCustomerDevice = useReactiveVar(idAppCustomerDeviceVar);

  let qrValue = "";

  if (user) {
    qrValue = `{"dev":"${idAppCustomerDevice}","ver":2,"cstm":"${user.customer.id}","type":2}`;
  }

  return (
    <IonPage className="mainSelection">
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{t("myId.title")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="pageContainer">
        <div className="qrHeader"></div>
        <div className="qrContainer">{user && <QRCode size={300} style={{ height: "auto", maxWidth: "100%", width: "100%" }} value={qrValue} viewBox={`0 0 300 300`} />}</div>
        <div className="qrFooter">
          <IonLabel>{t("table_payment.orderQrMsg")}</IonLabel>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MyId;
