import { IonButton, IonPage } from "@ionic/react";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Item, OptionValue, Sku } from "../apollo-client/types";
import { useTenant } from "../hooks/multiTenancy";
import { getProductOptions, getProductOptionValues, getSkuOptionValues, getSkuPrice } from "../utils";
import "./multivariants.css";

type ModalProps = {
  item: Item;
  sku?: Sku;
  optionValues?: { [key: string]: string };
  onChange?: (sku?: Sku, optionValues?: { [key: string]: string }) => void;
  onSelect?: (sku: Sku) => void;
};

const MultivariantsModal: React.FC<ModalProps> = ({ item, sku, optionValues, onChange, onSelect }) => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const currency = tenant.settings?.fidelityAccount?.account.currency;

  const [currentOptionValues, setCurrentOptionValues] = useState<{ [key: string]: string } | undefined>(optionValues);
  const [currentSku, setCurrentSku] = useState<Sku | undefined>(sku);

  const options = getProductOptions(item);

  const onOptionValueClick = (ov: OptionValue) => {
    const newOptionValues: { [key: string]: string } = currentOptionValues ? { ...currentOptionValues } : {};
    if (newOptionValues[ov.idOption!] === ov.id) {
      delete newOptionValues[ov.idOption!];
    } else {
      newOptionValues[ov.idOption!] = ov.id;
    }
    setCurrentOptionValues(newOptionValues);
    if (onChange) {
      onChange(currentSku, newOptionValues);
    }
  };

  const onSkuClick = (sku: Sku) => {
    setCurrentSku(sku);

    const newOptionValues: { [key: string]: string } = {};
    getSkuOptionValues(item, sku)?.forEach((sov) => (newOptionValues[sov.idOption!] = sov.id));

    if (onChange) {
      onChange(sku, newOptionValues);
    }
    if (onSelect) {
      onSelect(sku);
    }
  };

  return (
    <IonPage className="multivariant-modal-container">
      {options.map((option) => (
        <div key={option.id} className="option">
          <div className="title">{option.description}</div>
          <div className="values">
            {getProductOptionValues(item, option.id).map((ov) => (
              <IonButton key={ov.id} fill="outline" onClick={() => onOptionValueClick(ov)} color={currentOptionValues && currentOptionValues[option.id] === ov.id ? "primary" : "dark"}>
                {ov.value}
              </IonButton>
            ))}
          </div>
        </div>
      ))}
      <div className="skus">
        <div className="title">{t("product_details.select_variant")}</div>
        <div className="values">
          {currentOptionValues &&
            Object.keys(currentOptionValues).length >= 0 &&
            _.sortBy(item!.sku!, (s) => s.descriptionReceipt)
              .filter((sku) => item && _.some(getSkuOptionValues(item, sku), (ov) => currentOptionValues && currentOptionValues[ov.idOption!] === ov.id))
              .map((s) => (
                <IonButton key={s.id} fill="outline" onClick={() => onSkuClick(s)} color={currentSku?.id === s.id ? "primary" : "dark"}>
                  <div className="sku-container">
                    <div className="description">{s.descriptionReceipt}</div>
                    <div className="price">{getSkuPrice(item, s, currency)}</div>
                  </div>
                </IonButton>
              ))}

          {currentOptionValues &&
            Object.keys(currentOptionValues).length === 0 &&
            _.sortBy(item!.sku!, (s) => s.descriptionReceipt).map((s) => (
              <IonButton key={s.id} fill="outline" onClick={() => onSkuClick(s)} color={currentSku?.id === s.id ? "primary" : "dark"}>
                <div className="sku-container">
                  <div className="description">{s.descriptionReceipt}</div>
                  <div className="price">{getSkuPrice(item, s, currency)}</div>
                </div>
              </IonButton>
            ))}

          {!currentOptionValues &&
            _.sortBy(item!.sku!, (s) => s.descriptionReceipt).map((s) => (
              <IonButton key={s.id} fill="outline" onClick={() => onSkuClick(s)} color={currentSku?.id === s.id ? "primary" : "dark"}>
                <div className="sku-container">
                  <div className="description">{s.descriptionReceipt}</div>
                  <div className="price">{getSkuPrice(item, s, currency)}</div>
                </div>
              </IonButton>
            ))}
        </div>
      </div>
    </IonPage>
  );
};

export default MultivariantsModal;
