import "./oneTimePayment.css";
import { useTranslation } from "react-i18next";
import { ApolloClient, from, gql, InMemoryCache, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router";
import { RestLink } from "apollo-link-rest";
import { IonButton, IonCard, IonContent, IonFooter, IonHeader, IonLoading, IonPage, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import { useEffect, useMemo, useState } from "react";
import { getPrice } from "../../utils";
import { useTenant } from "../../hooks/multiTenancy";

const OneTimePayment: React.FC = () => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const [toast] = useIonToast();
  const { idOrder } = useParams<{ idOrder: string }>();

  const [token, setToken] = useState<string>();
  const [payed, setPayed] = useState<boolean>();

  // DEDICATED APOLLO CLIENTE FOR CN API ----------------------
  const apolloClient = useMemo(() => {
    const headers: { [key: string]: string } = {};
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
      headers["X-Version"] = "1.0.0";
      headers["X-Requested-With"] = "*";
    }

    const restLink = new RestLink({
      uri: "https://cassanova-api-staging.herokuapp.com",
      credentials: "include",
      headers,
    });

    const cache = new InMemoryCache();

    return new ApolloClient({
      link: from([restLink]),
      cache: cache,
    });
  }, [token]);

  // ------------------------------------------------------------

  const [getToken] = useMutation(
    gql`
      mutation GetAccessToken($apiKey: String) {
        accessToken(input: { apiKey: $apiKey }) @rest(type: "AccessToken", path: "/apikey/token", method: "POST") {
          access_token
        }
      }
    `,
    {
      client: apolloClient,
      variables: { apiKey: "9f1dc742-6562-4cc2-b3d6-be002e899e66" },
    }
  );

  useEffect(() => {
    const init = async () => {
      const tokenResponse = await getToken();
      setToken(tokenResponse.data.accessToken.access_token);
    };
    init();
  }, []);

  const orderResponse = useQuery(
    gql`
      query GetOrder($orderId: String) {
        order(orderId: $orderId) @rest(type: "Order", path: "/documents/orders/{args.orderId}", method: "GET") {
          order {
            id
            prepaymentStatus
            document {
              number
              amount
            }
          }
        }
      }
    `,
    {
      client: apolloClient,
      skip: !token,
      variables: { orderId: idOrder },
    }
  );
  const order = orderResponse.data?.order?.order;

  const [payOrder, payOrderResponse] = useMutation(
    gql`
      mutation PayOrder($orderId: String) {
        orderPayed(orderId: $orderId, input: { prepay: true, prepaymentType: OTHER, prepaymentStatus: PAYED }) @rest(type: "OrderPayed", path: "/documents/orders/{args.orderId}/prepayment", method: "PUT") {
          id
          prepaymentStatus
        }
      }
    `,
    {
      client: apolloClient,
      variables: { orderId: idOrder },
    }
  );

  const pay = async () => {
    try {
      await payOrder();
      setPayed(true);
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      toast((e as any).message, 5000);
    }
  };

  return (
    <IonPage className="onetime-payment">
      <IonHeader>
        <IonToolbar color="secondary">
          <IonTitle>{t("onetime_payment.title")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonLoading isOpen={!orderResponse.called || orderResponse.loading || payOrderResponse.loading} message={t("loading")} />
      <IonContent fullscreen>
        <div className="content">
          {order && (
            <IonCard className="header">
              <div className="section order-title">
                <div>{t("orders.order", { number: order.number })}</div>
              </div>
              <div className="section section-horizontal">
                <div className="title">{t("orders.payment")}</div>
                <div className="value">
                  <div>{getPrice(order.document.amount, tenant.settings?.fidelityAccount?.account.currency)}</div>
                </div>
              </div>
              {(payed || order.prepaymentStatus === "PAYED") && <div className="section section-horizontal payed">{t("onetime_payment.payed")}</div>}
            </IonCard>
          )}
        </div>
      </IonContent>
      {!payed && order?.prepaymentStatus !== "PAYED" && (
        <IonFooter className="pay-bar">
          <IonButton disabled={!order || order.prepaymentStatus} onClick={pay} expand="block">
            {t("onetime_payment.pay")}
          </IonButton>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default OneTimePayment;
