import { IonBackButton, IonButton, IonButtons, IonCard, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonList, IonLoading, IonPage, IonRefresher, IonRefresherContent, IonRow, IonTitle, IonToolbar, isPlatform } from "@ionic/react";
import "./products.css";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { useSelectedSalesPoint } from "../../hooks/selectedSalesPoint";
import { useCallback, useEffect, useState } from "react";
import { RefresherEventDetail } from "@ionic/core";
import { imageOutline, search } from "ionicons/icons";
import { ExternalDefaultViewProducts, Item } from "../../apollo-client/types";
import { productsQuery } from "../../apollo-client/queries";
import { getMainImage, getProductPrice, getSalesPoint } from "../../utils";
import { useHistory, useLocation, useParams } from "react-router";
import { useTenant } from "../../hooks/multiTenancy";
import queryString from "query-string";
import { useMenu } from "../../hooks/menu";

const PAGE_SIZE = 50;

const Products: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { idCategory } = useParams<{ idCategory: string }>();
  const { search: searchQuerystring } = useLocation();
  const { search: searchParam } = queryString.parse(searchQuerystring);
  const tenant = useTenant();
  const [selectedSalesPointId] = useSelectedSalesPoint();
  const [products, setProducts] = useState<Item[]>();
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
  const [, , , , , , , , clean] = useMenu();
  const selectedSalesPoint = getSalesPoint(tenant, selectedSalesPointId);
  const externalOrdersSettings = selectedSalesPoint?.salesPoint?.externalOrdersSettings;

  let displayListView = false;
  if (externalOrdersSettings?.mobileCommerce?.defaultViewProducts) {
    displayListView = externalOrdersSettings?.mobileCommerce?.defaultViewProducts === ExternalDefaultViewProducts.LIST;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filter: any = {};
  filter.soldOnlyInComposition = false;
  if (idCategory) {
    filter.idCategory = [idCategory];
  }
  if (searchParam) {
    filter.query = {
      description: [`%${searchParam}%`],
      descriptionLabel: [`%${searchParam}%`],
      skuCode: [`%${searchParam}%`],
      skuBarcode: [`%${searchParam}%`],
      skuDescriptionLabel: [`%${searchParam}%`],
    };
  }

  const productsResponse = useQuery(productsQuery, {
    skip: !selectedSalesPointId,
    variables: {
      idSalesPoint: selectedSalesPointId,
      filter: encodeURIComponent(JSON.stringify(filter)),
      start: 0,
      limit: PAGE_SIZE,
    },
  });

  useEffect(() => {
    if (productsResponse.data) {
      setProducts(productsResponse.data.products.records);
      setDisableInfiniteScroll(false);
    }
  }, [productsResponse]);

  const nextPage = async (e: CustomEvent<void>) => {
    const moreRecords = await productsResponse.fetchMore({
      variables: {
        idSalesPoint: selectedSalesPointId,
        start: products?.length,
        limit: PAGE_SIZE,
      },
    });
    setProducts([...(products || []), ...moreRecords.data.products.records]);
    if (moreRecords.data.products.records.length < PAGE_SIZE) {
      setDisableInfiniteScroll(true);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e?.target as any).complete();
  };

  const refresh = async (event: CustomEvent<RefresherEventDetail>) => {
    await productsResponse.refetch();
    event.detail.complete();
  };

  const openProduct = useCallback((product: Item) => {
    if (product.menu || product.composition) {
      clean();
      history.push(`/app/products/menu-details/${product.id}`);
    } else {
      history.push(`/app/products/product-details/${product.id}`);
    }
  }, []);

  return (
    <IonPage className="products">
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{t("products.title")}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => history.push("/app/products/search")}>
              <IonIcon slot="icon-only" icon={search} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonLoading isOpen={productsResponse.loading} showBackdrop={false} cssClass="infinite-scroll-loader" spinner={isPlatform("ios") ? "lines" : "circular"} />
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {!productsResponse.loading && (!products || products?.length === 0) && <div className="no-results-placeholder">{t("products.no_results")}</div>}
        {products && products?.length > 0 && !displayListView && (
          <IonGrid className="products-grid">
            <IonRow>
              {products?.map((p) => {
                const image = getMainImage(p);
                return (
                  <IonCol key={p.id} sizeXs="6" sizeLg="4" sizeXl="2">
                    <IonCard key={p.id} className="product" onClick={() => openProduct(p)}>
                      <div className="image" style={image ? { backgroundImage: `url('${image}')` } : {}}>
                        {!image && <IonIcon className="placeholder" icon={imageOutline} />}
                        <div className="price">{getProductPrice(p, tenant.settings?.fidelityAccount?.account.currency)}</div>
                      </div>
                      <div className="descriptionWrapper">
                        <div className="description">{p.description}</div>
                      </div>
                    </IonCard>
                  </IonCol>
                );
              })}
            </IonRow>
          </IonGrid>
        )}
        {products && products?.length > 0 && displayListView && (
          <IonList>
            {products?.map((p) => {
              return (
                <IonItem key={p.id} className="productList" onClick={() => openProduct(p)}>
                  <div className="listContainer">
                    <div className="description">{p.description}</div>
                    <div className="price">{getProductPrice(p, tenant.settings?.fidelityAccount?.account.currency)}</div>
                  </div>
                </IonItem>
              );
            })}
          </IonList>
        )}
        <IonInfiniteScroll disabled={disableInfiniteScroll} threshold="200px" onIonInfinite={(e: CustomEvent<void>) => nextPage(e)}>
          <IonInfiniteScrollContent loadingText={t("loading")} />
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
};

export default Products;
