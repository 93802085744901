import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonPage, IonToolbar } from '@ionic/react';
import './productsSearch.css';
import { useHistory } from 'react-router';
import { FormEvent, useRef, useState } from 'react';
import { search, timeOutline } from 'ionicons/icons';

const ProductsSearch: React.FC = () => {

  const history = useHistory();

  const [searchText, setSearchText] = useState<string | null | undefined>();
  const inputRef = useRef<HTMLIonInputElement>(null)

  const storedSearchHistoryObj = localStorage.getItem('search-history');
  const storedSearchHistory : string[] = storedSearchHistoryObj ? JSON.parse(storedSearchHistoryObj) : [];

  const [searchHistory, setSearchHistory] = useState<string[]>(storedSearchHistory);

  const newSearchText = () => {
    if(searchText){
      setSearchHistory([searchText, ...searchHistory]);
      localStorage.setItem('search-history', JSON.stringify([searchText, ...searchHistory]));
      history.push(`/app/products/search-results?search=${searchText}`);
      if(inputRef.current) inputRef.current.value = ''
    } else {
      inputRef.current?.setFocus();
    }
  };
 
  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    newSearchText();
  }

  return (
    <IonPage className="products-search">
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <form onSubmit={onSubmit}>  
            <IonInput ref={inputRef} autofocus inputMode="search" onIonChange={(e) => setSearchText(e.detail.value)}/>
          </form>
          <IonButtons slot="end">
            <IonButton onClick={() => newSearchText()}><IonIcon slot="icon-only" icon={search} /></IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList>
          {
            searchHistory.map((searcItem, index) => (
              <IonItem key={index} onClick={() => history.push(`/app/products/search-results?search=${searcItem}`)}>
                <IonIcon slot="start" icon={timeOutline} />
                <IonLabel>{searcItem}</IonLabel>
              </IonItem>
            ))
          }
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default ProductsSearch;
