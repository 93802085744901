import { ApolloClient, makeVar, NormalizedCacheObject, useReactiveVar } from "@apollo/client";
import { useCallback, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useCart } from "./cart";

const TOKEN_NAME = "cassanova-fidelityapp-access-token";

export type Auth = {
  loading: boolean;
  authenticated: boolean;
  token?: string;
};

export const authVar = makeVar<Auth>({ loading: true, authenticated: false });

export const useAuth = (): [Auth, (token: string) => void, (client: ApolloClient<NormalizedCacheObject>) => void] => {
  const [cookies, setCookie, removeCookie] = useCookies([TOKEN_NAME]);
  const auth = useReactiveVar(authVar);
  const [, , , , cleanCart] = useCart();

  useEffect(() => {
    if (auth.loading) {
      // this is executed only the first time a useAuth hook is called
      if (cookies[TOKEN_NAME] && cookies[TOKEN_NAME] !== auth.token) {
        authVar({ loading: false, token: cookies[TOKEN_NAME], authenticated: true });
      } else {
        authVar({ loading: false, authenticated: false });
      }
    }
  }, [auth, cookies]);

  const setToken = useCallback((token) => {
    setCookie(TOKEN_NAME, token, { path: "/", secure: true, maxAge: 365 * 24 * 60 * 60 });
    authVar({ loading: false, authenticated: true, token });
  }, []);

  const logout = useCallback((client: ApolloClient<NormalizedCacheObject>) => {
    removeCookie(TOKEN_NAME, { path: "/" });
    authVar({ loading: false, authenticated: false });
    cleanCart();
    client.resetStore();
    location.href = "/";
  }, []);

  return [auth, setToken, logout];
};
