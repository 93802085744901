import "./tablePayment.css";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ActionSheetButton, IonActionSheet, IonButton, IonCard, IonContent, IonFooter, IonHeader, IonItem, IonList, IonLoading, IonModal, IonPage, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { getPaymentsQuery, orderCreatePaymentMutation, orderCreatePaymentWithSourceTypeMutation, orderDetailsQuery } from "../../apollo-client/queries";
import { Bill, BillItem, ExternalOrdersPaymentType, OptionValueType, SourceType, SplitPaymentRequest } from "../../apollo-client/types";
import { useTenant } from "../../hooks/multiTenancy";
import { getAdditionRemovalPrice, getOrderMenuPrice, getPrice, getSalesPoint, paymentsAmount } from "../../utils";
import { useSelectedSalesPoint } from "../../hooks/selectedSalesPoint";
import SplitPaymentsModal from "../../components/splitPayments";
import ShowPaymentsModal from "../../components/showPayments";
import _ from "lodash";

type ModalProps = {
  payTable?: boolean;
};

const TablePayment: React.FC<ModalProps> = ({ payTable = false }) => {
  const { idElement, idSalesPoint } = useParams<{ idElement: string; idSalesPoint: string }>();
  const tenant = useTenant();
  const [toast] = useIonToast();
  const { t } = useTranslation();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [showPaymentsModal, setShowPaymentsModal] = useState(false);
  const [paymentType, setPaymentType] = useState<ExternalOrdersPaymentType | undefined>(undefined);
  const [sourceType, setSourceType] = useState<SourceType | undefined>(undefined);
  const [buttons] = useState<(string | ActionSheetButton<unknown>)[] | undefined>([]);
  const idSalesPointNumber: number = +idSalesPoint;
  const [getPayments, getPaymentsResponse] = useLazyQuery(getPaymentsQuery);
  const [paySplit, paySplitResponse] = useMutation(orderCreatePaymentMutation);
  const [paySplitWithST, paySplitWithSTResponse] = useMutation(orderCreatePaymentWithSourceTypeMutation);
  const [reFetchCount, setReFetchCount] = useState<number>(0);

  const selectPaymentAmount = (id: string, confirmationMethod: string, stripeClientSecret?: string, tspayLinkToSaveUrl?: string, satispayRedirectUrl?: string) => {
    setShowModal(false);
    if (stripeClientSecret) {
      history.replace(`/stripepay/${id}`);
    } else if (tspayLinkToSaveUrl) {
      history.replace(`/tspay/${id}/${sourceType}`);
    } else if (satispayRedirectUrl) {
      history.replace(`/satispay/${id}/${order.id}`);
    } else {
      toast({
        cssClass: "order-payed-toast",
        color: "light",
        message: t("table_payment.paymentErrorMsg"),
        duration: 5000,
        animated: true,
        position: "middle",
      });
    }
    return;
  };

  const amountToPay = (totalAmount: number, paymentAmount: number) => {
    const tot = totalAmount - paymentAmount;
    return Number(tot.toFixed(2));
  };

  const variationElement = (billItem: BillItem) => {
    if (billItem.percentageVariation || billItem.variation) {
      let variationType = "";
      let variationValue = "";
      if (billItem.percentageVariation) {
        variationValue = `${Math.abs(billItem.percentageVariation)}%`;
        if (billItem.percentageVariation > 0) {
          variationType = "Sconto";
        } else {
          variationType = "Maggiorazione";
        }
      }
      if (billItem.variation) {
        if (billItem.variation > 0) {
          variationValue = getPrice(billItem.variation * -1, tenant.settings?.fidelityAccount?.account.currency);
          variationType = "Sconto";
        } else {
          variationValue = getPrice(Math.abs(billItem.variation), tenant.settings?.fidelityAccount?.account.currency);
          variationType = "Maggiorazione";
        }
      }
      return (
        <>
          <div className="quantity"></div>
          <div className="title">
            <div>{variationType}</div>
          </div>
          <div className="value">{variationValue}</div>{" "}
        </>
      );
    }
  };

  useEffect(() => {
    if (paySplitResponse.data) {
      selectPaymentAmount(
        paySplitResponse.data.orderCreatePayment.id,
        paySplitResponse.data.orderCreatePayment.confirmationMethod,
        paySplitResponse.data.orderCreatePayment.stripeClientSecret,
        paySplitResponse.data.orderCreatePayment.tspayLinkToSaveUrl,
        paySplitResponse.data.orderCreatePayment.satispayRedirectUrl
      );
    }
  }, [paySplitResponse.data]);

  useEffect(() => {
    if (paySplitWithSTResponse.data) {
      selectPaymentAmount(
        paySplitWithSTResponse.data.orderCreatePaymentWithSourceType.id,
        paySplitWithSTResponse.data.orderCreatePaymentWithSourceType.confirmationMethod,
        paySplitWithSTResponse.data.orderCreatePaymentWithSourceType.stripeClientSecret,
        paySplitWithSTResponse.data.orderCreatePaymentWithSourceType.tspayLinkToSaveUrl,
        paySplitWithSTResponse.data.orderCreatePaymentWithSourceType.satispayRedirectUrl
      );
    }
  }, [paySplitWithSTResponse.data]);

  const payTotalAmount = useCallback(
    async (paymentAmount: number, idOrder: string, pt: ExternalOrdersPaymentType) => {
      try {
        if (sourceType) {
          const paymentRequest: SplitPaymentRequest = {
            amount: Number(paymentAmount.toFixed(2)),
            description: "Pagamento",
            prepaymentType: pt,
          };
          await paySplitWithST({ variables: { idSalesPoint, idOrder, paymentRequest, sourceType } });
        } else {
          const paymentRequest: SplitPaymentRequest = {
            amount: Number(paymentAmount.toFixed(2)),
            description: "Pagamento",
            prepaymentType: pt,
          };
          await paySplit({ variables: { idSalesPoint, idOrder, paymentRequest } });
        }
      } catch (e) {
        toast("error", 5000);
      }
      return;
    },
    [paymentType]
  );

  const [selectedSalesPointId, changeSalesPoint] = useSelectedSalesPoint();
  changeSalesPoint(idSalesPointNumber, true);
  const selectedSalesPoint = getSalesPoint(tenant, selectedSalesPointId);
  const externalOrdersSettings = selectedSalesPoint?.salesPoint?.externalOrdersSettings;

  const [showActionSheet, setShowActionSheet] = useState(false);

  const onSplitModalDismiss = () => {
    setShowModal(false);
  };

  // eslint-disable-next-line unused-imports/no-unused-vars
  const onSplitModalOpen = (pt: ExternalOrdersPaymentType) => {
    setPaymentType(pt);
    setShowModal(true);
  };

  const onPaymentsModalDismiss = () => {
    setShowPaymentsModal(false);
  };

  const pay = async (totalAmount: boolean) => {
    let sourceTypesEnabled = false;
    const stripeEnabled = (externalOrdersSettings?.acceptedPayments || []).find((eop) => eop.paymentType === ExternalOrdersPaymentType.STRIPE);
    const tspayEnabled = (externalOrdersSettings?.acceptedPayments || []).find((eop) => eop.paymentType === ExternalOrdersPaymentType.TSPAY);
    if (tspayEnabled) {
      const souceTypes = tspayEnabled.sourceTypes!;
      if (souceTypes.length > 1) sourceTypesEnabled = true;
      if (souceTypes.length === 1) setSourceType(souceTypes[0]);
    }
    const satispayEnabled = (externalOrdersSettings?.acceptedPayments || []).find((eop) => eop.paymentType === ExternalOrdersPaymentType.SATISPAY);
    if ((stripeEnabled && tspayEnabled) || (satispayEnabled && tspayEnabled) || (satispayEnabled && stripeEnabled) || sourceTypesEnabled) {
      if (buttons!.length > 1) {
        setShowActionSheet(true);
        return;
      }
      if (stripeEnabled) {
        buttons?.push({
          text: t("payment_types.STRIPE"),
          handler: () => {
            setPaymentType(ExternalOrdersPaymentType.STRIPE);
            setSourceType(undefined);
            if (!totalAmount) onSplitModalOpen(ExternalOrdersPaymentType.STRIPE);
            else {
              let pAmount = 0;
              if (getPaymentsResponse.data.getPayments) pAmount = paymentsAmount(getPaymentsResponse.data.getPayments.records);
              payTotalAmount(order.amount! - pAmount, order.id!, ExternalOrdersPaymentType.STRIPE);
            }
          },
        });
      }

      if (satispayEnabled) {
        buttons?.push({
          text: t("payment_types.SATISPAY"),
          handler: () => {
            setPaymentType(ExternalOrdersPaymentType.SATISPAY);
            setSourceType(undefined);
            if (!totalAmount) onSplitModalOpen(ExternalOrdersPaymentType.SATISPAY);
            else {
              let pAmount = 0;
              if (getPaymentsResponse.data.getPayments) pAmount = paymentsAmount(getPaymentsResponse.data.getPayments.records);
              payTotalAmount(order.amount! - pAmount, order.id!, ExternalOrdersPaymentType.SATISPAY);
            }
          },
        });
      }
      if (tspayEnabled) {
        tspayEnabled.sourceTypes?.map((st) => {
          buttons?.push({
            text: t(`payment_types.${st}`),
            handler: () => {
              setSourceType(st);
              if (!totalAmount) onSplitModalOpen(ExternalOrdersPaymentType.TSPAY);
              else {
                let pAmount = 0;
                if (getPaymentsResponse.data.getPayments) pAmount = paymentsAmount(getPaymentsResponse.data.getPayments.records);
                payTotalAmount(order.amount! - pAmount, order.id!, ExternalOrdersPaymentType.TSPAY);
              }
            },
          });
        });
      }
      buttons?.push({
        text: t("cancel"),
        role: "cancel",
      });
      setShowActionSheet(true);
    } else if (stripeEnabled) {
      setPaymentType(ExternalOrdersPaymentType.STRIPE);
      if (!totalAmount) setShowModal(true);
      else {
        let pAmount = 0;
        if (getPaymentsResponse.data.getPayments) pAmount = paymentsAmount(getPaymentsResponse.data.getPayments.records);
        payTotalAmount(order.amount! - pAmount, order.id!, ExternalOrdersPaymentType.STRIPE);
      }
    } else if (tspayEnabled) {
      setPaymentType(ExternalOrdersPaymentType.TSPAY);
      if (!totalAmount) setShowModal(true);
      else {
        let pAmount = 0;
        if (getPaymentsResponse.data.getPayments) pAmount = paymentsAmount(getPaymentsResponse.data.getPayments.records);
        payTotalAmount(order.amount! - pAmount, order.id!, ExternalOrdersPaymentType.TSPAY);
      }
    } else if (satispayEnabled) {
      setPaymentType(ExternalOrdersPaymentType.SATISPAY);
      if (!totalAmount) setShowModal(true);
      else {
        let pAmount = 0;
        if (getPaymentsResponse.data.getPayments) pAmount = paymentsAmount(getPaymentsResponse.data.getPayments.records);
        payTotalAmount(order.amount! - pAmount, order.id!, ExternalOrdersPaymentType.SATISPAY);
      }
    } else {
      toast({
        cssClass: "order-payed-toast",
        color: "light",
        message: t("table_payment.paymentErrorMsg"),
        duration: 5000,
        animated: true,
        position: "middle",
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let orderResponse: any;

  if (payTable) {
    orderResponse = useQuery(orderDetailsQuery, {
      variables: {
        filter: encodeURIComponent(JSON.stringify({ idTable: idElement })),
      },
      fetchPolicy: "network-only",
    });
  } else {
    orderResponse = useQuery(orderDetailsQuery, {
      variables: {
        filter: encodeURIComponent(JSON.stringify({ id: [idElement] })),
      },
      fetchPolicy: "network-only",
    });
  }

  const order: Bill = orderResponse.data?.orders?.records ? orderResponse.data?.orders?.records[0] : undefined;
  let billItems: BillItem[] | undefined = undefined;

  const splitPayAction = useCallback(
    async (paymentName: string, paymentAmount: number) => {
      setShowModal(false);
      const idOrder = order.id;
      try {
        if (sourceType) {
          const paymentRequest: SplitPaymentRequest = {
            amount: Number(paymentAmount.toFixed(2)),
            description: paymentName,
            prepaymentType: paymentType,
          };
          await paySplitWithST({ variables: { idSalesPoint, idOrder, paymentRequest, sourceType } });
        } else {
          const paymentRequest: SplitPaymentRequest = {
            amount: Number(paymentAmount.toFixed(2)),
            description: paymentName,
            prepaymentType: paymentType,
          };
          await paySplit({ variables: { idSalesPoint, idOrder, paymentRequest } });
        }
      } catch (e) {
        toast("error", 5000);
      }
    },
    [paymentType, idSalesPoint, order]
  );

  if (order && order.billItems) {
    billItems = _.sortBy(order.billItems, (bi) => bi.rowNumber);
  }

  useEffect(() => {
    if (order !== undefined) {
      getPayments({ variables: { idOrder: order.id, idSalesPoint } });
    }
  }, [order]);

  useEffect(() => {
    if (getPaymentsResponse.called) {
      if (reFetchCount < 5) {
        const val = reFetchCount + 1;
        console.log(val);
        setReFetchCount(val);

        getPaymentsResponse.refetch();
      }
    }
  }, [getPaymentsResponse]);

  let orderTitle = "";
  let orderPayedStatus = "";
  let orderPayedStatusColor = "";
  let emptyTable = false;

  if (order) {
    if (getPaymentsResponse.data && getPaymentsResponse.data.getPayments.records && amountToPay(order.amount!, paymentsAmount(getPaymentsResponse.data.getPayments.records)) === 0) {
      orderPayedStatus = t("table_payment.payed");
      orderPayedStatusColor = "var(--ion-color-danger)";
    } else if (order!.order?.prepaymentStatus !== "PAYED" && order.order?.externalWorkflowStatus !== "COMPLETED") {
      orderPayedStatus = t("table_payment.notPayed");
      orderPayedStatusColor = "var(--ion-color-success)";
    } else {
      orderPayedStatus = t("table_payment.payed");
      orderPayedStatusColor = "var(--ion-color-danger)";
      if (payTable) emptyTable = true;
    }

    if (payTable) {
      orderTitle = `${t("table_payment.orderTableTitle")} ${order.order?.theTable?.name}`;
    } else {
      if (order.order?.code) {
        orderTitle = `${t("table_payment.title")}: ${order.order?.code}`;
      } else if (order.order?.theTable?.name) {
        orderTitle = `${t("table_payment.orderTableTitle")} ${order.order?.theTable?.name}`;
      } else orderTitle = `${t("table_payment.title")}`;
    }
  }

  return (
    <IonPage className="tablePayment">
      <IonHeader>
        <IonToolbar color="secondary">
          <IonTitle>{t("table_payment.title")}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonLoading isOpen={paySplitResponse.loading || !orderResponse.called || orderResponse.loading} message={t("loading")} />
      <IonContent fullscreen>
        {!orderResponse.loading && payTable && (!order || emptyTable) && (
          <div className="content">
            <div className="errorMsg">{t(`table_payment.emptyTable`)} </div>
          </div>
        )}
        {!orderResponse.loading && !order && !payTable && (
          <div className="content">
            <div className="errorMsg">{t(`table_payment.emptyOrder`)} </div>
          </div>
        )}
        {order && !emptyTable && (
          <div className="content">
            <IonCard className="introMsg">
              <div className="msg">
                Scegli se pagare l’intero ordine o dividerlo con le persone al tuo tavolo.&nbsp;Prima di cliccare su <b>DIVIDI CONTO</b>, controlla quello che desideri pagare e calcola la tua quota.
              </div>
            </IonCard>

            <IonCard className="header">
              <div className="section order-title">
                <div>{orderTitle}</div>
                <div className="status" style={{ color: orderPayedStatusColor }}>
                  {orderPayedStatus}
                </div>
              </div>
            </IonCard>
            <IonCard key={order.id} className="body">
              {(billItems || []).map((billItem) => {
                const description = billItem.shippingCost ? t("order.delivery") : billItem.sku?.descriptionReceipt;
                if (billItem.subtotal && (billItem.percentageVariation || billItem.variation)) {
                  let variationType = "";
                  let variationValue = "";
                  if (billItem.percentageVariation) {
                    variationValue = `${Math.abs(billItem.percentageVariation)}%`;
                    if (billItem.percentageVariation > 0) {
                      variationType = "Sconto";
                    } else {
                      variationType = "Maggiorazione";
                    }
                  }
                  if (billItem.variation) {
                    if (billItem.variation > 0) {
                      variationValue = getPrice(billItem.variation * -1, tenant.settings?.fidelityAccount?.account.currency);
                      variationType = "Sconto";
                    } else {
                      variationValue = getPrice(Math.abs(billItem.variation), tenant.settings?.fidelityAccount?.account.currency);
                      variationType = "Maggiorazione";
                    }
                  }
                  return (
                    <div key={billItem.id} className="item">
                      <div className="itemContainer">
                        <div className="quantity"></div>
                        <div className="title">{variationType}</div>
                        <div className="value">{variationValue}</div>
                      </div>
                    </div>
                  );
                }
                if (billItem.menu || billItem.composition) {
                  return (
                    <div key={billItem.id} className="item">
                      <div className="itemContainer">
                        <div className="quantity">{billItem.quantity}</div>
                        <div className="title">
                          <div className="menuName">{description}</div>
                          {(billItem.billItemCourseSkus || []).map((courseSku) => {
                            return (
                              <>
                                <div className="ccName">{courseSku.sku?.descriptionReceipt}</div>
                                <div className="ar">
                                  {courseSku.courseSkuOptionValues &&
                                    courseSku.courseSkuOptionValues.length > 0 &&
                                    courseSku.courseSkuOptionValues
                                      .map((ar) => {
                                        const addition = ar.optionValue?.valueType === OptionValueType.POSITIVE ? "+ " : "";
                                        const removal = ar.optionValue?.valueType === OptionValueType.NEGATIVE ? "- " : "";
                                        return `${addition}${removal}${ar.optionValue?.value}`;
                                      })
                                      .join(", ")}
                                </div>
                              </>
                            );
                          })}
                        </div>
                        <div className="value">{getPrice(getOrderMenuPrice(billItem.price!, billItem.billItemCourseSkus!, billItem.menu!), tenant.settings?.fidelityAccount?.account.currency)}</div>
                      </div>
                    </div>
                  );
                }
                return (
                  <div key={billItem.id} className="item">
                    <div className="itemContainer">
                      <div className="quantity">{billItem.quantity}</div>
                      <div className="title">
                        {billItem.coverCharge && <div>{t("order.coverCharge")}</div>}
                        <div>{description}</div>
                        {billItem.itemOptionValues &&
                          billItem.itemOptionValues.length > 0 &&
                          billItem.itemOptionValues
                            .map((ar) => {
                              const addition = ar.optionValue?.valueType === OptionValueType.POSITIVE ? "+ " : "";
                              const removal = ar.optionValue?.valueType === OptionValueType.NEGATIVE ? "- " : "";
                              return `${addition}${removal}${ar.optionValue?.value}`;
                            })
                            .join(", ")}
                      </div>
                      <div className="value">{getPrice(getAdditionRemovalPrice(billItem.price!, billItem.itemOptionValues), tenant.settings?.fidelityAccount?.account.currency, false, billItem.quantity)}</div>
                    </div>
                    <div className="itemContainer">{variationElement(billItem)}</div>
                  </div>
                );
              })}
            </IonCard>
          </div>
        )}
        <IonActionSheet isOpen={showActionSheet} onDidDismiss={() => setShowActionSheet(false)} cssClass="my-custom-class" buttons={buttons}></IonActionSheet>
        {order && getPaymentsResponse.data && (
          <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
            <SplitPaymentsModal totalAmount={amountToPay(order.amount!, paymentsAmount(getPaymentsResponse.data.getPayments.records))} onSelect={splitPayAction} close={onSplitModalDismiss}></SplitPaymentsModal>
          </IonModal>
        )}
        {getPaymentsResponse.data && getPaymentsResponse.data && (
          <IonModal isOpen={showPaymentsModal} onDidDismiss={() => setShowPaymentsModal(false)}>
            <ShowPaymentsModal payments={getPaymentsResponse.data.getPayments.records} amountNotPayed={paymentsAmount(getPaymentsResponse.data.getPayments.records)} close={onPaymentsModalDismiss}></ShowPaymentsModal>
          </IonModal>
        )}
      </IonContent>
      {order && (
        <IonFooter className="pay-bar">
          <div className="content">
            <div className="recap">
              <div className="title">{t("table_payment.orderAmount")}:</div>
              <div className="value">
                <b>{getPrice(order.amount!, tenant.settings?.fidelityAccount?.account.currency)}</b>
              </div>
            </div>
            <div className="payments">
              {getPaymentsResponse.data && getPaymentsResponse.data.getPayments.records && getPaymentsResponse.data.getPayments.records.length > 0 && (
                <IonList lines="none" className="list">
                  <IonItem className="paymentsContent">
                    <div className="title">{t("table_payment.paymentAmount")}</div>
                    <div className="valueLink" onClick={() => setShowPaymentsModal(true)}>
                      <b>{getPrice(paymentsAmount(getPaymentsResponse.data.getPayments.records), tenant.settings?.fidelityAccount?.account.currency)} </b>
                    </div>
                  </IonItem>
                  <IonItem className="paymentsContent">
                    <div className="title">{t("table_payment.amountNotPayed")}</div>
                    <div className="value">
                      <b>{getPrice(amountToPay(order.amount!, paymentsAmount(getPaymentsResponse.data.getPayments.records)), tenant.settings?.fidelityAccount?.account.currency)}</b>
                    </div>
                  </IonItem>
                </IonList>
              )}
            </div>
            {order.order!.externalWorkflowStatus !== "COMPLETED" && (
              <>
                {getPaymentsResponse.data && getPaymentsResponse.data.getPayments.records && amountToPay(order.amount!, paymentsAmount(getPaymentsResponse.data.getPayments.records)) === 0 && <div>{t("table_payment.orderPayedNotClose")}</div>}

                {getPaymentsResponse.data && getPaymentsResponse.data.getPayments.records && amountToPay(order.amount!, paymentsAmount(getPaymentsResponse.data.getPayments.records)) > 0 && (
                  <>
                    <IonButton disabled={!order} onClick={() => pay(false)} expand="block">
                      {t("table_payment.splitPay")}
                    </IonButton>
                    <IonButton disabled={!order} onClick={() => pay(true)} expand="block">
                      {t("table_payment.totalAmountpay")}
                    </IonButton>
                  </>
                )}
                {getPaymentsResponse.data === undefined && (
                  <>
                    <IonButton disabled={!order} onClick={() => pay(false)} expand="block">
                      {t("table_payment.splitPay")}
                    </IonButton>
                    <IonButton disabled={!order} onClick={() => pay(true)} expand="block">
                      {t("table_payment.totalAmountpay")}
                    </IonButton>
                  </>
                )}
              </>
            )}
          </div>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default TablePayment;
