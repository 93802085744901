import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonItem, IonLabel, IonLoading, IonModal, IonPage, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar, useIonAlert, useIonToast } from "@ionic/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./product.css";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { useSelectedSalesPoint } from "../../hooks/selectedSalesPoint";
import { BillItemOptionValue, Item, OptionValueType, Sku } from "../../apollo-client/types";
import { useHistory, useLocation, useParams } from "react-router";
import _ from "lodash";
import { productDetailsQuery } from "../../apollo-client/queries";
import { getAdditionRemovalPrice, getAvailableQuantity, getCompletePrice, getPrice, getProductAdditionalRemovals, getProductOptions, getProductOptionValues, getSalesPoint, getSkuOptionValues, hasAtLeastOneSkuWithoutOptions } from "../../utils";
import { useCallback, useEffect, useState } from "react";
import { CartItem, useCart } from "../../hooks/cart";
import { add, caretDown, cart as cartIcon, checkmark, close } from "ionicons/icons";
import { isPlatform } from "@ionic/core";
import MultivariantsModal from "../../components/multivariants";
import AdditionRemovalsModal from "../../components/additionRemoval";
import { useTenant } from "../../hooks/multiTenancy";
import queryString from "query-string";
import { useMenu } from "../../hooks/menu";

import "swiper/css";
import "@ionic/react/css/ionic-swiper.css";

type ModalProps = {
  itemMenu?: boolean;
  itemComposition?: boolean;
};

const ProductDetails: React.FC<ModalProps> = ({ itemMenu = false, itemComposition = false }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search: querystr } = useLocation();
  const { cartItemIndex, menuCcItemIndex, idCourse, compositionprice, multiplier } = queryString.parse(querystr);
  const tenant = useTenant();
  const currency = tenant.settings?.fidelityAccount?.account.currency;
  const { idProduct } = useParams<{ idProduct: string }>();
  const [selectedSalesPointId] = useSelectedSalesPoint();
  const [cart, addToCart, , updateCartItem] = useCart();
  const [menu, , , addToMenuAtCourse, updateItemAtCourse, , , ,] = useMenu();
  const [toast] = useIonToast();
  const [showAlert] = useIonAlert();
  const [additionalShow, setAdditionalShow] = useState<boolean>(false);
  const [multivariantShow, setMultivariantShow] = useState<boolean>(false);

  const itemCourse = menu.item?.courses?.filter((c) => c.id === idCourse)[0];
  const cartCourse = menu.courses?.filter((c) => c.id === idCourse)[0];

  const multiplierValue = multiplier ? parseInt(multiplier as string) : 1;
  const compositionPriceValue = compositionprice ? parseFloat(compositionprice as string) : undefined;

  let cartItem: CartItem | undefined = undefined;

  if (itemMenu || itemComposition) {
    cartItem = menuCcItemIndex ? cartCourse.courseChoices[parseInt(menuCcItemIndex as string)] || undefined : undefined;
  } else {
    cartItem = cartItemIndex ? cart.items[parseInt(cartItemIndex as string)] || undefined : undefined;
  }

  // modify existing cart item

  const initQuantity = cartItem ? cartItem.quantity : 1;
  const initAdditionRemovals = cartItem ? cartItem.additionRemovals : undefined;
  let initOptionValues;

  if (cartItem) {
    const initOptionValues: { [key: string]: string } = {};
    getSkuOptionValues(cartItem.item, cartItem.sku)?.forEach((sov) => (initOptionValues[sov.idOption!] = sov.id));
  }

  const initSku = cartItem ? cartItem?.sku : undefined;
  const [quantity, setQuantity] = useState<number>(initQuantity);
  const [currentAdditionRemovals, setCurrentAdditionRemovals] = useState<BillItemOptionValue[] | undefined>(initAdditionRemovals);
  const [currentOptionValues, setCurrentOptionValues] = useState<{ [key: string]: string } | undefined>(initOptionValues);
  const [currentSku, setCurrentSku] = useState<Sku | undefined>(initSku);
  const selectedSalesPoint = getSalesPoint(tenant, selectedSalesPointId);
  const externalOrdersSettings = selectedSalesPoint?.salesPoint?.externalOrdersSettings;

  const productResponse = useQuery(productDetailsQuery, {
    skip: !selectedSalesPointId,
    variables: {
      idSalesPoint: selectedSalesPointId,
      filter: encodeURIComponent(JSON.stringify({ id: [idProduct] })),
    },
  });

  const product: Item | undefined = _.first(productResponse?.data?.productDetails?.records);

  useEffect(() => {
    if (!cartItem) {
      if (product && product.multivariant === false) {
        setCurrentSku(_.first(product.sku));
      }
      if (product) {
        const defaultOptionValueBindings = _.unionBy(
          [...(product?.optionValueBindings || []), ...(product?.category?.optionValueBindings || [])].filter((ovb) => ovb.isDefault),
          "idOptionValue"
        );
        if (defaultOptionValueBindings.length > 0) {
          const newAdditionalRemovals = defaultOptionValueBindings.map((ovb) => {
            const price = _.first(ovb.prices)?.price;
            if (itemMenu) {
              return {
                idOptionValue: ovb.idOptionValue,
                optionValue: ovb.optionValue,
                price: price ? price : 0,
                percentagePrice: undefined,
              };
            } else {
              return {
                idOptionValue: ovb.idOptionValue,
                price: itemMenu && _.first(ovb.prices)?.percentagePrice ? 0 : _.first(ovb.prices)?.price,
                percentagePrice: itemMenu ? undefined : _.first(ovb.prices)?.percentagePrice,
                optionValue: ovb.optionValue,
              };
            }
          });
          setCurrentAdditionRemovals(newAdditionalRemovals);
        }
      }
    }
  }, [product, cartItem]);

  const onMultivariantsChange = (sku?: Sku, optionValues?: { [key: string]: string }) => {
    setCurrentSku(sku);
    setCurrentOptionValues(optionValues);
  };

  const onMultivariantsSelect = (sku: Sku) => {
    setCurrentSku(sku);
    setMultivariantShow(false);
  };

  const onAdditionRemovalsChange = (additionRemovals?: BillItemOptionValue[]) => {
    setCurrentAdditionRemovals(additionRemovals);
    setAdditionalShow(false);
  };

  const options = product ? getProductOptions(product) : [];

  const onAdditionRemovalsDismiss = () => {
    setAdditionalShow(false);
  };

  const quantityOptions = [];

  let availableQuantity = currentSku ? getAvailableQuantity(currentSku, cart) : undefined;
  if ((itemMenu || itemComposition) && itemCourse) {
    if (availableQuantity !== undefined) {
      if (itemCourse!.max) {
        availableQuantity = itemCourse!.max >= availableQuantity ? availableQuantity : itemCourse!.max!;
      }
    } else {
      if (itemCourse!.max) {
        availableQuantity = itemCourse!.max!;
      }
    }
  }
  if (availableQuantity === undefined) availableQuantity = 300;
  if (availableQuantity > 300) availableQuantity = 300;

  if (externalOrdersSettings?.maximumBillItemQuantityAllowed && availableQuantity! > externalOrdersSettings?.maximumBillItemQuantityAllowed) availableQuantity = externalOrdersSettings?.maximumBillItemQuantityAllowed;

  for (let i = 1; i <= availableQuantity; i++) quantityOptions.push(i * multiplierValue);

  const onRemoveAdditionRemovalClick = (additionRemoval: BillItemOptionValue) => {
    const newAdditionalRemovals: BillItemOptionValue[] = currentAdditionRemovals ? [...currentAdditionRemovals] : [];
    const existing = currentAdditionRemovals?.find((ar) => ar.idOptionValue === additionRemoval.idOptionValue);
    if (existing) {
      _.remove(newAdditionalRemovals, existing);
      setCurrentAdditionRemovals(newAdditionalRemovals);
    }
  };

  const addToCartClick = useCallback(() => {
    if (product) {
      if (currentSku) {
        if (quantity && (availableQuantity === undefined || quantity <= availableQuantity)) {
          addToCart({
            item: product,
            sku: currentSku,
            price: _.first(currentSku.prices)?.price || _.first(product.prices)?.price || 0,
            additionRemovals: currentAdditionRemovals,
            quantity,
          });
          toast({
            cssClass: "cart-added-toast",
            color: "light",
            message: t("cart.added"),
            duration: 500,
            animated: true,
            position: "middle",
            onDidDismiss: () => {
              setCurrentAdditionRemovals([]);
              setCurrentOptionValues({});
              setCurrentSku(undefined);
              history.goBack();
            },
          });
        } else {
          showAlert({
            header: t("product_details.quantity_alert.title"),
            message: t("product_details.quantity_alert.message"),
            buttons: [t("product_details.quantity_alert.cancel")],
          });
        }
      } else if (product.multivariant) {
        setMultivariantShow(true);
      }
    }
  }, [product, quantity, currentSku, currentAdditionRemovals, availableQuantity]);

  const addToMenuClick = useCallback(() => {
    if (product && idCourse) {
      if (currentSku) {
        if (quantity && (availableQuantity === undefined || quantity <= availableQuantity)) {
          let price = 0;
          let componentPrice = price;
          if (itemComposition) {
            componentPrice = 0;
            price = 0;
            if (compositionPriceValue !== undefined) {
              componentPrice = compositionPriceValue;
              price = _.first(currentSku.prices)?.price || _.first(product.prices)?.price || 0;
            } else {
              componentPrice = _.first(currentSku.prices)?.price || _.first(product.prices)?.price || 0;
            }
          }
          if (itemMenu) {
            price = getAdditionRemovalPrice(0, currentAdditionRemovals);
          }
          addToMenuAtCourse(
            {
              item: product,
              sku: currentSku,
              price: price,
              componentPrice: componentPrice,
              additionRemovals: currentAdditionRemovals,
              quantity: quantity,
              multiplier: multiplierValue,
            },
            idCourse as string
          );
          toast({
            cssClass: "cart-added-toast",
            color: "light",
            message: t("menuPage.addToMenu"),
            duration: 100,
            animated: true,
            position: "middle",
            onDidDismiss: () => {
              setCurrentAdditionRemovals([]);
              setCurrentOptionValues({});
              setCurrentSku(undefined);
              history.goBack();
            },
          });
        } else {
          showAlert({
            header: t("product_details.quantity_alert.title"),
            message: t("product_details.quantity_alert.message"),
            buttons: [t("product_details.quantity_alert.cancel")],
          });
        }
      } else if (product.multivariant) {
        setMultivariantShow(true);
      }
    }
  }, [product, quantity, currentSku, currentAdditionRemovals, availableQuantity]);

  const saveCartItemMenuClick = useCallback(() => {
    if (menuCcItemIndex && product && idCourse && currentSku && quantity && (availableQuantity === undefined || quantity <= availableQuantity)) {
      let price = 0;
      let componentPrice = price;
      if (itemComposition) {
        componentPrice = 0;
        price = 0;
        if (compositionPriceValue) {
          componentPrice = compositionPriceValue;
          if (compositionPriceValue === 0) price = _.first(currentSku.prices)?.price || _.first(product.prices)?.price || 0;
        } else {
          componentPrice = _.first(currentSku.prices)?.price || _.first(product.prices)?.price || 0;
        }
      }
      if (itemMenu) {
        price = getAdditionRemovalPrice(0, currentAdditionRemovals);
      }
      updateItemAtCourse(
        {
          item: product,
          sku: currentSku,
          price: price,
          componentPrice: componentPrice,
          additionRemovals: currentAdditionRemovals,
          quantity: quantity,
          multiplier: multiplierValue,
        },
        idCourse as string,
        parseInt(menuCcItemIndex as string)
      );
      setCurrentAdditionRemovals([]);
      setCurrentOptionValues({});
      setCurrentSku(undefined);
      toast({
        cssClass: "cart-added-toast",
        color: "light",
        message: t("menuPage.addToMenu"),
        duration: 100,
        animated: true,
        position: "middle",
        onDidDismiss: () => {
          setCurrentAdditionRemovals([]);
          setCurrentOptionValues({});
          setCurrentSku(undefined);
          history.goBack();
        },
      });
    } else {
      showAlert({
        header: t("product_details.quantity_alert.title"),
        message: t("product_details.quantity_alert.message"),
        buttons: [t("product_details.quantity_alert.cancel")],
      });
    }
  }, [cartItemIndex, product, quantity, currentSku, currentAdditionRemovals, availableQuantity]);

  const saveCartItemClick = useCallback(() => {
    if (cartItemIndex && product && currentSku && quantity && (availableQuantity === undefined || quantity <= availableQuantity)) {
      updateCartItem(parseInt(cartItemIndex as string), {
        item: product,
        sku: currentSku,
        price: _.first(currentSku.prices)?.price || _.first(product.prices)?.price || 0,
        additionRemovals: currentAdditionRemovals,
        quantity,
      });
      setCurrentAdditionRemovals([]);
      setCurrentOptionValues({});
      setCurrentSku(undefined);
      history.push("/app/cart");
    } else {
      showAlert({
        header: t("product_details.quantity_alert.title"),
        message: t("product_details.quantity_alert.message"),
        buttons: [t("product_details.quantity_alert.cancel")],
      });
    }
  }, [cartItemIndex, product, quantity, currentSku, currentAdditionRemovals, availableQuantity]);

  const getCurrentOptionValue = (optionId: string): string | undefined => {
    if (currentOptionValues && product) {
      const optionValueId = currentOptionValues ? currentOptionValues[optionId] : undefined;
      if (optionValueId) {
        return getProductOptionValues(product, optionId).find((ov) => ov.id === optionValueId)?.value;
      }
    }
    return undefined;
  };

  let componentPrice = "";

  if (itemComposition) {
    if (compositionPriceValue !== undefined) {
      componentPrice = getPrice(getAdditionRemovalPrice(compositionPriceValue, currentAdditionRemovals), currency);
    } else {
      if (product) {
        componentPrice = getPrice(getCompletePrice(product, currentSku, currentAdditionRemovals), currency);
      }
    }
  }
  return (
    <IonPage className="product-details">
      {product && (!product?.itemImages || product.itemImages.length === 0) && (
        <IonFab className="cart-floating-button" style={{ top: "30px" }} slot="fixed">
          {(itemMenu || itemComposition) && cartItem && (
            <IonFabButton onClick={() => saveCartItemMenuClick()}>
              <IonIcon icon={checkmark} />
            </IonFabButton>
          )}
          {(itemMenu || itemComposition) && !cartItem && (
            <IonFabButton onClick={() => addToMenuClick()}>
              <IonIcon icon={checkmark} />
            </IonFabButton>
          )}
          {!(itemMenu || itemComposition) && cartItem && (
            <IonFabButton onClick={() => saveCartItemClick()}>
              <IonIcon icon={checkmark} />
            </IonFabButton>
          )}
          {!(itemMenu || itemComposition) && !cartItem && (
            <IonFabButton onClick={() => addToCartClick()}>
              <IonIcon icon={cartIcon} />
            </IonFabButton>
          )}
        </IonFab>
      )}
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{product?.description}</IonTitle>
        </IonToolbar>
        <IonFab className="cart-floating-button" style={{ top: isPlatform("ios") ? "30px" : "30px" }}>
          {(itemMenu || itemComposition) && cartItem && (
            <IonFabButton onClick={() => saveCartItemMenuClick()}>
              <IonIcon icon={checkmark} />
            </IonFabButton>
          )}
          {(itemMenu || itemComposition) && !cartItem && (
            <IonFabButton onClick={() => addToMenuClick()}>
              <IonIcon icon={checkmark} />
            </IonFabButton>
          )}
          {!(itemMenu || itemComposition) && cartItem && (
            <IonFabButton onClick={() => saveCartItemClick()}>
              <IonIcon icon={checkmark} />
            </IonFabButton>
          )}
          {!(itemMenu || itemComposition) && !cartItem && (
            <IonFabButton onClick={() => addToCartClick()}>
              <IonIcon icon={cartIcon} />
            </IonFabButton>
          )}
        </IonFab>
      </IonHeader>
      <IonLoading isOpen={productResponse.loading} message={t("loading")} />
      <IonContent fullscreen>
        {product && (
          <>
            {product.itemImages && product.itemImages.length > 0 && (
              <>
                <Swiper className="images">
                  {_.sortBy(product.itemImages, (s) => -s.position).map((image, index) => (
                    <SwiperSlide key={index}>
                      <div className="image" style={image ? { backgroundImage: `url('${image.imageUrl}')` } : {}} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </>
            )}
            <div className="product-content">
              <IonRow>
                <IonCol className="name" size="9">
                  {product.description}
                </IonCol>
                {itemMenu && (
                  <IonCol className="price" size="3">
                    {getPrice(getAdditionRemovalPrice(0, currentAdditionRemovals), currency)}
                  </IonCol>
                )}
                {itemComposition && (
                  <IonCol className="price" size="3">
                    {componentPrice}
                  </IonCol>
                )}
                {!itemMenu && !itemComposition && (
                  <IonCol className="price" size="3">
                    {getPrice(getCompletePrice(product, currentSku, currentAdditionRemovals), currency)}
                  </IonCol>
                )}
              </IonRow>
              <IonRow>
                <IonCol>
                  {getProductAdditionalRemovals(product).length > 0 && (
                    <>
                      <IonItem lines="none">
                        <IonButton className="variation-button addition-removal-button" onClick={() => setAdditionalShow(true)}>
                          <div className="variation-text">{t("product_details.addition_removal")}</div>
                          <IonIcon slot="end" icon={add} />
                        </IonButton>
                      </IonItem>
                      {currentAdditionRemovals && currentAdditionRemovals.length > 0 && (
                        <IonRow className="addition-removals">
                          {currentAdditionRemovals.map((ar, index) => (
                            <IonCol key={index} size="6">
                              <div className="addition-removal">
                                <div className="description">
                                  {ar.optionValue?.valueType === OptionValueType.POSITIVE && "+ "}
                                  {ar.optionValue?.valueType === OptionValueType.NEGATIVE && "- "}
                                  {ar.optionValue?.value}
                                </div>
                                {ar && <div className="price">{getPrice(ar, currency, true)}</div>}
                                <IonButton size="small" fill="clear" color="dark" onClick={() => onRemoveAdditionRemovalClick(ar)}>
                                  <IonIcon icon={close} />
                                </IonButton>
                              </div>
                            </IonCol>
                          ))}
                        </IonRow>
                      )}
                    </>
                  )}
                  {product.multivariant &&
                    options.map((option) => (
                      <IonItem key={option.id} lines="none">
                        <IonLabel position="stacked">{option.description}</IonLabel>
                        <IonButton className="variation-button" onClick={() => setMultivariantShow(true)}>
                          <div className="variation-text">{getCurrentOptionValue(option.id)}</div>
                          <IonIcon slot="end" icon={caretDown} />
                        </IonButton>
                      </IonItem>
                    ))}
                  {product && hasAtLeastOneSkuWithoutOptions(product) && (
                    <IonItem lines="none">
                      <IonLabel position="stacked">{t("product_details.variants")}</IonLabel>
                      <IonButton className="variation-button" onClick={() => setMultivariantShow(true)}>
                        <div className="variation-text">{currentSku?.descriptionReceipt}</div>
                        <IonIcon slot="end" icon={caretDown} />
                      </IonButton>
                    </IonItem>
                  )}
                  <IonItem lines="none" disabled={availableQuantity !== undefined && availableQuantity <= 0}>
                    <IonLabel position="stacked">{t("product_details.quantity")}</IonLabel>
                    <IonSelect value={quantity * multiplierValue} interface="alert" interfaceOptions={{ header: t("product_details.quantity") }} onIonChange={(e) => setQuantity(e.detail.value / multiplierValue)}>
                      {quantityOptions.map((q) => (
                        <IonSelectOption key={q} value={q}>
                          {q}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonItem>
                  {!(itemMenu || itemComposition) && !cartItem && (
                    <IonButton className="add-to-cart" expand="block" onClick={() => addToCartClick()}>
                      {t("product_details.add_to_cart")}
                    </IonButton>
                  )}
                  {!(itemMenu || itemComposition) && cartItem && (
                    <IonButton className="add-to-cart" expand="block" onClick={() => saveCartItemClick()}>
                      {t("product_details.save")}
                    </IonButton>
                  )}
                  {(itemMenu || itemComposition) && !cartItem && (
                    <IonButton className="add-to-cart" expand="block" onClick={() => addToMenuClick()}>
                      {t("menuPage.addToMenu")}
                    </IonButton>
                  )}
                  {(itemMenu || itemComposition) && cartItem && (
                    <IonButton className="add-to-cart" expand="block" onClick={() => saveCartItemMenuClick()}>
                      {t("menuPage.addToMenu")}
                    </IonButton>
                  )}
                  {product?.descriptionExtended && (
                    <div className="description-extended">
                      <div className="label">{t("product_details.description")}</div>
                      <div dangerouslySetInnerHTML={{ __html: product?.descriptionExtended }} />
                    </div>
                  )}
                </IonCol>
              </IonRow>
            </div>
          </>
        )}
      </IonContent>
      {product && (
        <IonModal isOpen={multivariantShow} onDidDismiss={() => setMultivariantShow(false)}>
          <MultivariantsModal item={product!} sku={currentSku} optionValues={currentOptionValues} onChange={onMultivariantsChange} onSelect={onMultivariantsSelect}></MultivariantsModal>
        </IonModal>
      )}

      {product && (
        <IonModal isOpen={additionalShow} onDidDismiss={() => setAdditionalShow(false)}>
          <AdditionRemovalsModal menu={itemMenu} item={product!} sku={currentSku} additionRemovals={currentAdditionRemovals} onChange={onAdditionRemovalsChange} dismiss={onAdditionRemovalsDismiss}></AdditionRemovalsModal>
        </IonModal>
      )}
    </IonPage>
  );
};

export default ProductDetails;
