import { useQuery } from "@apollo/client";
import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonItem, IonLabel, IonLoading, IonPage, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar, isPlatform, useIonAlert, useIonToast } from "@ionic/react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "@ionic/react/css/ionic-swiper.css";

import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router";
import { productDetailsQuery } from "../../../apollo-client/queries";
import { Item, OptionValueType } from "../../../apollo-client/types";
import { CartItem, useCart } from "../../../hooks/cart";
import { useTenant } from "../../../hooks/multiTenancy";
import { useSelectedSalesPoint } from "../../../hooks/selectedSalesPoint";
import { copleteMenu, getAdditionRemovalPrice, getAvailableQuantity, getCourseQuantity, getPrice, getPriceMenu, getSalesPoint, getSkuOptionValues } from "../../../utils";
import queryString from "query-string";
import { alert, checkmark, add, remove, cart as cartIcon } from "ionicons/icons";
import { useMenu } from "../../../hooks/menu";
import "./menu.css";
import "swiper/css";
import "@ionic/react/css/ionic-swiper.css";

const Menu: React.FC = () => {
  const history = useHistory();
  const tenant = useTenant();
  const currency = tenant.settings?.fidelityAccount?.account.currency;
  const { idProduct } = useParams<{ idProduct: string }>();
  const [selectedSalesPointId] = useSelectedSalesPoint();
  const { t } = useTranslation();
  const [cart, addToCart, , updateItem, , ,] = useCart();
  const [menu, newMenu, openMenu, , , , addQuantityItem, removeQuantityItem, clean] = useMenu();
  const [toast] = useIonToast();
  const [showAlert] = useIonAlert();
  const { search: querystr } = useLocation();
  const { cartItemIndex } = queryString.parse(querystr);
  const selectedSalesPoint = getSalesPoint(tenant, selectedSalesPointId);
  const externalOrdersSettings = selectedSalesPoint?.salesPoint?.externalOrdersSettings;
  const cartItem = cartItemIndex ? cart.items[parseInt(cartItemIndex as string)] || undefined : undefined;
  const initQuantity = cartItem ? cartItem.quantity : 1;
  const [quantity, setQuantity] = useState<number>(initQuantity);

  // modify existing cart item

  if (cartItem) {
    if (cartItem.menu?.item && !menu.item) {
      openMenu(cartItem.menu);
    }
    const initOptionValues: { [key: string]: string } = {};
    getSkuOptionValues(cartItem.item, cartItem.sku)?.forEach((sov) => (initOptionValues[sov.idOption!] = sov.id));
  }

  const addToCartClick = useCallback(() => {
    if (copleteMenu(menu)) {
      if (menu && menu.item && menu.item.sku && menu.price != undefined) {
        if (quantity && (availableQuantity === undefined || quantity <= availableQuantity)) {
          let price = menu.price;
          if (menu.item.composition) {
            price = 0;
            if (menu!.item!.prices![0].price) price = menu!.item!.prices![0].price!;
          }
          addToCart({
            item: menu.item,
            sku: menu!.item!.sku![0],
            price: price,
            menu: menu,
            additionRemovals: undefined,
            quantity,
          });
          toast({
            cssClass: "cart-added-toast",
            color: "light",
            message: t("cart.added"),
            duration: 500,
            animated: true,
            position: "middle",
            onDidDismiss: () => {
              history.goBack();
            },
          });
        } else {
          showAlert({
            header: t("product_details.quantity_alert.title"),
            message: t("product_details.quantity_alert.message"),
            buttons: [t("product_details.quantity_alert.cancel")],
          });
        }
      }
    } else {
      showAlert({
        header: t("menuPage.menuNotCompleteTitle"),
        message: t("menuPage.menuNotCompleteMsg"),
        buttons: [t("product_details.quantity_alert.cancel")],
      });
    }
  }, [quantity, menu]);

  const saveCartItemClick = useCallback(() => {
    if (copleteMenu(menu)) {
      if (menu && menu.item && menu.item.sku) {
        if (quantity && (availableQuantity === undefined || quantity <= availableQuantity)) {
          updateItem(parseInt(cartItemIndex as string), {
            item: menu.item,
            sku: menu!.item!.sku![0],
            price: getPriceMenu(menu),
            menu: menu,
            additionRemovals: undefined,
            quantity,
          });
          history.goBack();
        } else {
          showAlert({
            header: t("product_details.quantity_alert.title"),
            message: t("product_details.quantity_alert.message"),
            buttons: [t("product_details.quantity_alert.cancel")],
          });
        }
      }
    } else {
      showAlert({
        header: t("menuPage.menuNotCompleteTitle"),
        message: t("menuPage.menuNotCompleteMsg"),
        buttons: [t("product_details.quantity_alert.cancel")],
      });
    }
  }, [quantity, menu]);

  const openItem = useCallback(
    (item: CartItem, courseID: string, index: number) => {
      if (menu.item?.menu) history.push(`/app/products/menu/product-details/${item.item.id}?idCourse=${courseID}&menuCcItemIndex=${index}&multiplier=${item.multiplier}`);
      if (menu.item?.composition) history.push(`/app/products/composition/product-details/${item.item.id}?idCourse=${courseID}&menuCcItemIndex=${index}`);
    },
    [history, menu]
  );

  const productResponse = useQuery(productDetailsQuery, {
    skip: !selectedSalesPointId,
    variables: {
      idSalesPoint: selectedSalesPointId,
      filter: encodeURIComponent(JSON.stringify({ id: [idProduct] })),
    },
  });

  const product: Item | undefined = _.first(productResponse?.data?.productDetails?.records);

  useEffect(() => {
    if (product && cartItemIndex !== null) {
      if (product.courses) {
        newMenu(product!, product.courses!);
      } else {
        clean();
      }
    }
  }, [product]);

  const quantityOptions = [];
  let availableQuantity = product?.sku ? getAvailableQuantity(product?.sku[0], cart) : undefined;
  if (availableQuantity === undefined) availableQuantity = 300;
  if (availableQuantity > 300) availableQuantity = 300;

  if (externalOrdersSettings?.maximumBillItemQuantityAllowed && availableQuantity! > externalOrdersSettings?.maximumBillItemQuantityAllowed) availableQuantity = externalOrdersSettings?.maximumBillItemQuantityAllowed;
  for (let i = 1; i <= availableQuantity; i++) quantityOptions.push(i);

  return (
    <IonPage className="menu-details">
      {product && (!product?.itemImages || product.itemImages.length === 0) && (
        <IonFab className="cart-floating-button" style={{ top: "30px" }} slot="fixed">
          {cartItem && (
            <IonFabButton onClick={() => saveCartItemClick()}>
              <IonIcon icon={checkmark} />
            </IonFabButton>
          )}
          {!cartItem && (
            <IonFabButton onClick={() => addToCartClick()}>
              <IonIcon icon={cartIcon} />
            </IonFabButton>
          )}
        </IonFab>
      )}
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{product?.description}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonLoading isOpen={productResponse.loading} message={t("loading")} />
      <IonContent fullscreen>
        {product && (
          <>
            {product.itemImages && product.itemImages.length > 0 && (
              <>
                <Swiper className="images">
                  {_.sortBy(product.itemImages, (s) => -s.position).map((image, index) => (
                    <SwiperSlide key={index}>
                      <div className="image" style={image ? { backgroundImage: `url('${image.imageUrl}')` } : {}} />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <IonFab className="cart-floating-button" style={{ top: isPlatform("ios") ? "217px" : "230px" }}>
                  {cartItem && (
                    <IonFabButton onClick={() => saveCartItemClick()}>
                      <IonIcon icon={checkmark} />
                    </IonFabButton>
                  )}
                  {!cartItem && (
                    <IonFabButton onClick={() => addToCartClick()}>
                      <IonIcon icon={cartIcon} />
                    </IonFabButton>
                  )}
                </IonFab>
              </>
            )}

            <div className="menu-content">
              <IonRow>
                <IonCol className="name" size="9">
                  {product.description}
                </IonCol>
                <IonCol className="price" size="3">
                  {getPrice(getPriceMenu(menu), currency)}
                </IonCol>
              </IonRow>
              <IonRow className="quantityContainer">
                <IonCol>
                  <IonItem lines="none">
                    <IonLabel position="stacked">{t("product_details.quantity")}</IonLabel>
                    <IonSelect value={quantity} interface="alert" interfaceOptions={{ header: t("product_details.quantity") }} onIonChange={(e) => setQuantity(e.detail.value)}>
                      {quantityOptions.map((q) => (
                        <IonSelectOption key={q} value={q}>
                          {q}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonItem>
                </IonCol>
              </IonRow>
              {_.sortBy(menu.courses, (c) => c.position!).map((course, index) => {
                let courseComplete = false;
                if (course.courseChoices) {
                  courseComplete = getCourseQuantity(course) >= course.min!;
                }
                return (
                  <div className="courseContainer">
                    <div className="courseTitle" key={index} onClick={() => history.push(`/app/products/course/${course.id}`)}>
                      <div className="section-horizontal">
                        <div className="title">
                          <IonLabel>{course.description}</IonLabel>
                        </div>
                        <div className="value">
                          {courseComplete && <IonIcon className="icon" icon={checkmark} />}
                          {!courseComplete && <IonIcon className="icon" icon={alert} />}
                        </div>
                      </div>
                    </div>
                    {course.courseChoices.map((item, index) => {
                      return (
                        <div className="ccSelectElementContainer">
                          <div className="ccTitle">
                            <div className="section-horizontal">
                              <div className="preAction" key={index} onClick={() => removeQuantityItem(index, course.id, item.multiplier!)}>
                                <IonIcon className="icon" icon={remove} />
                              </div>
                              <div className="title" key={index} onClick={() => openItem(item, course.id, index)}>
                                <IonLabel>{item.item.description}</IonLabel>
                                <div className="ar">
                                  {item.additionRemovals &&
                                    item.additionRemovals.length > 0 &&
                                    item.additionRemovals
                                      .map((ar) => {
                                        const addition = ar.optionValue?.valueType === OptionValueType.POSITIVE ? "+ " : "";
                                        const removal = ar.optionValue?.valueType === OptionValueType.NEGATIVE ? "- " : "";
                                        return `${addition}${removal}${ar.optionValue?.value}`;
                                      })
                                      .join(", ")}
                                </div>
                              </div>
                              <div className="quantity">
                                <div>
                                  <IonLabel>Qt. {item.quantity}</IonLabel>
                                </div>

                                {menu.item?.composition && item.price == 0 && item.componentPrice !== undefined && (
                                  <div>
                                    <IonLabel>{getPrice(getAdditionRemovalPrice(item.componentPrice, item.additionRemovals), currency, false, item.quantity)}</IonLabel>
                                  </div>
                                )}
                                {!menu.item?.composition && (
                                  <div>
                                    <IonLabel>{getPrice(item.price, currency, false, item.quantity)}</IonLabel>
                                  </div>
                                )}
                              </div>
                              <div className="action" key={index} onClick={() => addQuantityItem(index, course.id, item.multiplier!)}>
                                <IonIcon className="icon" icon={add} />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              <IonRow>
                <IonCol>
                  {!cartItem && (
                    <IonButton className="add-to-cart" expand="block" onClick={() => addToCartClick()}>
                      {t("product_details.add_to_cart")}
                    </IonButton>
                  )}
                  {cartItem && (
                    <IonButton className="add-to-cart" expand="block" onClick={() => saveCartItemClick()}>
                      {t("product_details.save")}
                    </IonButton>
                  )}
                  {product?.descriptionExtended && (
                    <div className="description-extended">
                      <div className="label">{t("product_details.description")}</div>
                      <div dangerouslySetInnerHTML={{ __html: product?.descriptionExtended }} />
                    </div>
                  )}
                </IonCol>
              </IonRow>
            </div>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Menu;
